import React, { useEffect } from "react";
import { ConcreteComponentNode } from "../../components/ConcreteComponentNode";
import { FacebookLogo } from "../../components/FacebookLogo";
import { Group } from "../../components/Group";
import { InstagramLogo } from "../../components/InstagramLogo";
import { LinkedinLogo } from "../../components/LinkedinLogo";
import { NavPages } from "../../components/NavPages";
import { Newsletter } from "../../components/Newsletter";
import { PropertyDefaultWrapper } from "../../components/PropertyDefaultWrapper";
import { TextHover } from "../../components/TextHover";
import { User } from "../../components/User";
import { XLogo } from "../../components/XLogo";
import { YoutubeLogo } from "../../components/YoutubeLogo";
import "./style.css";
import { Navbar } from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import { House } from "../../components/House";
import { useWindowWidth } from "../../breakpoints";
import { usePartners } from "../../api/api";
import { useTranslation } from "../../translations/TranslationContext";

export const Screen6 = () => {

  const screenWidth = useWindowWidth();
  const data = usePartners()
  console.log(data);
  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, []);
  const { t, changeLocale, locale } = useTranslation();

  return (
    <div dir={locale == "ar" ? "rtl" : "ltr"} className="screen-6">

      <div className="screen-7">
        <Navbar className="navbar-instance" logo="/img/logo-3.png" />
        <div className="container-91 h-[55vh] md:h-[40vh]">
          <div className="container-92">
            <div className="container-93">
              <div className="text-wrapper-150"
                style={{
                  width: "100%",
                  fontFamily:
                    screenWidth < 500
                      ? "var(--arabic-heading-h4-font-family)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h3-font-family)"
                        : screenWidth >= 834 && screenWidth < 3000
                          ? "var(--arabic-heading-h1-font-family)"
                          : "var(--arabic-heading-h1-font-family)",
                  fontSize:
                    screenWidth < 500
                      ? "var(--arabic-heading-h4-font-size)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h3-font-size)"
                        : screenWidth >= 834 && screenWidth < 3000
                          ? "var(--arabic-heading-h1-font-size)"
                          : "var(--extra-large-size)",
                  fontStyle:
                    screenWidth < 500
                      ? "var(--arabic-heading-h4-font-style)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h3-font-style)"
                        : screenWidth >= 834 && screenWidth < 3000
                          ? "var(--arabic-heading-h1-font-style)"
                          : "var(--arabic-heading-h1-font-style)",
                  fontWeight:
                    screenWidth < 500
                      ? "var(--arabic-heading-h4-font-weight)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h3-font-weight)"
                        : screenWidth >= 834 && screenWidth < 3000
                          ? "var(--arabic-heading-h1-font-weight)"
                          : "var(--arabic-heading-h1-font-weight)",
                  letterSpacing:
                    screenWidth < 500
                      ? "var(--arabic-heading-h4-letter-spacing)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h3-letter-spacing)"
                        : screenWidth >= 834 && screenWidth < 3000
                          ? "var(--arabic-heading-h1-letter-spacing)"
                          : "var(--arabic-heading-h1-letter-spacing)",
                  lineHeight:
                    screenWidth < 834
                      ? "var(--arabic-heading-h4-line-height)"
                      : screenWidth >= 834 && screenWidth < 3000
                        ? "var(--arabic-heading-h1-line-height)"
                        : "var(--arabic-heading-h1-line-height)",
                }}
              >{t("partners")}</div>
              <p className="text-wrapper-151"
                style={{
                  width: "100%",
                  fontFamily:
                    screenWidth < 500
                      ? "var(--arabic-heading-h6-font-family)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h6-font-family)"
                        : screenWidth >= 834 && screenWidth < 3000
                          ? "var(--arabic-heading-h6-font-family)"
                          : "var(--arabic-heading-h3-font-family)",
                  fontSize:
                    screenWidth < 500
                      ? "var(--arabic-heading-h6-font-size)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h6-font-size)"
                        : screenWidth >= 834 && screenWidth < 3000
                          ? "var(--arabic-heading-h6-font-size)"
                          : "var(--meduam-large-size)",
                  fontStyle:
                    screenWidth < 500
                      ? "var(--arabic-heading-h6-font-style)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h6-font-style)"
                        : screenWidth >= 834 && screenWidth < 3000
                          ? "var(--arabic-heading-h6-font-style)"
                          : "var(--arabic-heading-h3-font-style)",
                  fontWeight:
                    screenWidth < 500
                      ? "var(--arabic-heading-h6-font-weight)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h6-font-weight)"
                        : screenWidth >= 834 && screenWidth < 3000
                          ? "var(--arabic-heading-h6-font-weight)"
                          : "var(--arabic-heading-h3-font-weight)",
                  letterSpacing:
                    screenWidth < 500
                      ? "var(--arabic-heading-h6-letter-spacing)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h6-letter-spacing)"
                        : screenWidth >= 834 && screenWidth < 3000
                          ? "var(--arabic-heading-h6-letter-spacing)"
                          : "var(--arabic-heading-h3-letter-spacing)",
                  lineHeight:
                    screenWidth < 834
                      ? "var(--arabic-heading-h6-line-height)"
                      : screenWidth >= 834 && screenWidth < 3000
                        ? "var(--arabic-heading-h6-line-height)"
                        : "var(--arabic-heading-h3-line-height)",
                }}

              >{t("saudiWaterInnovationCenter")}</p>
            </div>
          </div>
        </div>
        <div className="aframe-127">
          <div className="aframe-128">
            <House className={screenWidth < 3000 ? "ainstance-node-8" : "ainstance-node-8more"} format="stroke" formatOutline="/img/house.png" weight="regular" />
            <div className={screenWidth < 3000 ? "aitem-link-home-5" : "aitem-link-home-5"}>{t("homepage")}</div>
          </div>
          <div className={screenWidth < 3000 ? "atext-wrapper-104" : "atext-wrapper-104"}>/</div>

          <div className={screenWidth < 3000 ? "atext-wrapper-103" : "atext-wrapper-103"}


          >
{t("partners")}
          </div>

        </div>

        <div className="frame-140">
          <div className={screenWidth < 3000 ? "text-wrapper-159" : "text-wrapper-159"}>{t("partners")}</div>
          <div className="frame-141">
            <div className="grid grid-cols-3 gap-8 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 w-[95%]">
              {/* <div className="frame-143">
                <div className="frame-144">
                  <img className="SVG-2" alt="Svg" src="/img/property-1-variant2-2.png" />
                </div>
                <div className="parteners-link">
                  <p className="text-wrapper-160">جامعة المالك عبدالله للعلوم والتنقينة</p>
                </div>
              </div> */}
              {/* <div className="frame-143">
                <div className="frame-144">
                  <img className="image-9" alt="Image" src="/img/property-1-variant2-5.png" />
                </div>
                <div className="parteners-link">
                  <div className="text-wrapper-160">وزارة البيئة والمياه والزراعة</div>
                </div>
              </div> */}
              {/* <div className="frame-143">
                <div className={screenWidth<2000?"frame-144":"frame-144more"}>
                  <PropertyDefaultWrapper
                    className={screenWidth<2000?"SVG-instance":"SVG-instancemore"}
                    property="/img/property-1-variant2-7.png"
                    property1="variant-2"
                  />
                </div>
                <div className="parteners-link">
                  <p className={screenWidth<2000?"text-wrapper-160":"text-wrapper-160more"}>المؤسسة العامة لتحلية الماه المالحة</p>
                </div>
              </div> */}

              {data?.data?.map((partner) => {

                return <a href={partner?.description[locale]} target="_blank" className="frame-143 hover:shadow-lg" key={partner.id}>
                  <div className={screenWidth < 2000 ? "frame-144" : "frame-144more"}>
                    <Group className={screenWidth < 2000 ? "group-instance" : "group-instancemore"} property={partner?.attachment[0]?.original} property1="variant-2" />
                  </div>
                  <div className="parteners-link">
                    <p className={screenWidth < 2000 ? "text-wrapper-160" : "text-wrapper-160more"}>{partner?.name[locale]}</p>
                  </div>
                </a>
              })}

              {/* <div className="frame-143">
                <div className="frame-144">
                  <img className="img-4" alt="Group" src="/img/property-1-variant2-1.png" />
                </div>
                <div className="parteners-link">
                  <div className="text-wrapper-160">شركة المياه الوطنية</div>
                </div>
              </div> */}

            </div>
          </div>
        </div>

        <div className={screenWidth < 2000 ? "mt-[100px] lg:mt-[300px]" : "mt-[600px]"}></div>
        <Footer className="footer-3" logo="/img/logo-9.png" property1="web" />

      </div>
    </div>
  );
};
