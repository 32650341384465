/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { FormatOutlineWeightDuotone7 } from "../../icons/FormatOutlineWeightDuotone7";
import { FormatOutlineWeightFill7 } from "../../icons/FormatOutlineWeightFill7";
import { FormatOutlineWeightLight7 } from "../../icons/FormatOutlineWeightLight7";
import { FormatStrokeWeightBold8 } from "../../icons/FormatStrokeWeightBold8";
import { FormatStrokeWeightDuotone8 } from "../../icons/FormatStrokeWeightDuotone8";
import { FormatStrokeWeightFill7 } from "../../icons/FormatStrokeWeightFill7";
import { FormatStrokeWeightLight8 } from "../../icons/FormatStrokeWeightLight8";
import { FormatStrokeWeightRegular8 } from "../../icons/FormatStrokeWeightRegular8";
import { FormatStrokeWeightThin8 } from "../../icons/FormatStrokeWeightThin8";
import "./style.css";

export const InstagramLogo = ({ format, weight }) => {
  return (
    <>
      {((format === "outline" && weight === "bold") ||
        (format === "outline" && weight === "light") ||
        (format === "outline" && weight === "regular") ||
        (format === "outline" && weight === "thin")) && <FormatOutlineWeightLight7 className="instance-node-6" />}

      {format === "outline" && weight === "fill" && <FormatOutlineWeightFill7 className="instance-node-6" />}

      {format === "outline" && weight === "duotone" && <FormatOutlineWeightDuotone7 className="instance-node-6" />}

      {weight === "regular" && format === "stroke" && <FormatStrokeWeightRegular8 className="instance-node-6" />}

      {weight === "thin" && format === "stroke" && <FormatStrokeWeightThin8 className="instance-node-6" />}

      {weight === "light" && format === "stroke" && <FormatStrokeWeightLight8 className="instance-node-6" />}

      {weight === "bold" && format === "stroke" && <FormatStrokeWeightBold8 className="instance-node-6" />}

      {format === "stroke" && weight === "fill" && <FormatStrokeWeightFill7 className="instance-node-6" />}

      {weight === "duotone" && format === "stroke" && <FormatStrokeWeightDuotone8 className="instance-node-6" />}
    </>
  );
};

InstagramLogo.propTypes = {
  format: PropTypes.oneOf(["outline", "stroke"]),
  weight: PropTypes.oneOf(["duotone", "regular", "thin", "bold", "fill", "light"]),
};
