/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatOutlineWeightFill8 = ({ className }) => {
  return (
    <svg
      className={`format-outline-weight-fill-8 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M26.875 27.4813C26.7889 27.6381 26.6623 27.7689 26.5084 27.8602C26.3545 27.9514 26.1789 27.9997 26 28H20C19.8317 27.9999 19.6661 27.9574 19.5186 27.8764C19.3711 27.7953 19.2465 27.6783 19.1562 27.5362L14.095 19.5825L6.74 27.6725C6.5607 27.8651 6.31272 27.9793 6.04981 27.9903C5.7869 28.0012 5.53025 27.9082 5.33551 27.7312C5.14078 27.5542 5.02364 27.3076 5.00951 27.0448C4.99537 26.7821 5.08537 26.5243 5.26 26.3275L12.9812 17.8275L5.15625 5.5375C5.05988 5.3863 5.00593 5.21198 5.00005 5.03278C4.99417 4.85357 5.03658 4.67609 5.12284 4.5189C5.2091 4.36172 5.33604 4.23062 5.49036 4.13932C5.64468 4.04803 5.8207 3.99991 6 4H12C12.1683 4.00005 12.3339 4.04258 12.4814 4.12365C12.6289 4.20471 12.7535 4.32169 12.8437 4.46375L17.905 12.4175L25.26 4.3275C25.4393 4.1349 25.6873 4.02073 25.9502 4.00974C26.2131 3.99876 26.4697 4.09185 26.6645 4.26882C26.8592 4.44579 26.9764 4.69239 26.9905 4.95515C27.0046 5.21791 26.9146 5.47565 26.74 5.6725L19.0187 14.1663L26.8437 26.4638C26.9396 26.615 26.993 26.7892 26.9985 26.9682C27.004 27.1472 26.9614 27.3244 26.875 27.4813Z"
        fill="#343330"
      />
    </svg>
  );
};
