/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Eye = ({ format, weight, className, formatStrokeWeight = "/img/format-stroke-weight-duotone-11.png" }) => {
  return (
    <img
      className={`eye ${className}`}
      alt="Format outline"
      src={
        format === "outline" && weight === "thin"
          ? "/img/format-outline-weight-thin-11.png"
          : weight === "light" && format === "outline"
          ? "/img/format-outline-weight-light-11.png"
          : format === "outline" && weight === "bold"
          ? "/img/format-outline-weight-bold-11.png"
          : weight === "fill"
          ? "/img/format-outline-weight-fill-11.png"
          : format === "outline" && weight === "duotone"
          ? "/img/format-outline-weight-duotone-11.png"
          : weight === "regular" && format === "stroke"
          ? "/img/format-stroke-weight-regular-11.png"
          : weight === "thin" && format === "stroke"
          ? "/img/format-stroke-weight-thin-11.png"
          : weight === "light" && format === "stroke"
          ? "/img/format-stroke-weight-light-11.png"
          : weight === "bold" && format === "stroke"
          ? "/img/format-stroke-weight-bold-11.png"
          : weight === "duotone" && format === "stroke"
          ? formatStrokeWeight
          : "/img/format-outline-weight-regular-11.png"
      }
    />
  );
};

Eye.propTypes = {
  format: PropTypes.oneOf(["outline", "stroke"]),
  weight: PropTypes.oneOf(["duotone", "regular", "thin", "bold", "fill", "light"]),
  formatStrokeWeight: PropTypes.string,
};
