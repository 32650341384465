import React from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { Screen } from "./screens/Screen/index.js";
import { Screen6 } from "./screens/Screen6/index.js";
import { NewsMobile } from "./screens/NewsMobile/index.js";
import { IncubatorMobile } from "./screens/IncubatorMobile/index.js";
import { AboutTheCentre } from "./screens/AboutTheCentre/index.js";
import { NewsDetails } from "./screens/NewsDetails/NewsDetails.jsx";
import { Register } from "./screens/Register/index.js";
import { TranslationProvider, useTranslation } from './translations/TranslationContext.js';



export const App = () => {
  const {locale} = useTranslation();

  console.log(locale);
  
  const router = createBrowserRouter([
    {
      path: `/`,
      element:<Navigate to="/ar" />,
    },
    {
      path: `/${"en"}`,
      element: <Screen />,
    },
    {
      path: `/${"en"}/home`,
      element: <Screen />,
    },
    {
      path: `/${"en"}/partners`,
      element: <Screen6 />,
    },
    {
      path: `/${"en"}/about`,
      element: <AboutTheCentre />,
    },
    {
      path: `/${"en"}/incubator`,
      element: <IncubatorMobile />,
    },
    {
      path: `/${"en"}/news`,
      element: <NewsMobile/>,
    },
    {
      path: `/${"en"}/newsdetails`,
      element: <NewsDetails/>,
    },
    {
      path:`/${"en"}/register`,
      element: <Register/>,
    },
    {
      path: `/${"ar"}/`,
      element: <Screen />,
    },

    {
      path: `/${"ar"}/home`,
      element: <Screen />,
    },
    {
      path: `/${"ar"}/partners`,
      element: <Screen6 />,
    },
    {
      path: `/${"ar"}/about`,
      element: <AboutTheCentre />,
    },
    {
      path: `/${"ar"}/incubator`,
      element: <IncubatorMobile />,
    },
    {
      path: `/${"ar"}/news`,
      element: <NewsMobile/>,
    },
    {
      path: `/${"ar"}/newsdetails`,
      element: <NewsDetails/>,
    },
    {
      path:`/${"ar"}/register`,
      element: <Register/>,
    },
    // {
    //   path: "/u1575u1604u1585u1610u1620u1610u1587u1610u1577",
    //   element: <Screen />,
    // },
    // {
    //   path: "/u1575u1604u1578u1602u1575u1585u1610u1585",
    //   element: <ScreenScreen />,
    // },
    // {
    //   path: "/u1575u1604u1601u1575u1593u1604u1610u1575u1578",
    //   element: <DivWrapper />,
    // },
    // {
    //   path: "/u1575u1604u1582u1583u1605u1575u1578",
    //   element: <Screen5 />,
    // },
    // {
    //   path: "/u1593u1606-u1575u1604u1605u1585u1603u1586-1",
    //   element: <ScreenWrapper />,
    // },
    // {
    //   path: "/u1593u1606-u1575u1604u1605u1585u1603u1586-2",
    //   element: <Screen8 />,
    // },
    // {
    //   path: "/language-1",
    //   element: <Language />,
    // },
    // {
    //   path: "/u1575u1604u1575u1582u1576u1575u1585",
    //   element: <Screen10 />,
    // },
    // {
    //   path: "/language-2",
    //   element: <LanguageScreen />,
    // },

  ]);
  return<>
  <RouterProvider router={router} />

  </>
  
  
  ;
};
