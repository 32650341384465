/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { FormatStrokeWeightLight2 } from "../../icons/FormatStrokeWeightLight2";
import { FormatStrokeWeightThin3 } from "../../icons/FormatStrokeWeightThin3";
import { ButtonHover } from "../ButtonHover";
import { LinkTo } from "../LinkTo";
import "./style.css";

export const NewsCard = ({
  property1,
  className,
  rectangleClassName,
  rectangle = "/img/rectangle-6-3.png",
  divClassName,
  linkToIcon = <FormatStrokeWeightLight2 className="format-stroke-weight-light-2" color="#2A499E" />,
  buttonHoverClassName,
  frameClassName,
  text = "أخبار",
  buttonHoverText = "احجز مقعدك",
  buttonHoverIcon = <FormatStrokeWeightLight2 className="arrowupleft-1" color="#2A499E" />,
  divClassNameOverride,
  override = <FormatStrokeWeightLight2 className="format-stroke-weight-light-2" color="#2A499E" />,
}) => {
  return (
    <div className={`news-card ${property1} ${className}`}>
      {["button", "default"].includes(property1) && (
        <>
          <img className={`rectangle-2 ${rectangleClassName}`} alt="Rectangle" src={rectangle} />
          <div className="frame-11">
            <div className="frame-12">
              <div className="frame-13">
                <div className="text-wrapper-11">الخميس 29 أبريل, 2024</div>
                <FormatStrokeWeightThin3 className="clock-afternoon" color="#2A499E" />
              </div>
              <p className={`text-wrapper-12 ${divClassName}`}>
                التحول الوطني يُكرم التحلية لتحقيقها رقماً عالمياً في الإنتاج وزيادة نوعية في الاداء
              </p>
            </div>
            {property1 === "default" && <LinkTo className="link-to-instance" icon={linkToIcon} property1="default" />}

            {property1 === "button" && (
              <div className="button-hover-wrapper">
                <ButtonHover
                  className="button-hover-instance"
                  divClassName="instance-node"
                  formatStrokeWeightLight2StyleOverrideClassName={buttonHoverClassName}
                  icon={buttonHoverIcon}
                  property1="default"
                  text={buttonHoverText}
                />
              </div>
            )}
          </div>
          <div className={`frame-14 ${frameClassName}`}>
            <div className="text-wrapper-13">{text}</div>
          </div>
        </>
      )}

      {property1 === "hover" && (
        <>
          <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-6-3.png" />
          <div className="frame-wrapper">
            <div className="frame-11">
              <div className="frame-12">
                <div className="frame-13">
                  <div className="text-wrapper-11">الخميس 29 أبريل, 2024</div>
                  <FormatStrokeWeightThin3 className="clock-afternoon" color="#2A499E" />
                </div>
                <p className={`text-wrapper-12 ${divClassNameOverride}`}>
                  التحول الوطني يُكرم التحلية لتحقيقها رقماً عالمياً في الإنتاج وزيادة نوعية في الاداء
                </p>
              </div>
              <LinkTo className="link-to-instance" icon={override} property1="default" />
            </div>
          </div>
          <div className="rectangle-3" />
          <div className="rectangle-4" />
          <div className="frame-14">
            <div className="text-wrapper-13">{text}</div>
          </div>
        </>
      )}
    </div>
  );
};

NewsCard.propTypes = {
  property1: PropTypes.oneOf(["hover", "button", "default"]),
  rectangle: PropTypes.string,
  text: PropTypes.string,
  buttonHoverText: PropTypes.string,
};
