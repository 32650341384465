/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Caretdown } from "../../icons/Caretdown";
import { FormatOutlineWeightBold } from "../../icons/FormatOutlineWeightBold";
import { FormatOutlineWeightDuotone } from "../../icons/FormatOutlineWeightDuotone";
import { FormatOutlineWeightFill } from "../../icons/FormatOutlineWeightFill";
import { FormatOutlineWeightLight } from "../../icons/FormatOutlineWeightLight";
import { FormatOutlineWeightRegular } from "../../icons/FormatOutlineWeightRegular";
import { FormatOutlineWeightThin } from "../../icons/FormatOutlineWeightThin";
import { FormatStrokeWeightBold } from "../../icons/FormatStrokeWeightBold";
import { FormatStrokeWeightDuotone } from "../../icons/FormatStrokeWeightDuotone";
import { FormatStrokeWeightFill } from "../../icons/FormatStrokeWeightFill";
import { FormatStrokeWeightRegular } from "../../icons/FormatStrokeWeightRegular";
import { FormatStrokeWeightThin } from "../../icons/FormatStrokeWeightThin";
import "./style.css";

export const CaretDown = ({ format, weight }) => {
  return (
    <>
      {weight === "regular" && format === "outline" && <FormatOutlineWeightRegular className="instance-node-8" />}

      {format === "outline" && weight === "thin" && <FormatOutlineWeightThin className="instance-node-8" />}

      {weight === "light" && format === "outline" && <FormatOutlineWeightLight className="instance-node-8" />}

      {format === "outline" && weight === "bold" && <FormatOutlineWeightBold className="instance-node-8" />}

      {format === "outline" && weight === "fill" && <FormatOutlineWeightFill className="instance-node-8" />}

      {format === "outline" && weight === "duotone" && <FormatOutlineWeightDuotone className="instance-node-8" />}

      {weight === "regular" && format === "stroke" && <FormatStrokeWeightRegular className="instance-node-8" />}

      {weight === "thin" && format === "stroke" && <FormatStrokeWeightThin className="instance-node-8" />}

      {weight === "light" && format === "stroke" && <Caretdown className="instance-node-8" color="#343330" />}

      {weight === "bold" && format === "stroke" && <FormatStrokeWeightBold className="instance-node-8" />}

      {format === "stroke" && weight === "fill" && <FormatStrokeWeightFill className="instance-node-8" />}

      {weight === "duotone" && format === "stroke" && <FormatStrokeWeightDuotone className="instance-node-8" />}
    </>
  );
};

CaretDown.propTypes = {
  format: PropTypes.oneOf(["outline", "stroke"]),
  weight: PropTypes.oneOf(["duotone", "regular", "thin", "bold", "fill", "light"]),
};
