/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Paperplanetilt2 = ({ className }) => {
  return (
    <svg
      className={`paperplanetilt-2 ${className}`}
      fill="none"
      height="13"
      viewBox="0 0 13 13"
      width="13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M11.5857 2.71543C11.5857 2.71543 11.5857 2.72012 11.5857 2.72246L8.85757 11.7196C8.81628 11.8658 8.73128 11.9958 8.61398 12.0922C8.49667 12.1887 8.35268 12.2469 8.20132 12.2592C8.17976 12.2611 8.1582 12.262 8.13664 12.262C7.99479 12.2624 7.85578 12.2223 7.73601 12.1463C7.61624 12.0703 7.52071 11.9616 7.4607 11.8331L5.75445 8.33152C5.73737 8.29643 5.73168 8.25689 5.73815 8.21841C5.74462 8.17992 5.76293 8.14442 5.79054 8.11684L8.50554 5.40184C8.5729 5.33094 8.6099 5.23653 8.60864 5.13875C8.60739 5.04096 8.56799 4.94753 8.49884 4.87838C8.42969 4.80923 8.33626 4.76983 8.23847 4.76858C8.14069 4.76733 8.04628 4.80432 7.97539 4.87168L5.25898 7.58668C5.2314 7.61429 5.19589 7.63261 5.15741 7.63908C5.11893 7.64555 5.07938 7.63985 5.04429 7.62277L1.53945 5.91699C1.40239 5.85124 1.28856 5.7454 1.21302 5.61348C1.13748 5.48157 1.10381 5.32982 1.11647 5.17834C1.12912 5.02685 1.18751 4.88279 1.28389 4.76525C1.38028 4.6477 1.5101 4.56221 1.65617 4.52012L10.6534 1.79199H10.6604C10.7885 1.756 10.9239 1.75474 11.0526 1.78834C11.1814 1.82193 11.2989 1.88918 11.393 1.98317C11.4872 2.07716 11.5547 2.19452 11.5886 2.3232C11.6224 2.45188 11.6214 2.58726 11.5857 2.71543Z"
        fill="white"
      />
    </svg>
  );
};
