/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatOutlineWeightThin8 = ({ className }) => {
  return (
    <svg
      className={`format-outline-weight-thin-8 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M26.4213 26.7313L18.3913 14.1125L26.375 5.33626C26.4642 5.23681 26.5102 5.106 26.5029 4.97261C26.4957 4.83923 26.4357 4.71419 26.3363 4.62501C26.2368 4.53584 26.106 4.48982 25.9726 4.49708C25.8392 4.50435 25.7142 4.56431 25.625 4.66376L17.835 13.2375L12.4225 4.73126C12.3773 4.66026 12.3148 4.60184 12.2409 4.56141C12.1671 4.52099 12.0842 4.49987 12 4.50001H6.00001C5.91042 4.50008 5.82249 4.52422 5.74541 4.56992C5.66834 4.61561 5.60497 4.68117 5.56191 4.75974C5.51886 4.83831 5.4977 4.92701 5.50067 5.01656C5.50363 5.10611 5.53061 5.19321 5.57876 5.26876L13.6088 17.8875L5.62501 26.6638C5.58086 26.713 5.54683 26.7705 5.52488 26.8329C5.50293 26.8953 5.49349 26.9614 5.49708 27.0274C5.50068 27.0935 5.51725 27.1581 5.54585 27.2178C5.57445 27.2774 5.61452 27.3309 5.66376 27.375C5.71301 27.4192 5.77047 27.4532 5.83287 27.4751C5.89526 27.4971 5.96137 27.5065 6.02741 27.5029C6.09346 27.4993 6.15815 27.4828 6.21779 27.4542C6.27743 27.4256 6.33086 27.3855 6.37501 27.3363L14.17 18.7625L19.5825 27.2688C19.6273 27.3391 19.689 27.397 19.7619 27.4374C19.8348 27.4778 19.9167 27.4993 20 27.5H26C26.0896 27.4999 26.1775 27.4758 26.2546 27.4301C26.3317 27.3844 26.3951 27.3189 26.4381 27.2403C26.4812 27.1617 26.5023 27.073 26.4994 26.9835C26.4964 26.8939 26.4694 26.8068 26.4213 26.7313ZM20.275 26.5L6.91126 5.50001H11.725L25.0888 26.5H20.275Z"
        fill="#343330"
      />
    </svg>
  );
};
