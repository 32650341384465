/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";
import { useWindowWidth } from "../../breakpoints";
import { useTranslation } from "../../translations/TranslationContext";

export const SlideHover = ({background,content, property1, className, divClassName, text = "عنوان رئيسي" }) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  const screenWidth = useWindowWidth();
  const { t, changeLocale, locale } = useTranslation();


  return (
    <div
      className={`slide-hover ${state.property1} ${className}`}
      style={{backgroundImage: `url(${background})`,}}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
    >
      {state.property1 === "default" && <div className={`text-wrapper-15`} style={locale=="ar"?{right:"10%",fontSize:"1.25rem"}:{left:"10%",fontSize:".8rem"}}>
        <span style={{backgroundColor:"rgb(42, 73, 158)",width:"80%"}}>

      {text}
        </span>
      </div>}

      {state.property1 === "variant-2" && (
        <>
          <div style={locale=="ar"?{right:"10%",fontSize:"1.5rem"}:{left:"10%",fontSize:"1.25rem"}} className={screenWidth<2000?"text-wrapper-16":"text-wrapper-16"}>
          <span style={{backgroundColor:"rgb(42, 73, 158)"}}>

            {text}
            </span>
            </div>
          <p className={screenWidth<2000?"text-wrapper-17":"text-wrapper-17"}>
            {content}
          </p>
        </>
      )}

      {/* <img
        className="line-3"
        alt="Line"
        src={state.property1 === "variant-2" ? "/img/line-6-1.svg" : "/img/line-6.svg"}
      /> */}
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "variant-2",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "default",
      };
  }

  return state;
}

SlideHover.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  text: PropTypes.string,
};
