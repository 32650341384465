/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Group = ({ property1, className, property = "/img/property-1-variant2-2.svg" }) => {
  return (
    <img
      className={`group property-1-1-${property1} ${className}`}
      alt="Property default"
      src={property1 === "variant-2" ? property : "/img/property-1-default-3.svg"}
    />
  );
};

Group.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  property: PropTypes.string,
};
