/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Copyright = ({ className }) => {
  return (
    <svg
      className={`copyright ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke="#DBDBDB"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M15 14.25C14.5278 14.8796 13.8694 15.3448 13.1181 15.5794C12.3669 15.8141 11.5608 15.8065 10.8141 15.5576C10.0675 15.3087 9.41804 14.8312 8.95784 14.1927C8.49764 13.5542 8.25 12.7871 8.25 12C8.25 11.2129 8.49764 10.4458 8.95784 9.80734C9.41804 9.16884 10.0675 8.69133 10.8141 8.44244C11.5608 8.19355 12.3669 8.1859 13.1181 8.42057C13.8694 8.65525 14.5278 9.12035 15 9.75"
        stroke="#DBDBDB"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
