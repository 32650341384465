/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormatStrokeWeightLight1 } from "../../icons/FormatStrokeWeightLight1";
import { NavPages } from "../NavPages";
import "./style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { List } from "../List";
import { useWindowWidth } from "../../breakpoints";
import { useTranslation } from "../../translations/TranslationContext";
import { navigate } from "@storybook/addon-links";

export const Navbar = ({ className, logo = "/img/logo.svg" }) => {

  const screenWidth = useWindowWidth();
  let [menue, setMenue] = useState(0)
  let [burger, setBurger] = useState(0)
  let [overlay, setOverlay] = useState(0)

  const navbarMenu = document.getElementById("menu");
  const burgerMenu = document.getElementById("burger");
  const bgOverlay = document.getElementById("overlay");

  // Show Menu when Click the Burger
  // Hide Menu when Click the Overlay
  // if (burgerMenu && navbarMenu && bgOverlay) {
  //   burgerMenu.addEventListener("click", () => {
  //     console.log("daried");
  //     navbarMenu.classList.toggle("is-active");
  //     bgOverlay.classList.toggle("is-active");
  //   });

  //   bgOverlay.addEventListener("click", () => {
  //     console.log("not snldnlv");
  //     navbarMenu.classList.toggle("is-active");
  //     bgOverlay.classList.toggle("is-active");
  //   });
  // }

  // Hide Menu when Click the Links
  document.querySelectorAll(".menu-link").forEach((link) => {
    link.addEventListener("click", () => {
      navbarMenu.classList.remove("is-active");
      bgOverlay.classList.remove("is-active");
    });
  });

  const { t, changeLocale, locale } = useTranslation();
  let navigation = useNavigate()
  let location = useLocation()
  console.log(location);
  const handleLocaleChange = () => {
    changeLocale(locale === 'en' ? 'ar' : 'en');
    navigation(location.pathname.split("/")[1] === 'en' ?
      `/ar/${location?.pathname?.split("/")[2] ? location?.pathname?.split("/")[2] : ""}${location.search ? location.search : ""}`
      :
      `/en/${location.pathname.split("/")[2] ? location.pathname.split("/")[2] : ""}${location.search ? location.search : ""}`
    )
  };

  return (
    <>


      <div dir={locale === 'ar' ? "rtl" : "ltr"} className={`navbar  ${className}`}>
        <div className="frame">
          <div className="flex justify-around items-center flex-row-reverse gap-4">

            <div onClick={() => burger == 1 ? setBurger(0) : setBurger(1)} className="frame-2" id="burger">
              <FormatStrokeWeightLight1 className="format-stroke-weight-light-1" />
            </div>
            <Link to={`/${locale}/register`}>
              <svg
                className={` w-[2rem] h-[2rem]`}
                fill="none"
                height="25"
                viewBox="0 0 24 25"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="path"
                  d="M12 15.5C15.3137 15.5 18 12.8137 18 9.5C18 6.18629 15.3137 3.5 12 3.5C8.68629 3.5 6 6.18629 6 9.5C6 12.8137 8.68629 15.5 12 15.5Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
                <path
                  className="path"
                  d="M3 20.75C4.81594 17.6122 8.11406 15.5 12 15.5C15.8859 15.5 19.1841 17.6122 21 20.75"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </svg>
            </Link>
            <div className="text-wrapper-2  cursor-pointer" style={{ color: "white" }} onClick={handleLocaleChange}>
              {locale === 'ar' ? 'English' : 'العربية'}
            </div>

          </div>
          <span onClick={() => { setBurger(0) }} id="overlay" className={burger == 1 ? "overlay is-active" : "overlay"}></span>
          <div className={burger == 1 ? "menu is-active" : "menu"} id="menu">
            <ul className="pt-[50px] px-[15px] menu-inner flex flex-col gap-[30px] items-end flex-col-reverce justify-center">
              <li className="menu-item">
                <NavPages
                  className="nav-pages-instance text-black"
                  divClassName="design-component-instance-node text-white-w	"
                  property1="off"
                  text={t("homepage")}
                  to={`/${locale}/home`}
                />
              </li>
              <li className="menu-item">
                <NavPages
                  className="nav-pages-instance"
                  divClassName="design-component-instance-node text-white-w"
                  property1="off"
                  text={t("aboutCenter")}
                  to={`/${locale}/about`}
                />
              </li>
              <li className="menu-item">
                <NavPages
                  className="nav-pages-instance"
                  divClassName="design-component-instance-node text-white-w"
                  property1="off"
                  text={t("waterTechNetwork")}
                  to={`/${locale}/news?type=news&&serch=`}
                />
              </li>
              <li className="menu-item">
                <NavPages
                  className="nav-pages-instance"
                  divClassName="design-component-instance-node text-white-w"
                  property1="off"
                  text={t("incubator")}
                  to={`/${locale}/incubator`}
                />
              </li>
              <li className="menu-item">
                <NavPages
                  className="nav-pages-instance"
                  divClassName="design-component-instance-node text-white-w"
                  property1="off"
                  text={t("partners")}
                  to={`/${locale}/partners`}
                />
              </li>


            </ul>
          </div>
          <div className={screenWidth > 3000 ? "frame-3" : "frame-3"}>
            <div className="frame-4 flex justify-center items-center gap-[1rem] md:hidden flex-row-reverse">
              <NavPages
                className="nav-pages-instance"
                divClassName={screenWidth > 3000 ? "design-component-instance-node" : "design-component-instance-node"}
                property1="off"
                text={t("partners")}
                to={`/${locale}/partners`}
              />
              {/* <NavPages
              className="nav-pages-instance"
              divClassName="design-component-instance-node"
              property1="off"
              text="الخدمات"
              to="/u1575u1604u1582u1583u1605u1575u1578"
            /> */}


              <NavPages
                className="nav-pages-instance"
                divClassName={screenWidth > 3000 ? "design-component-instance-node" : "design-component-instance-node"}
                property1="off"
                text={t("incubator")}
                to={`/${locale}/incubator`}
              />
              <NavPages
                className="nav-pages-instance"
                divClassName={screenWidth > 3000 ? "design-component-instance-node" : "design-component-instance-node"}
                property1="off"
                text={t("waterTechNetwork")}
                to={`/${locale}/news?type=news&&serch=`}
              />

              {/* <NavPages
              className="nav-pages-instance"
              divClassNameOverride="design-component-instance-node"
              property1="on"
              text={t("waterTechNetwork")}
              to="/u1575u1604u1581u1575u1590u1606u1577"
            /> */}
              <div className="dropdown">
                <NavPages
                  className="nav-pages-instance"
                  divClassName={screenWidth > 3000 ? "design-component-instance-node" : "design-component-instance-node"}
                  property1="on"
                  text={t("aboutCenter")}
                  to={`/${locale}/about`}
                />
                <div className={screenWidth < 2000 ? "dropdown-content" : "dropdown-content"}>
                  <a
                    className={screenWidth > 3000 ? "mt-[70px] design-component-instance-node" : "mt-[35px] design-component-instance-node"}
                    href="#about"
                  >{t("aboutCenter")}</a>
                  <a className={screenWidth > 3000 ? "design-component-instance-node" : "design-component-instance-node"} href="#content">{t("centerObjectives")}</a>
                  <a className={screenWidth > 3000 ? "design-component-instance-node" : "design-component-instance-node"} href="#massage">{t("visionMission")}</a>
                  <a className={screenWidth > 3000 ? "design-component-instance-node" : "design-component-instance-node"} href="#team">{t("centerTeam")}</a>
                </div>
              </div>
              <NavPages
                className="nav-pages-instance"
                divClassName={screenWidth > 3000 ? "design-component-instance-node" : "design-component-instance-node"}
                property1="off"
                text={t("homepage")}
                to={`/${locale}/home`}
              />

            </div>
            <Link to={`/${locale}/home`} className={screenWidth > 3000 ? "logo" : "logo"}>
              <img className={screenWidth > 3000 ? "logo" : "logo"} alt="Logo" src={logo} />
            </Link>
          </div>
        </div>
      </div>

    </>
  );
};

Navbar.propTypes = {
  logo: PropTypes.string,
};
