/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const House = ({ format, weight, className, formatOutline = "/img/format-outline-weight-regular-3.png" }) => {
  return (
    <img
      className={`house ${className}`}
      alt="Format outline"
      src={
        weight === "regular"
          ? formatOutline
          : weight === "light"
          ? "/img/format-outline-weight-light-3.png"
          : format === "outline" && weight === "bold"
          ? "/img/format-outline-weight-bold-3.png"
          : weight === "fill"
          ? "/img/format-outline-weight-fill-3.png"
          : weight === "duotone"
          ? "/img/format-outline-weight-duotone-3.png"
          : weight === "thin" && format === "stroke"
          ? "/img/format-stroke-weight-thin-1.png"
          : weight === "bold" && format === "stroke"
          ? "/img/format-stroke-weight-bold-1.png"
          : "/img/format-outline-weight-thin-3.png"
      }
    />
  );
};

House.propTypes = {
  format: PropTypes.oneOf(["outline", "stroke"]),
  weight: PropTypes.oneOf(["duotone", "regular", "thin", "bold", "fill", "light"]),
  formatOutline: PropTypes.string,
};
