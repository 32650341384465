/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { ArrowUpLeft } from "../ArrowUpLeft";
import { NavPages } from "../NavPages";
import { User } from "../User";
import "./style.css";
export const Tablets = ({ className, logo = "/img/logo-8.svg" }) => {
  return (
    <div className={`tablets ${className}`}>
      <div className="frame-4">
        <div className="frame-5">
          <div className="button-hover">
            <div className="text-wrapper-8">قدّم الآن</div>
            <ArrowUpLeft
              className="arrow-up-left-instance"
              format="stroke"
              formatOutline="/img/arrowupleft.png"
              weight="regular"
            />
          </div>
         
        </div>
        <div className="frame-6">
          <div className="frame-7">
          {/*
            <NavPages className="nav-pages-instance" divClassName="nav-pages-2" property1="off" text="الشركاء" />
            <NavPages className="nav-pages-instance" divClassName="nav-pages-2" property1="off" text="الخدمات" /> */}
            <NavPages className="nav-pages-instance" divClassName="nav-pages-2" property1="off" text="الحاضنة" />
            {/*<NavPages
            
              className="nav-pages-instance"
              divClassNameOverride="nav-pages-2"
              property1="on"
              text="شبكة تقنيات المياه"
            />*/ }
            <NavPages
              className="nav-pages-instance"
              divClassNameOverride="nav-pages-2"
              property1="on"
              text="عن المركز"
            />
          </div>
          <img className="logo-2" alt="Logo" src={logo} />
        </div>
      </div>
    </div>
  );
};

Tablets.propTypes = {
  logo: PropTypes.string,
};
