/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { FormatOutlineWeightBold10 } from "../../icons/FormatOutlineWeightBold10";
import { FormatOutlineWeightDuotone10 } from "../../icons/FormatOutlineWeightDuotone10";
import { FormatOutlineWeightFill10 } from "../../icons/FormatOutlineWeightFill10";
import { FormatOutlineWeightRegular10 } from "../../icons/FormatOutlineWeightRegular10";
import { FormatOutlineWeightThin10 } from "../../icons/FormatOutlineWeightThin10";
import { FormatStrokeWeightBold11 } from "../../icons/FormatStrokeWeightBold11";
import { FormatStrokeWeightDuotone10 } from "../../icons/FormatStrokeWeightDuotone10";
import { FormatStrokeWeightLight11 } from "../../icons/FormatStrokeWeightLight11";
import { FormatStrokeWeightRegular11 } from "../../icons/FormatStrokeWeightRegular11";
import { FormatStrokeWeightThin11 } from "../../icons/FormatStrokeWeightThin11";
import "./style.css";

export const LinkedinLogo = ({ format, weight }) => {
  return (
    <>
      {weight === "regular" && format === "outline" && <FormatOutlineWeightRegular10 className="instance-node-3" />}

      {format === "outline" && ["light", "thin"].includes(weight) && (
        <FormatOutlineWeightThin10 className="instance-node-3" />
      )}

      {format === "outline" && weight === "bold" && <FormatOutlineWeightBold10 className="instance-node-3" />}

      {weight === "fill" && <FormatOutlineWeightFill10 className="instance-node-3" />}

      {format === "outline" && weight === "duotone" && <FormatOutlineWeightDuotone10 className="instance-node-3" />}

      {weight === "regular" && format === "stroke" && <FormatStrokeWeightRegular11 className="instance-node-3" />}

      {weight === "thin" && format === "stroke" && <FormatStrokeWeightThin11 className="instance-node-3" />}

      {weight === "light" && format === "stroke" && <FormatStrokeWeightLight11 className="instance-node-3" />}

      {weight === "bold" && format === "stroke" && <FormatStrokeWeightBold11 className="instance-node-3" />}

      {weight === "duotone" && format === "stroke" && <FormatStrokeWeightDuotone10 className="instance-node-3" />}
    </>
  );
};

LinkedinLogo.propTypes = {
  format: PropTypes.oneOf(["outline", "stroke"]),
  weight: PropTypes.oneOf(["duotone", "regular", "thin", "bold", "fill", "light"]),
};
