const messages =  {
  homepage: "الرئيسية",
  aboutCenter: "عن المركز",
  waterTechNetwork: "شبكة تقنيات المياه",
  incubator: "الحاضنة",
  partners: "الشركاء",
  saudiWater: "مركز الابتكار السعودي ",
  InnovationCenter: " لتقنيات المياه",
  towardsSustainableFuture: "نحو مستقبل مستدام",
  industrialCapacityDevelopment: "تنمية القدرات الصناعية للمشاريع الصغيرة والمتوسطة",
  learnAboutCenter: "تعرف عن المركز",
  ourPartners: "شركاؤنا",
  waterInnovationNetwork: "شبكة المياه للابتكار",
  latestNewsReportsWorkshopsEvents: "آخر الأخبار والتقارير وكذلك ورش العمل والفعاليات",
  showMore: "عرض المزيد",
  saudiWaterInnovationCenterReport2023: "التقرير الختامي لمركز الابتكار السعودي لتقنيات المياه 2023",
  downloadReport: "تحميل التقرير",
  newsletter: "النشرة الإخبارية",
  connectWithUsOnSocialMedia: "تواصل معنا عبر مواقع التواصل الاجتماعي",
  waterInnovationNetwork: "شبكة المياه للابتكار",
  events: "الفعاليات",
  news: "الأخبار",
  incubator: "الحاضنة",
  waterIncubator: "حاضنة تقنيات المياه",
  stages: "المراحل",
  registrationRequirements: "متطلبات التسجيل",
  currentIncubatees: "المحتضنين الحاليين",
  aboutCenter: "عن المركز",
  aboutCenter: "نبذه عن المركز",
  centerObjectives: "أهداف المركز",
  visionMission: "الروئية و الرسالة",
  homepage: "الرئيسية",
  centerTeam: "فريق المركز",
  news: "الأخبار",
  events: "الفعاليات",
  reports: "التقارير",
  search: "البحث",
  searchWord: "كلمة البحث",
  connectWithUsOnSocialMedia: "تواصل معنا عبر مواقع التواصل الاجتماعي",
  allRightsReserved: "جميع الحقوق محفوظة لمركز الابتكار السعودي لتقنياة المياه 2022",
  privacyPolicy: "سياسه الخصوصية",
  termsOfUse: "شروط الاستخدام",
  incubatorTimeline: "المخطط الزمني لبرنامج الحاضنة",
  startupPathDescription: "يتلخص مسار الشركات الناشئة خلال برنامج الاحتضان على هذه المراحل",
  may142024: "١٤ مايو ٢٠٢٤",
  registrationStarts: "بدء التسجيل",
  may242024: "٢٤ مايو ٢٠٢٤",
  registrationCloses: "إغلاق التسجيل",
  may242024ToJune102024: "٢٤ مايو ٢٠٢٤ إلى ١٠ يونيو ٢٠٢٤",
  sortingAndAcceptanceProcess: "عملية الفرز والقبول",
  june242024: "٢٤ يونيو ٢٠٢٤",
  programStarts: "بدء البرنامج",
  december202024: "٢٠ ديسمبر ٢٠٢٤",
  programConclusionAndGraduationCeremony: "ختام البرنامج و حفل التخريج",
  currentIncubatees: "المحتضنين الحاليين",
  saudiWaterInnovationCenter: "مركز الابتكار السعودي لتقنيات المياه",
  newUserRegistration: "تسجيل مستخدم جديد",
  userType: "نوع المستخدم",
  personalInformation: "المعلومات الشخصية",
  governmentalEntities: "جهات حكومية",
  businessSector: "قطاع الأعمال",
  individuals: "أفراد",
  accountInformation: "معلومات الحساب",
  nationalIDNumber: "رقم الهوية الوطنية ",
  idType: "نوع الهوية ",
  select: "اختر",
  gender: "النوع",
  nationality: "الجنسية",
  male: "ذكر",
  female: "أنثى",
  fatherName: "اسم الأب",
  firstName: "الاسم الأول",
  lastName: "اسم العائلة",
  grandfatherName: "اسم الجد",
  mobileNumber: "رقم الجوال",
  confirm: "تأكيد",
  username: "اسم المستخدم",
  email: "البريد الإلكتروني",
  password: "كلمة المرور",
  confirmPassword: "تكرار كلمة المرور",
  organizationName: "اسم الجهة",
"commercialRegister":"السجل التجاري",
footer1:"مهتم بمعرفة آخر المستجدات عن مركز الابتكار السعودي لتقنيات المياه؟",
footer2:"اشترك الآن بالنشرة الإخبارية ليصلك كل جديد!",
"companyInformation": "معلومات الشركة أو الجهة",
"commissionerInformation": "معلومات المفوض",
"accountInformation": "معلومات الحساب",
"select": "أختر",
"citizenID": "هوية مواطن",
"residentID": "هوية مقيم",
"passport": "جواز سفر",
"male": "ذكر",
"female": "أنثى",
};

  
  
  export default messages;