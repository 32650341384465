import React, { useEffect } from "react";
import { useWindowWidth } from "../../breakpoints";
import { ArrowUpLeft } from "../../components/ArrowUpLeft";
import { CaretDown } from "../../components/CaretDown";
import { Copyright } from "../../components/Copyright";
import { FacebookLogo } from "../../components/FacebookLogo";
import { Footer } from "../../components/Footer";
import { House } from "../../components/House";
import { InstagramLogo } from "../../components/InstagramLogo";
import { LinkedinLogo } from "../../components/LinkedinLogo";
import { List } from "../../components/List";
import { Navbar } from "../../components/Navbar";
import { Newsletter } from "../../components/Newsletter";
import { PaperPlaneTilt } from "../../components/PaperPlaneTilt";
import { TextHover } from "../../components/TextHover";
import { User } from "../../components/User";
import { XLogo } from "../../components/XLogo";
import { YoutubeLogo } from "../../components/YoutubeLogo";
import "./style.css";
import { Clockafternoon8 } from "../../icons/Clockafternoon8";
import { useReports, useSpacificReports } from "../../api/api";
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from "../../translations/TranslationContext";

export const NewsDetails = () => {


  const screenWidth = useWindowWidth();

  let params = new URL(document.location).searchParams;
  console.log(params.get("id"));
  let spacificReport = useSpacificReports(params.get("id"))
  console.log(spacificReport);

  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, []);
  const { t, changeLocale, locale } = useTranslation();

  return (<>
    <div
      className="news-mobile"
      dir={locale == "ar" ? "rtl" : "ltr"}
    >
      <Navbar className="navbar-instance" logo="/img/logo-3.png" />

      <div className="frame-125 mt-[6.25rem]">
        <div className="frame-126">
          <div className="aframe-127">
          <div className="aframe-128">
              <House className={screenWidth < 3000 ? "ainstance-node-8" : "ainstance-node-8more"} format="stroke" formatOutline="/img/house.png" weight="regular" />
              <div className={screenWidth < 3000 ? "aitem-link-home-5" : "aitem-link-home-5more"}>{t("homepage")}</div>
            </div>

            <div className={screenWidth < 3000 ? "atext-wrapper-104" : "atext-wrapper-104more"}>/

            </div>

            <Link to={"/news?type=news&&serch="} className={screenWidth < 3000 ? "atext-wrapper-103" : "atext-wrapper-103more"}>
{t("waterTechNetwork")}    
        </Link>

            <div className={screenWidth < 3000 ? "atext-wrapper-104" : "atext-wrapper-104more"}>
              /
            </div>

            <Link to={`/news?type=${spacificReport?.data?.type}&&serch=`} className={screenWidth < 3000 ? "atext-wrapper-103" : "atext-wrapper-103more"}>
              {spacificReport?.data?.type == "news" ?locale=="ar"? "أخبار":"News" : spacificReport?.data?.type == "event" ?locale=="ar"?  "فعاليات":"Event" :locale=="ar"?  "تقارير":"Report"}
              {/* شبكة تقنيات المياه */}
            </Link>


            <div className={screenWidth < 3000 ? "atext-wrapper-104" : "atext-wrapper-104more"}>/

            </div>
            <div className={screenWidth < 3000 ? "atext-wrapper-103" : "atext-wrapper-103more"}>
              {spacificReport?.data?.name[`${locale}`]}
              {/* شبكة تقنيات المياه */}
            </div>
          </div>
          <div className="w-full h-[43.25rem] " style={{ backgroundImage: `url(${spacificReport?.data?.attachment[0]?.original})`, backgroundSize: "cover", backgroundPosition: "center" }}>
            <div className="frame-340 z-20">
              <div className="frame-35">
                <div className={screenWidth < 2000 ? "text-wrapper-36" : "text-wrapper-36more"}>الخميس 29 أبريل, 2024</div>
                <Clockafternoon8 className="clockafternoon-8" />
              </div>
              <div className="frame-36">
                <p className={"text-wrapper-37"}
                  style={{
                    fontFamily:
                      screenWidth < 500
                        ? "var(--arabic-heading-h5-font-family)"
                        : screenWidth >= 500 && screenWidth < 834
                          ? "var(--arabic-heading-h4-font-family)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-font-family)"
                            : undefined,
                    fontSize:
                      screenWidth < 500
                        ? "var(--arabic-heading-h5-font-size)"
                        : screenWidth >= 500 && screenWidth < 834
                          ? "var(--arabic-heading-h4-font-size)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-font-size)"
                            : "var(--arabic-heading-h3-font-size)",
                    fontStyle:
                      screenWidth < 500
                        ? "var(--arabic-heading-h5-font-style)"
                        : screenWidth >= 500 && screenWidth < 834
                          ? "var(--arabic-heading-h4-font-style)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-font-style)"
                            : undefined,
                    fontWeight:
                      screenWidth < 500
                        ? "var(--arabic-heading-h5-font-weight)"
                        : screenWidth >= 500 && screenWidth < 834
                          ? "var(--arabic-heading-h4-font-weight)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-font-weight)"
                            : undefined,
                    letterSpacing:
                      screenWidth < 500
                        ? "var(--arabic-heading-h4-letter-spacing)"
                        : screenWidth >= 500 && screenWidth < 834
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-letter-spacing)"
                            : undefined,
                    lineHeight:
                      screenWidth < 834
                        ? "var(--arabic-heading-h4-line-height)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h3-line-height)"
                          : undefined,
                  }}
                >
                  {spacificReport?.data?.name[`${locale}`]}
                </p>

              </div>
            </div>
          </div>
        </div>
        <div className="px-[10%] py-[5%] w-full">

          <p className={screenWidth < 2000 ? "text-wrapper-250" : "text-wrapper-250"}>
            {spacificReport?.data?.description[`${locale}`].split(".").map((line) => <p className="mt-5">{line}.</p>)}
          </p>
        </div>
      </div>

    </div>
    <div className={screenWidth < 2000 ? "mt-[100px] lg:mt-[300px] sm:mt-[80px]" : "mt-[700px]"}></div>
    <Footer className="footer-3" logo="/img/logo-9.png" property1="web" />
  </>);
};
