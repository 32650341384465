import React, { useEffect, useState } from "react";
import { useWindowWidth } from "../../breakpoints";
import { ButtonHover } from "../../components/ButtonHover";
import { Footer } from "../../components/Footer";
import { LinkTo } from "../../components/LinkTo";
import { Navbar } from "../../components/Navbar";
import { NewsCard } from "../../components/NewsCard";
import { Newsletter } from "../../components/Newsletter";
import { SlideHover } from "../../components/SlideHover";
import { TextHover } from "../../components/TextHover";
import { Arrowupleft16 } from "../../icons/Arrowupleft16";
import { Arrowupleft21 } from "../../icons/Arrowupleft21";
import { Clockafternoon3 } from "../../icons/Clockafternoon3";
import { Clockafternoon8 } from "../../icons/Clockafternoon8";
import { Copyright } from "../../icons/Copyright";
import { Downloadsimple1 } from "../../icons/Downloadsimple1";
import { Facebooklogo } from "../../icons/Facebooklogo";
import { FormatStrokeWeightLight1 } from "../../icons/FormatStrokeWeightLight1";
import { FormatStrokeWeightLight12 } from "../../icons/FormatStrokeWeightLight12";
import { FormatStrokeWeightLight2 } from "../../icons/FormatStrokeWeightLight2";
import { FormatStrokeWeightThin3 } from "../../icons/FormatStrokeWeightThin3";
import { Group1 } from "../../icons/Group1";
import { Instagramlogo } from "../../icons/Instagramlogo";
import { Linkedinlogo } from "../../icons/Linkedinlogo";
import { Paperplanetilt2 } from "../../icons/Paperplanetilt2";
import { Paperplanetilt3 } from "../../icons/Paperplanetilt3";
import { Svg } from "../../icons/Svg";
import { Svg1 } from "../../icons/Svg1";
import { Svg2 } from "../../icons/Svg2";
import { Xlogo2 } from "../../icons/Xlogo2";
import { Youtubelogo } from "../../icons/Youtubelogo";
import "./style.css";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { usePage, usePartners, useReports } from "../../api/api";
import { Group } from "../../components/Group";
import { useTranslation } from "../../translations/TranslationContext";

// import { Link } from "react-router-dom";

export const Screen = () => {
  const screenWidth = useWindowWidth();
  const partners = usePartners()
  const report = useReports()
  const data = usePage(2)
  const sections = data?.data?.sections;
  let postOne = sections?.find((section) => section.id == 4)
  let postTwo = sections?.find((section) => section.id == 5)
  let postThree = sections?.find((section) => section.id == 6)
  let postFour = sections?.find((section) => section.id == 7)
  console.log(partners);

  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, []);

  const { t, changeLocale, locale } = useTranslation();

  const handleLocaleChange = () => {
    changeLocale(locale === 'en' ? 'ar' : 'en');
  };

  return (<>

    <div dir={locale == "ar" ? "rtl" : "ltr"} className="screen" >
      <Navbar className="navbar-instance" logo="/img/logo-3.png" />
      <div
        className="mobile"
        style={{
          backgroundColor: "#ffffff",
        }}
      >
        <div
          className="overlap"
        >

          <div
            className="container-14"
          >
            <div className="absolute top-0 bottom-0 right-0 left-0 z-10 "></div>

            <video id="vid" loop muted playsinline autoplay="autoplay" >

              <source src="/img/video.mp4" type="video/mp4" />

            </video>
            <div className="frame-15">
              <p className="text-wrapper-18"
                style={{
                  fontFamily:
                    screenWidth < 500
                      ? "var(--arabic-heading-h5-font-family)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h3-font-family)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h1-font-family)"
                          : "var(--arabic-heading-h1-font-family)",
                  fontSize:
                    screenWidth < 500
                      ? "var(--arabic-heading-h5-font-size)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h3-font-size)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h1-font-size)"
                          : "var(--arabic-heading-h1-font-size)",
                  fontStyle:
                    screenWidth < 500
                      ? "var(--arabic-heading-h5-font-style)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h3-font-style)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h1-font-style)"
                          : undefined,
                  fontWeight:
                    screenWidth < 500
                      ? "var(--arabic-heading-h5-font-weight)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h3-font-weight)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h1-font-weight)"
                          : undefined,
                  letterSpacing:
                    screenWidth < 500
                      ? "var(--arabic-heading-h4-letter-spacing)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h3-letter-spacing)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h1-letter-spacing)"
                          : undefined,
                  lineHeight:
                    screenWidth < 834
                      ? "var(--arabic-heading-h3-line-height)"
                      : screenWidth >= 834 && screenWidth < 1550
                        ? "var(--arabic-heading-h1-line-height)"
                        : undefined,
                }}
              
              >

{t("saudiWater")}                <br />
              {t("InnovationCenter")}</p>
              <div className={screenWidth < 2000 ? "text-wrapper-19" : "text-wrapper-19"}>{t("towardsSustainableFuture")}</div>
            </div>
            <img
              className={screenWidth < 2000 ? "https-lottiefiles" : "https-lottiefilesmore"}
              alt="Https lottiefiles"
              src="/img/https-lottiefiles-com-animations-scroll-white-1l1ilxw1hm.gif"
            />


          </div>
        </div>
        <div
          className="about-the-center"
        >


          <div className=" w-full bg-[#f7f7f7] lg:h-full lg:bg-white flex items-center justify-between lg:flex-col-reverse px-[5%]">
            <div className="w-[40%] px-4 lg:w-[100%] lg:p-[5%]" >
              <div
                className="frame-17"
                style={{
                  alignSelf: screenWidth >= 834 && screenWidth < 1250 ? "stretch" : undefined,
                  display:
                    screenWidth >= 834 && screenWidth < 1250
                      ? "flex"
                      : screenWidth >= 1250
                        ? "inline-flex"
                        : undefined,
                  // width: screenWidth >= 834 && screenWidth < 1250 ? "100%" : undefined,
                }}
              >
                <div
                  className="frame-18"
                  style={{
                    alignItems:
                      screenWidth >= 834 && screenWidth < 1250
                        ? "flex-end"
                        : screenWidth >= 1250
                          ? "flex-start"
                          : undefined,
                    alignSelf: screenWidth >= 834 && screenWidth < 1250 ? "stretch" : undefined,
                    display:
                      screenWidth >= 834 && screenWidth < 1250
                        ? "flex"
                        : screenWidth >= 1250
                          ? "inline-flex"
                          : undefined,
                    width: screenWidth >= 834 && screenWidth < 1250 ? "100%" : undefined,
                  }}
                >
                  <div
                    className="text-wrapper-20"
                    style={{
                      width: "100%",
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-family)"
                              : "var(--arabic-heading-h3-font-family)",
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-size)"
                              : "var(--arabic-heading-h3-font-size)",
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-style)"
                              : "var(--arabic-heading-h3-font-style)",
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-weight)"
                              : "var(--arabic-heading-h3-font-weight)",
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-letter-spacing)"
                              : "var(--arabic-heading-h3-letter-spacing)",
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-heading-h4-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-line-height)"
                            : "var(--arabic-heading-h3-line-height)",
                    }}
                  >
                    {postOne?.Posts[0].title[`${locale}`]}
                    {/* نبذة عن المركز */}
                  </div>
                  <p
                    className="text-wrapper-21"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-small-medium-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-small-medium-font-family)"
                              : "var(--arabic-text-small-medium-font-family)",
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-size)"
                              : "var(--arabic-text-large-medium-font-size)",
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-style)"
                              : "var(--arabic-text-large-medium-font-style)",
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-weight)"
                              : "var(--arabic-text-large-medium-font-weight)",
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-letter-spacing)"
                              : "var(--arabic-text-large-medium-letter-spacing)",
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-text-small-medium-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-text-small-medium-line-height)"
                            : "var(--arabic-text-small-medium-line-height)",
                    }}
                  >
                    {postOne?.Posts[0].description[`${locale}`]}

                    {/* أنشئ مركز الابتكار السعودي لتقنيات المياه لتفعيل منظومة الابتكار وريادة الأعمال في تقنيات المياه
                      من خلال تطوير نظام ريادي يقوم على تعزيز البنية التحتية والمساعدة في خلق وتطوير الشركات الناشئة في
                      قطاع المياه . */}
                  </p>
                </div>
                <div
                  className="frame-19"
                  style={{
                    alignItems:
                      screenWidth >= 834 && screenWidth < 1250
                        ? "flex-end"
                        : screenWidth >= 1250
                          ? "flex-start"
                          : undefined,
                    alignSelf: screenWidth >= 834 && screenWidth < 1250 ? "stretch" : undefined,
                    display:
                      screenWidth >= 834 && screenWidth < 1250
                        ? "flex"
                        : screenWidth >= 1250
                          ? "inline-flex"
                          : undefined,
                    width: screenWidth >= 834 && screenWidth < 1250 ? "100%" : undefined,
                  }}
                >
                  <div
                    className="frame-20"
                    style={{
                      alignSelf: screenWidth >= 834 && screenWidth < 1250 ? "stretch" : undefined,
                      display:
                        screenWidth >= 834 && screenWidth < 1250
                          ? "flex"
                          : screenWidth >= 1250
                            ? "inline-flex"
                            : undefined,
                      width: screenWidth >= 834 && screenWidth < 1250 ? "100%" : undefined,
                    }}
                  >
                    <p
                      className="text-wrapper-22"
                      style={{
                        flex: screenWidth >= 834 && screenWidth < 1250 ? "1" : undefined,
                        width: "100%",
                        fontFamily:
                          screenWidth >= 834 && screenWidth < 1250
                            ? "var(--arabic-text-regular-bold-font-family)"
                            : screenWidth >= 1250
                              ? "var(--arabic-text-medium-bold-font-family)"
                              : "var(--arabic-text-regular-bold-font-family)",
                        fontSize:
                          screenWidth <= 834 && screenWidth < 1250
                            ? "var(--arabic-text-regular-bold-font-size)"
                            : screenWidth >= 1250 && screenWidth < 2000
                              ? "var(--arabic-text-medium-bold-font-size)"
                              : "var(--arabic-text-medium-bold-font-size)",
                        fontStyle:
                          screenWidth >= 834 && screenWidth < 1250
                            ? "var(--arabic-text-regular-bold-font-style)"
                            : screenWidth >= 1250
                              ? "var(--arabic-text-medium-bold-font-style)"
                              : "var(--arabic-text-regular-bold-font-style)",
                        fontWeight:
                          screenWidth >= 834 && screenWidth < 1250
                            ? "var(--arabic-text-regular-bold-font-weight)"
                            : screenWidth >= 1250
                              ? "var(--arabic-text-medium-bold-font-weight)"
                              : "var(--arabic-text-regular-bold-font-weight)",
                        letterSpacing:
                          screenWidth >= 834 && screenWidth < 1250
                            ? "var(--arabic-text-regular-bold-letter-spacing)"
                            : screenWidth >= 1250
                              ? "var(--arabic-text-medium-bold-letter-spacing)"
                              : "var(--arabic-text-regular-bold-letter-spacing)",
                        lineHeight:
                          screenWidth >= 834 && screenWidth < 1250
                            ? "var(--arabic-text-regular-bold-line-height)"
                            : screenWidth >= 1250
                              ? "var(--arabic-text-medium-bold-line-height)"
                              : "var(--arabic-text-regular-bold-line-height)",
                        // width: screenWidth >= 1250 ? "360px" : undefined,
                      }}
                    >
{t("industrialCapacityDevelopment")}         
           </p>
                  </div>
                </div>
                <NavLink to={`${locale}/about`} className={"frame-21"}>

                  <div className={screenWidth < 2000 ? "text-wrapper-27" : "text-wrapper-27"}>{t("learnAboutCenter")}</div>
                  <LinkTo
                    className="link-to-2"
                    icon={<Arrowupleft16 className={locale=="ar" ? "arrow-up-left-2" : "arrow-up-left-2 rotate-90"} />}
                    property1="default"
                  />

                </NavLink>
              </div>
            </div>
            <div className="relative top-[-10%] left-[5%] w-[50%] lg:static lg:w-[100%]">
              <img
                className="image-2"

                alt="Image"
                src={postOne?.Posts[0]?.attachment[0]?.original}
              />
            </div>

          </div>
        </div>
        <div className={screenWidth < 2000 ? "our-partners" : "our-partners"}>
          <div className={screenWidth < 2000 ? "text-wrapper-28" : "text-wrapper-28"}
            style={{
              color: "var(--greygrey-900)",
              fontFamily:
                screenWidth < 500
                  ? "var(--arabic-heading-h5-font-family)"
                  : screenWidth >= 500 && screenWidth < 834
                    ? "var(--arabic-heading-h4-font-family)"
                    : screenWidth >= 834 && screenWidth < 1550
                      ? "var(--arabic-heading-h3-font-family)"
                      : "var(--arabic-heading-h3-font-family)",
              fontSize:
                screenWidth < 500
                  ? "var(--arabic-heading-h5-font-size)"
                  : screenWidth >= 500 && screenWidth < 834
                    ? "var(--arabic-heading-h4-font-size)"
                    : screenWidth >= 834 && screenWidth < 1550
                      ? "var(--arabic-heading-h3-font-size)"
                      : "var(--arabic-heading-h3-font-size)",
              fontStyle:
                screenWidth < 500
                  ? "var(--arabic-heading-h5-font-style)"
                  : screenWidth >= 500 && screenWidth < 834
                    ? "var(--arabic-heading-h4-font-style)"
                    : screenWidth >= 834 && screenWidth < 1550
                      ? "var(--arabic-heading-h3-font-style)"
                      : "var(--arabic-heading-h3-font-style)",
              fontWeight:
                screenWidth < 500
                  ? "var(--arabic-heading-h5-font-weight)"
                  : screenWidth >= 500 && screenWidth < 834
                    ? "var(--arabic-heading-h4-font-weight)"
                    : screenWidth >= 834 && screenWidth < 1550
                      ? "var(--arabic-heading-h3-font-weight)"
                      : "var(--arabic-heading-h3-font-weight)",
              letterSpacing:
                screenWidth < 500
                  ? "var(--arabic-heading-h4-letter-spacing)"
                  : screenWidth >= 500 && screenWidth < 834
                    ? "var(--arabic-heading-h4-letter-spacing)"
                    : screenWidth >= 834 && screenWidth < 1550
                      ? "var(--arabic-heading-h3-letter-spacing)"
                      : "var(--arabic-heading-h3-letter-spacing)",
              lineHeight:
                screenWidth < 834
                  ? "var(--arabic-heading-h4-line-height)"
                  : screenWidth >= 834 && screenWidth < 1550
                    ? "var(--arabic-heading-h3-line-height)"
                    : "var(--arabic-heading-h3-line-height)",
            }}

          >{t("ourPartners")}</div>
          <div className="frame-26">
            <div className="w-full flex flex-row-reverse justify-center items-center h-[30vh] gap-[5rem] md:gap-[2rem]">
              {partners?.data?.map((partner) => {
                return <div className="relative">
                  {/* <Group  property={partner?.attachment[0]?.original} property1="variant-2" /> */}
                  <img
                    alt="Property default"
                    src={partner?.attachment[0]?.original}
                  />
                </div>

              })}
            </div>
          </div>
        </div>
        {screenWidth > 1100 ?
          <>
            <div className="incubator">
              <div className="frame-65">
                <div className={screenWidth < 2000 ? "text-wrapper-62" : "text-wrapper-62"}>
                  {postTwo?.title[`${locale}`]}
                </div>
                <div className="frame-29">
                  <p className={screenWidth < 2000 ? "text-wrapper-71" : "text-wrapper-71"}>
                    {postTwo?.Posts[0]?.description[`${locale}`]}
                  </p>
                </div>
              </div>
              <div className="frame-66">
                <div className="frame-67">
                  {/* <NavLink to={`${locale}/incub`tor"}>
                  <ButtonHover
                    className="instance-node-11"
                    icon={<FormatStrokeWeightLight2 className="instance-node-12" color="#2A499E" />}
                    property1="default"
                    text="قدّم الآن"
                  />
                  </NavLink> */}
                  <div className={screenWidth < 2000 ? "text-wrapper-72" : "text-wrapper-72"}>
                    {postThree?.title[`${locale}`]}
                  </div>
                </div>
                <div className="element-3 flex-row-reverse">
                  <SlideHover
                    className="slide-hover-instance"
                    divClassName={screenWidth < 2000 ? "slide-hover-11" : "slide-hover-11"}
                    property1="default"
                    content={postThree?.Posts[4]?.description[`${locale}`]}
                    text={postThree?.Posts[4]?.title[`${locale}`]}
                    background={postThree?.Posts[4]?.attachment[0]?.original}

                  />
                  <SlideHover
                    className="slide-hover-instance"
                    divClassName={screenWidth < 2000 ? "slide-hover-11" : "slide-hover-11"}
                    property1="default"
                    background={postThree?.Posts[3]?.attachment[0]?.original}

                    content={postThree?.Posts[3]?.description[`${locale}`]}
                    text={postThree?.Posts[3]?.title[`${locale}`]}
                  />
                  <SlideHover
                    className="slide-hover-instance"
                    divClassName={screenWidth < 2000 ? "slide-hover-11" : "slide-hover-11"} property1="default"
                    background={postThree?.Posts[2]?.attachment[0]?.original}

                    content={postThree?.Posts[2]?.description[`${locale}`]}
                    text={postThree?.Posts[2]?.title[`${locale}`]} />
                  <SlideHover
                    className="slide-hover-instance"
                    divClassName={screenWidth < 2000 ? "slide-hover-11" : "slide-hover-11"} property1="default"
                    background={postThree?.Posts[1]?.attachment[0]?.original}

                    content={postThree?.Posts[1]?.description[`${locale}`]}
                    text={postThree?.Posts[1]?.title[`${locale}`]} />
                  <SlideHover
                    className="slide-hover-instance"
                    divClassName={screenWidth < 2000 ? "slide-hover-11" : "slide-hover-11"} property1="default"
                    background={postThree?.Posts[0]?.attachment[0]?.original}
                    content={postThree?.Posts[0]?.description[`${locale}`]}
                    text={postThree?.Posts[0]?.title[`${locale}`]}
                  />
                </div>
              </div>
            </div>
          </> : <>

            <div className="frame-27">
              <div className="frame-28">
                <div className="text-wrapper-28"
                  style={{
                    fontFamily:
                      screenWidth < 500
                        ? "var(--arabic-heading-h5-font-family)"
                        : screenWidth >= 500 && screenWidth < 834
                          ? "var(--arabic-heading-h4-font-family)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-font-family)"
                            : "var(--arabic-heading-h3-font-family)",
                    fontSize:
                      screenWidth < 500
                        ? "var(--arabic-heading-h5-font-size)"
                        : screenWidth >= 500 && screenWidth < 834
                          ? "var(--arabic-heading-h4-font-size)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-font-size)"
                            : "var(--arabic-heading-h3-font-size)",
                    fontStyle:
                      screenWidth < 500
                        ? "var(--arabic-heading-h5-font-style)"
                        : screenWidth >= 500 && screenWidth < 834
                          ? "var(--arabic-heading-h4-font-style)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-font-style)"
                            : "var(--arabic-heading-h3-font-style)",
                    fontWeight:
                      screenWidth < 500
                        ? "var(--arabic-heading-h5-font-weight)"
                        : screenWidth >= 500 && screenWidth < 834
                          ? "var(--arabic-heading-h4-font-weight)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-font-weight)"
                            : "var(--arabic-heading-h3-font-weight)",
                    letterSpacing:
                      screenWidth < 500
                        ? "var(--arabic-heading-h4-letter-spacing)"
                        : screenWidth >= 500 && screenWidth < 834
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-letter-spacing)"
                            : "var(--arabic-heading-h3-letter-spacing)",
                    lineHeight:
                      screenWidth < 834
                        ? "var(--arabic-heading-h4-line-height)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h3-line-height)"
                          : "var(--arabic-heading-h3-line-height)",
                  }}
                >

                  {postTwo?.title[`${locale}`]}


                </div>
                <div className="frame-29">
                  <p className="text-wrapper-29"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-small-medium-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-small-medium-font-family)"
                              : "var(--arabic-text-small-medium-font-family)",
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-size)"
                              : "var(--arabic-text-large-medium-font-size)",
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-style)"
                              : "var(--arabic-text-large-medium-font-style)",
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-weight)"
                              : "var(--arabic-text-large-medium-font-weight)",
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-letter-spacing)"
                              : "var(--arabic-text-large-medium-letter-spacing)",
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-text-small-medium-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-text-small-medium-line-height)"
                            : "var(--arabic-text-small-medium-line-height)",
                    }}
                  >
                    {postTwo?.Posts[0]?.description[`${locale}`]}
                  </p>
                </div>
              </div>
              <div className="element">
                <div className="slide-hover-2" style={{ backgroundImage: `url(${postThree?.Posts[0]?.attachment[0]?.original})` }}>
                  <div className="text-wrapper-30"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-family)"
                              : "var(--arabic-heading-h3-font-family)",
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-size)"
                              : "var(--arabic-heading-h3-font-size)",
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-style)"
                              : "var(--arabic-heading-h3-font-style)",
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-weight)"
                              : "var(--arabic-heading-h3-font-weight)",
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-letter-spacing)"
                              : "var(--arabic-heading-h3-letter-spacing)",
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-heading-h4-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-line-height)"
                            : "var(--arabic-heading-h3-line-height)",
                    }}
                  >

                    {postThree?.Posts[0]?.title[`${locale}`]}
                  </div>
                  <p className="text-wrapper-29"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-small-medium-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-small-medium-font-family)"
                              : "var(--arabic-text-small-medium-font-family)",
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-size)"
                              : "var(--arabic-text-large-medium-font-size)",
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-style)"
                              : "var(--arabic-text-large-medium-font-style)",
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-weight)"
                              : "var(--arabic-text-large-medium-font-weight)",
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-letter-spacing)"
                              : "var(--arabic-text-large-medium-letter-spacing)",
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-text-small-medium-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-text-small-medium-line-height)"
                            : "var(--arabic-text-small-medium-line-height)",
                      color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", padding: "10px"
                    }}
                  >
                    {postThree?.Posts[0]?.description[`${locale}`]}

                  </p>
                </div>
                <div className="slide-hover-3" style={{ backgroundImage: `url(${postThree?.Posts[1]?.attachment[0]?.original})` }}>
                  <div className="text-wrapper-30"

                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-family)"
                              : "var(--arabic-heading-h3-font-family)",
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-size)"
                              : "var(--arabic-heading-h3-font-size)",
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-style)"
                              : "var(--arabic-heading-h3-font-style)",
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-weight)"
                              : "var(--arabic-heading-h3-font-weight)",
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-letter-spacing)"
                              : "var(--arabic-heading-h3-letter-spacing)",
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-heading-h4-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-line-height)"
                            : "var(--arabic-heading-h3-line-height)",
                    }}
                  >
                    {postThree?.Posts[1]?.title[`${locale}`]}
                  </div>
                  <p className="text-wrapper-29"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-small-medium-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-small-medium-font-family)"
                              : "var(--arabic-text-small-medium-font-family)",
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-size)"
                              : "var(--arabic-text-large-medium-font-size)",
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-style)"
                              : "var(--arabic-text-large-medium-font-style)",
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-weight)"
                              : "var(--arabic-text-large-medium-font-weight)",
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-letter-spacing)"
                              : "var(--arabic-text-large-medium-letter-spacing)",
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-text-small-medium-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-text-small-medium-line-height)"
                            : "var(--arabic-text-small-medium-line-height)",
                      color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", padding: "10px"
                    }}                    >
                    {postThree?.Posts[1]?.description[`${locale}`]}
                  </p>
                </div>
                <div className="slide-hover-4" style={{ backgroundImage: `url(${postThree?.Posts[2]?.attachment[0]?.original})` }}>
                  <div className="text-wrapper-30"

                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-family)"
                              : "var(--arabic-heading-h3-font-family)",
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-size)"
                              : "var(--arabic-heading-h3-font-size)",
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-style)"
                              : "var(--arabic-heading-h3-font-style)",
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-weight)"
                              : "var(--arabic-heading-h3-font-weight)",
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-letter-spacing)"
                              : "var(--arabic-heading-h3-letter-spacing)",
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-heading-h4-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-line-height)"
                            : "var(--arabic-heading-h3-line-height)",
                    }}
                  >
                    {postThree?.Posts[2]?.title[`${locale}`]}
                  </div>
                  <p className="text-wrapper-29"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-small-medium-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-small-medium-font-family)"
                              : "var(--arabic-text-small-medium-font-family)",
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-size)"
                              : "var(--arabic-text-large-medium-font-size)",
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-style)"
                              : "var(--arabic-text-large-medium-font-style)",
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-weight)"
                              : "var(--arabic-text-large-medium-font-weight)",
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-letter-spacing)"
                              : "var(--arabic-text-large-medium-letter-spacing)",
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-text-small-medium-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-text-small-medium-line-height)"
                            : "var(--arabic-text-small-medium-line-height)",
                      color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", padding: "10px"
                    }}
                  >
                    {postThree?.Posts[2]?.description[`${locale}`]}
                  </p>
                </div>
                <div className="slide-hover-5" style={{ backgroundImage: `url(${postThree?.Posts[3]?.attachment[0]?.original})` }}>
                  <div className="text-wrapper-30"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-family)"
                              : "var(--arabic-heading-h3-font-family)",
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-size)"
                              : "var(--arabic-heading-h3-font-size)",
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-style)"
                              : "var(--arabic-heading-h3-font-style)",
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-weight)"
                              : "var(--arabic-heading-h3-font-weight)",
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-letter-spacing)"
                              : "var(--arabic-heading-h3-letter-spacing)",
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-heading-h4-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-line-height)"
                            : "var(--arabic-heading-h3-line-height)",
                    }}
                  >
                    {postThree?.Posts[3]?.title[`${locale}`]}

                  </div>
                  <p className="text-wrapper-29"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-small-medium-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-small-medium-font-family)"
                              : "var(--arabic-text-small-medium-font-family)",
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-size)"
                              : "var(--arabic-text-large-medium-font-size)",
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-style)"
                              : "var(--arabic-text-large-medium-font-style)",
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-weight)"
                              : "var(--arabic-text-large-medium-font-weight)",
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-letter-spacing)"
                              : "var(--arabic-text-large-medium-letter-spacing)",
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-text-small-medium-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-text-small-medium-line-height)"
                            : "var(--arabic-text-small-medium-line-height)",
                      color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", padding: "10px"
                    }}
                  >
                    {postThree?.Posts[3]?.description[`${locale}`]}

                  </p>
                </div>
                <div className="slide-hover-6" style={{ backgroundImage: `url(${postThree?.Posts[4]?.attachment[0]?.original})` }}>
                  <div className="text-wrapper-30"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-family)"
                              : "var(--arabic-heading-h3-font-family)",
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-size)"
                              : "var(--arabic-heading-h3-font-size)",
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-style)"
                              : "var(--arabic-heading-h3-font-style)",
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-weight)"
                              : "var(--arabic-heading-h3-font-weight)",
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-letter-spacing)"
                              : "var(--arabic-heading-h3-letter-spacing)",
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-heading-h4-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-line-height)"
                            : "var(--arabic-heading-h3-line-height)",
                    }}
                  >

                    {postThree?.Posts[4]?.title[`${locale}`]}


                  </div>
                  <p className="text-wrapper-29"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-small-medium-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-small-medium-font-family)"
                              : "var(--arabic-text-small-medium-font-family)",
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-size)"
                              : "var(--arabic-text-large-medium-font-size)",
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-style)"
                              : "var(--arabic-text-large-medium-font-style)",
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-weight)"
                              : "var(--arabic-text-large-medium-font-weight)",
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-letter-spacing)"
                              : "var(--arabic-text-large-medium-letter-spacing)",
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-text-small-medium-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-text-small-medium-line-height)"
                            : "var(--arabic-text-small-medium-line-height)",
                      color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", padding: "10px"
                    }}                    >
                    {postThree?.Posts[4]?.description[`${locale}`]}
                  </p>
                </div>
              </div>
            </div>

          </>}


        <div className="news">
          <div className="frame-30">
            <div className="frame-31">
              <div className="text-wrapper-35"
                style={{
                  fontFamily:
                    screenWidth < 500
                      ? "var(--arabic-heading-h5-font-family)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h4-font-family)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h3-font-family)"
                          : "var(--arabic-heading-h3-font-family)",
                  fontSize:
                    screenWidth < 500
                      ? "var(--arabic-heading-h5-font-size)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h4-font-size)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h3-font-size)"
                          : "var(--arabic-heading-h3-font-size)",
                  fontStyle:
                    screenWidth < 500
                      ? "var(--arabic-heading-h5-font-style)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h4-font-style)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h3-font-style)"
                          : "var(--arabic-heading-h3-font-style)",
                  fontWeight:
                    screenWidth < 500
                      ? "var(--arabic-heading-h5-font-weight)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h4-font-weight)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h3-font-weight)"
                          : "var(--arabic-heading-h3-font-weight)",
                  letterSpacing:
                    screenWidth < 500
                      ? "var(--arabic-heading-h4-letter-spacing)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h4-letter-spacing)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h3-letter-spacing)"
                          : "var(--arabic-heading-h3-letter-spacing)",
                  lineHeight:
                    screenWidth < 834
                      ? "var(--arabic-heading-h4-line-height)"
                      : screenWidth >= 834 && screenWidth < 1550
                        ? "var(--arabic-heading-h3-line-height)"
                        : "var(--arabic-heading-h3-line-height)",
                }}
              >{t("waterInnovationNetwork")}</div>
              <p className={screenWidth < 2000 ? "text-wrapper-25" : "text-wrapper-25"}

              >{t("latestNewsReportsWorkshopsEvents")}</p>
            </div>
            <Link to={"/news?type=news&&serch="} className="frame-21">
              <div className={screenWidth < 2000 ? "text-wrapper-27" : "text-wrapper-27"}>{t("showMore")}</div>
              <LinkTo
                className="link-to-2"
                icon={<Arrowupleft16 className={locale=="ar" ? "arrow-up-left-2" : "arrow-up-left-2 rotate-90"} />}
                property1="default"
              />
            </Link>

          </div>


          <div className="frame-32">
            { }
            <div className="frame-33">

              <img className="rectangle-5" alt="Rectangle" src={report?.data ? report?.data[0]?.attachment[0]?.original : "/img/1.jpg"} />
              <div className="frame-34 z-20">
                <div className="frame-35">
                  <div className={screenWidth < 2000 ? "text-wrapper-36" : "text-wrapper-36"}>الخميس 29 أبريل, 2024</div>
                  <Clockafternoon8 className="clockafternoon-8" />
                </div>
                <div className="frame-36">
                  <p className="text-wrapper-37"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-family)"
                              : "var(--arabic-heading-h3-font-family)",
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-size)"
                              : "var(--arabic-heading-h3-font-size)",
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-style)"
                              : "var(--arabic-heading-h3-font-style)",
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-weight)"
                              : "var(--arabic-heading-h3-font-weight)",
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-letter-spacing)"
                              : "var(--arabic-heading-h3-letter-spacing)",
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-heading-h4-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-line-height)"
                            : "var(--arabic-heading-h3-line-height)",
                    }}
                  >
                    {report?.data ? report?.data[0]?.name[`${locale}`] : ""}                                 </p>
                  <p className={screenWidth < 2000 ? "text-wrapper-38" : "text-wrapper-38"}>
                    {report?.data ? report?.data[0]?.description[`${locale}`] : ""}    </p>
                </div>
              </div>
              {report?.data ?

                <div className="frame-37">
                  <div className={screenWidth < 2000 ? "text-wrapper-39" : "text-wrapper-39"}>{report?.data[0]?.type == "news" ? "أخبار" : report?.data[0]?.type == "event" ? "فعاليات" : "تقارير"}</div>
                </div> : ""
              }
            </div>
            <div className="grid grid-cols-4 gap-4 md:grid-cols-2 sm:grid-cols-1 w-full">
              {report?.data?.map((rep, index) => {
                return index <= 3 ? <Link to={`${locale}/newsdetails?id=${rep.id}`} className="news-card-2" key={rep.id + "reportsHome"}>
                  <img className={screenWidth < 2000 ? "rectangle-6" : "rectangle-6"} alt="Rectangle" src={rep.attachment[0].original} />
                  <div className="frame-39">
                    <div className="frame-23">
                      <div className="frame-40">
                        <div className="frame-41">
                          <div className={screenWidth < 2000 ? "text-wrapper-36" : "text-wrapper-36"}>الخميس 29 أبريل, 2024</div>
                          <FormatStrokeWeightThin3 className="format-stroke-weight-thin-3" color="#2A499E" />
                        </div>
                        <p className={screenWidth < 2000 ? "text-wrapper-40" : "text-wrapper-40"}>
                          {rep.name[`${locale}`].substr(1, 100)}   ...                       </p>
                      </div>
                      <LinkTo
                        className="link-to-2"
                        icon={<Arrowupleft21 className={locale=="ar" ? "arrow-up-left-2" : "arrow-up-left-2 rotate-90"} />}
                        property1="default"
                      />
                    </div>
                  </div>
                  <div className="frame-42">
                    <div className={screenWidth < 2000 ? "text-wrapper-41" : "text-wrapper-41"}>{rep.type == "news" ? "أخبار" : rep.type == "event" ? "فعاليات" : "تقارير"}</div>
                  </div>
                </Link> : ""
              })}

              {/* <div className="news-card-2">
                    <img className={screenWidth<2000?"rectangle-6":"rectangle-6more"} alt="Rectangle" src="/img/2.png" />
                    <div className="frame-39">
                      <div className="frame-23">
                        <div className="frame-40">
                          <div className="frame-41">
                            <div className={screenWidth<2000?"text-wrapper-36":"text-wrapper-36more"}>الخميس 29 أبريل, 2024</div>
                            <FormatStrokeWeightThin3 className="format-stroke-weight-thin-3" color="#2A499E" />
                          </div>
                          <p className={screenWidth<2000?"text-wrapper-40":"text-wrapper-40more"}>
                          سمو ولي العهد يعلن عن التطلعات الوطنية لقطاع البحث والتطوير والابتكار     
                                               </p>
                        </div>
                        <LinkTo
                          className="link-to-2"
                          icon={<Arrowupleft16 className={screenWidth<2000?"arrow-up-left-2":"arrow-up-left-2"} />}
                          property1="default"
                        />
                      </div>
                    </div>
                    <div className="frame-42">
                      <div className={screenWidth<2000?"text-wrapper-41":"text-wrapper-41more"}>أخبار</div>
                    </div>
                  </div>
                  <div className="news-card-2">
                    <img className={screenWidth<2000?"rectangle-6":"rectangle-6more"} alt="Rectangle" src="/img/1.jpg" />
                    <div className="frame-39">
                      <div className="frame-23">
                        <div className="frame-40">
                          <div className="frame-41">
                            <div className={screenWidth<2000?"text-wrapper-36":"text-wrapper-36more"}>الخميس 29 أبريل, 2024</div>
                            <FormatStrokeWeightThin3 className="format-stroke-weight-thin-3" color="#2A499E" />
                          </div>
                          <p className={screenWidth<2000?"text-wrapper-40":"text-wrapper-40more"}>
                          المؤسسة العامة لتحلية المياه المالحة تطلق تقريرها الأول للاستدامة بعنوان نحلي المياه لمستقبل أخضر                          </p>
                        </div>
                        <LinkTo
                          className="link-to-2"
                          icon={<Arrowupleft21 className={screenWidth<2000?"arrow-up-left-2":"arrow-up-left-2"} />}
                          property1="default"
                        />
                      </div>
                    </div>
                    <div className="frame-42">
                      <div className={screenWidth<2000?"text-wrapper-41":"text-wrapper-41more"}>أخبار</div>
                    </div>
                  </div>
                  <div className="news-card-3">
                    <img className={screenWidth<2000?"rectangle-6":"rectangle-6more"} alt="Rectangle" src="/img/rectangle-6-3.png" />
                    <div className="frame-39">
                      <div className="frame-23">
                        <div className="frame-40">
                          <div className="frame-41">
                            <div className={screenWidth<2000?"text-wrapper-36":"text-wrapper-36more"}>الخميس 29 أبريل, 2024</div>
                            <FormatStrokeWeightThin3 className="format-stroke-weight-thin-3" color="#2A499E" />
                          </div>
                          <p className={screenWidth<2000?"text-wrapper-40":"text-wrapper-40more"}>
                            التحول الوطني يُكرم التحلية لتحقيقها رقماً عالمياً في الإنتاج وزيادة نوعية في الاداء
                          </p>
                        </div>
                        <LinkTo
                          className="link-to-2"
                          icon={<Arrowupleft16 className={screenWidth<2000?"arrow-up-left-2":"arrow-up-left-2"} />}
                          property1="default"
                        />
                      </div>
                    </div>
                    <div className="rectangle-7" />
                    <div className="rectangle-8" />
                    <div className="frame-42">
                      <div className={screenWidth<2000?"text-wrapper-41":"text-wrapper-41more"}>أخبار</div>
                    </div>
                  </div> */}
            </div>
          </div>
        </div>

        <div className="statistic-secion">
          <div className="div-2">
            <div className="frame-49">
              <div className="text-wrapper-28"
                style={{
                  color: "var(--greygrey-900)",
                  fontFamily:
                    screenWidth < 500
                      ? "var(--arabic-heading-h5-font-family)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h4-font-family)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h3-font-family)"
                          : "var(--arabic-heading-h3-font-family)",
                  fontSize:
                    screenWidth < 500
                      ? "var(--arabic-heading-h5-font-size)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h4-font-size)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h3-font-size)"
                          : "var(--arabic-heading-h3-font-size)",
                  fontStyle:
                    screenWidth < 500
                      ? "var(--arabic-heading-h5-font-style)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h4-font-style)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h3-font-style)"
                          : "var(--arabic-heading-h3-font-style)",
                  fontWeight:
                    screenWidth < 500
                      ? "var(--arabic-heading-h5-font-weight)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h4-font-weight)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h3-font-weight)"
                          : "var(--arabic-heading-h3-font-weight)",
                  letterSpacing:
                    screenWidth < 500
                      ? "var(--arabic-heading-h4-letter-spacing)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h4-letter-spacing)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h3-letter-spacing)"
                          : "var(--arabic-heading-h3-letter-spacing)",
                  lineHeight:
                    screenWidth < 834
                      ? "var(--arabic-heading-h4-line-height)"
                      : screenWidth >= 834 && screenWidth < 1550
                        ? "var(--arabic-heading-h3-line-height)"
                        : "var(--arabic-heading-h3-line-height)",
                }}
              >
                {postFour?.title[`${locale}`]}
              </div>
              <p className="text-wrapper-50"
                style={{
                  fontFamily:
                    screenWidth < 500
                      ? "var(--arabic-text-small-medium-font-family)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-family)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-text-small-medium-font-family)"
                          : "var(--arabic-text-small-medium-font-family)",
                  fontSize:
                    screenWidth < 500
                      ? "var(--arabic-text-small-medium-font-size)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-text-large-medium-font-size)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-text-large-medium-font-size)"
                          : "var(--arabic-text-large-medium-font-size)",
                  fontStyle:
                    screenWidth < 500
                      ? "var(--arabic-text-small-medium-font-style)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-text-large-medium-font-style)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-text-large-medium-font-style)"
                          : "var(--arabic-text-large-medium-font-style)",
                  fontWeight:
                    screenWidth < 500
                      ? "var(--arabic-text-small-medium-font-weight)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-text-large-medium-font-weight)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-text-large-medium-font-weight)"
                          : "var(--arabic-text-large-medium-font-weight)",
                  letterSpacing:
                    screenWidth < 500
                      ? "var(--arabic-text-small-medium-letter-spacing)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-text-large-medium-letter-spacing)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-text-large-medium-letter-spacing)"
                          : "var(--arabic-text-large-medium-letter-spacing)",
                  lineHeight:
                    screenWidth < 834
                      ? "var(--arabic-text-small-medium-line-height)"
                      : screenWidth >= 834 && screenWidth < 1550
                        ? "var(--arabic-text-small-medium-line-height)"
                        : "var(--arabic-text-small-medium-line-height)",
                }}
              >
                {postFour?.Posts[0]?.description[`${locale}`]}
              </p>
            </div>
            <div className={screenWidth < 2000 ? "grid grid-cols-4 gap-4 md:grid-cols-2" : "grid grid-cols-4 gap-4 md:grid-cols-2 w-full"}>
              <div className="vertical-border">
                <div className={screenWidth < 2000 ? "text-wrapper-51 num " : "text-wrapper-51"}>
                  <CountUp end={postFour?.Posts[1]?.title[`${locale}`]} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </div>
                <div className="heading-11">
                  <div className={screenWidth < 2000 ? "text-wrapper-54" : "text-wrapper-54"}>
                    {/* مشروع محتضن */}
                    {postFour?.Posts[1]?.description[`${locale}`].split(" ", 2).toString().replaceAll(",", " ")}
                  </div>
                </div>
                <div className="heading-10">
                  <p className={screenWidth < 2000 ? "text-wrapper-57" : "text-wrapper-57"}>
                    {postFour?.Posts[1]?.description[`${locale}`].split(" ").map((text, index) => {
                      return index > 1 ? text : ""
                    }).toString().replaceAll(",", " ")}
                  </p>
                </div>
              </div>
              <div className="vertical-border">
                <div className={screenWidth < 2000 ? "text-wrapper-51" : "text-wrapper-51"}>

                  <CountUp end={postFour?.Posts[2]?.title[`${locale}`]} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </div>
                <div className="heading-6">
                  <div className={screenWidth < 2000 ? "text-wrapper-54" : "text-wrapper-54"}>
                    {/* معسكر تدريبي */}
                    {postFour?.Posts[2]?.description[`${locale}`].split(" ", 2).toString().replaceAll(",", " ")}

                  </div>
                </div>
                <div className="heading-10">
                  <div className={screenWidth < 2000 ? "text-wrapper-57" : "text-wrapper-57"}>
                    {postFour?.Posts[2]?.description[`${locale}`].split(" ").map((text, index) => {
                      return index > 1 ? text : ""
                    }).toString().replaceAll(",", " ")}
                  </div>
                </div>
              </div>
              <div className="vertical-border">
                <div className={screenWidth < 2000 ? "text-wrapper-51" : "text-wrapper-51"}>
                  + <CountUp end={postFour?.Posts[3]?.title[`${locale}`]} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp></div>
                <div className="heading-8">
                  <div className={screenWidth < 2000 ? "text-wrapper-54" : "text-wrapper-54"}>
                    {postFour?.Posts[3]?.description[`${locale}`].split(" ", 2).toString().replaceAll(",", " ")}
                  </div>
                </div>
                <div className="heading-10">
                  <div className={screenWidth < 2000 ? "text-wrapper-57" : "text-wrapper-57"}>
                    {postFour?.Posts[3]?.description[`${locale}`].split(" ").map((text, index) => {
                      return index > 1 ? text : ""
                    }).toString().replaceAll(",", " ")}
                  </div>
                </div>
              </div>
              <div className="vertical-border">
                <div className={screenWidth < 2000 ? "text-wrapper-51" : "text-wrapper-51"}>+

                  <CountUp end={postFour?.Posts[4]?.title[`${locale}`]} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp></div>
                <div className="heading-6">
                  <div className={screenWidth < 2000 ? "text-wrapper-54" : "text-wrapper-54"}>
                    {postFour?.Posts[4]?.description[`${locale}`].split(" ", 2).toString().replaceAll(",", " ")}
                  </div>
                </div>
                <div className="heading-10">
                  <div className={screenWidth < 2000 ? "text-wrapper-57" : "text-wrapper-57"}>

                    {postFour?.Posts[4]?.description[`${locale}`].split(" ").map((text, index) => {
                      return index > 1 ? text : ""
                    }).toString().replaceAll(",", " ")}

                    {/* في الورش التدريبية متخصصة */}
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>

        <div className="view">
          <div className="frame-50 flex justify-center items-center  lg:flex-col">
            <p className="text-wrapper-60"
              style={{
                fontFamily:
                  screenWidth < 500
                    ? "var(--arabic-heading-h5-font-family)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h3-font-family)"
                      : screenWidth >= 834 && screenWidth < 1550
                        ? "var(--arabic-heading-h2-font-family)"
                        : "var(--arabic-heading-h2-font-family)",
                fontSize:
                  screenWidth < 500
                    ? "var(--arabic-heading-h5-font-size)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h3-font-size)"
                      : screenWidth >= 834 && screenWidth < 1550
                        ? "var(--arabic-heading-h2-font-size)"
                        : "var(--arabic-heading-h2-font-size)",
                fontStyle:
                  screenWidth < 500
                    ? "var(--arabic-heading-h5-font-style)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h3-font-style)"
                      : screenWidth >= 834 && screenWidth < 1550
                        ? "var(--arabic-heading-h2-font-style)"
                        : "var(--arabic-heading-h2-font-style)",
                fontWeight:
                  screenWidth < 500
                    ? "var(--arabic-heading-h5-font-weight)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h3-font-weight)"
                      : screenWidth >= 834 && screenWidth < 1550
                        ? "var(--arabic-heading-h2-font-weight)"
                        : "var(--arabic-heading-h2-font-weight)",
                letterSpacing:
                  screenWidth < 834
                    ? "var(--arabic-heading-h5-letter-spacing)"
                    : screenWidth >= 834 && screenWidth < 1550
                      ? "var(--arabic-heading-h2-letter-spacing)"
                      : "var(--arabic-heading-h2-letter-spacing)",
                lineHeight:
                  screenWidth < 834
                    ? "var(--arabic-heading-h5-line-height)"
                    : screenWidth >= 834 && screenWidth < 1550
                      ? "var(--arabic-heading-h2-line-height)"
                      : "var(--arabic-heading-h2-line-height)",
              }}

            >{t("saudiWaterInnovationCenterReport2023")}</p>
            <div className="frame-51">
              <img className={screenWidth < 2000 ? "logo-3" : "logo-3more"} alt="Logo" src="/img/logo-9.svg" />
              <div className="frame-52">
                <div className="frame-53">
                  <div className={screenWidth < 2000 ? "text-wrapper-61" : "text-wrapper-61"}>{t("downloadReport")}</div>
                  <Downloadsimple1 className="instance-node-12" color="#2A499E" />
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>



    </div>
    <div className={screenWidth < 2000 ? "mt-[100px] lg:mt-[300px] sm:mt-[80px]" : "mt-[700px]"}></div>
    <Footer className="footer-3" logo="/img/logo-9.png" property1="web" />

  </>);
};
