import React, { useEffect } from "react";
import { useWindowWidth } from "../../breakpoints";
import { ArrowUpLeft } from "../../components/ArrowUpLeft";
import { CaretDown } from "../../components/CaretDown";
import { Copyright } from "../../components/Copyright";
import { FacebookLogo } from "../../components/FacebookLogo";
import { Footer } from "../../components/Footer";
import { House } from "../../components/House";
import { InstagramLogo } from "../../components/InstagramLogo";
import { LinkedinLogo } from "../../components/LinkedinLogo";
import { List } from "../../components/List";
import { Navbar } from "../../components/Navbar";
import { Newsletter } from "../../components/Newsletter";
import { PaperPlaneTilt } from "../../components/PaperPlaneTilt";
import { TextHover } from "../../components/TextHover";
import { User } from "../../components/User";
import { XLogo } from "../../components/XLogo";
import { YoutubeLogo } from "../../components/YoutubeLogo";
import "./style.css";
import { Clockafternoon8 } from "../../icons/Clockafternoon8";
import { useReports } from "../../api/api";
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import ReactVisibilitySensor from "react-visibility-sensor";
import { Spring } from "react-spring/renderprops";
import { TimeLineAt } from "../TimeLineAt";
import { useTranslation } from "../../translations/TranslationContext";

export const TimeLine = () => {


  const screenWidth = useWindowWidth();
  const h2Styles = {
    fontSize: "82px"
    // color: "white",
    // backgroundColor: "black",
    // padding: "16px 32px"
  };

  const { t, changeLocale, locale } = useTranslation();

  return (<>

  {/* <div className="mt-[200vh]"></div> */}
<section className="timeline-section px-[5%]" id="Timeline">
    <div className="container">
        {screenWidth>1000?  
              <div className="timeline2 d-none d-md-block">
        <ReactVisibilitySensor>
          {({ isVisible }) => (
            <Spring
              delay={400}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateX(0)" : "translateX(200px)"
              }}
            >
              {(props) => <div style={{...props }} className="item top-[80px] left-[15%] ">
              <b style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>
{t("may142024")}
              </b>
              <br />
              <span style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}> {t("registrationStarts")}
              </span>
          </div>
              }
            </Spring>
          )}
        </ReactVisibilitySensor>
        <ReactVisibilitySensor>
          {({ isVisible }) => (
            <Spring
              delay={600}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateX(0)" : "translateX(200px)"
              }}
            >
              {(props) => 
            <div style={{...props }} className="item top-[80px] right-[15%] aos-init aos-animate" data-aos="fade-right" data-aos-delay="100" data-aos-anchor-placement="top-bottom">
            <b style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("may242024")}  
            </b>
            <br />
            <span style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("registrationCloses")}</span>
        </div>
              }
            </Spring>
          )}
        </ReactVisibilitySensor>
        <ReactVisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              delay={600}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateX(0)" : "translateX(200px)"
              }}
            >
              {(props) => 
            <div style={{...props }} className="item top-[390px] left-[40%] xl:top-[350px] lg:top-[310px]  aos-init aos-animate" data-aos="fade-left" data-aos-anchor-placement="top-bottom">
            <b style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("may242024ToJune102024")}</b>
            <br />
            <span style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("sortingAndAcceptanceProcess")}</span>
        </div>
              }
            </Spring>
          )}
        </ReactVisibilitySensor>
        <ReactVisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              delay={400}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateX(0)" : "translateX(200px)"
              }}
            >
              {(props) => 
            <div style={{...props }} className="item top-[700px] left-[15%] xl:top-[625px] lg:top-[530px] aos-init" data-aos="fade-right" data-aos-anchor-placement="top-bottom">
            <b style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("june242024")}</b>
            <br />
            <span style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("programStarts")}</span>
        </div>
              }
            </Spring>
          )}
        </ReactVisibilitySensor>
        <ReactVisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              delay={600}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateX(0)" : "translateX(200px)"
              }}
            >
              {(props) => 
            <div style={{...props }} className="item top-[700px] right-[15%] xl:top-[625px] lg:top-[530px] aos-init" data-aos="fade-right" data-aos-delay="100" data-aos-anchor-placement="top-bottom">
            <b style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("december202024")}</b>
            <br />
            <span style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("programConclusionAndGraduationCeremony")}</span>
        </div>
              }
            </Spring>
          )}
        </ReactVisibilitySensor>










        </div>:
        
        
<TimeLineAt/>
        
        }




            </div>
</section>
 </> );
};
