/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatStrokeWeightThin8 = ({ className }) => {
  return (
    <svg
      className={`format-stroke-weight-thin-8 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z"
        stroke="#343330"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="path"
        d="M22 4H10C6.68629 4 4 6.68629 4 10V22C4 25.3137 6.68629 28 10 28H22C25.3137 28 28 25.3137 28 22V10C28 6.68629 25.3137 4 22 4Z"
        stroke="#343330"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="path"
        d="M22.5 10.5C23.0523 10.5 23.5 10.0523 23.5 9.5C23.5 8.94772 23.0523 8.5 22.5 8.5C21.9477 8.5 21.5 8.94772 21.5 9.5C21.5 10.0523 21.9477 10.5 22.5 10.5Z"
        fill="#343330"
      />
    </svg>
  );
};
