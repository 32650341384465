/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { FormatOutlineWeightBold9 } from "../../icons/FormatOutlineWeightBold9";
import { FormatOutlineWeightDuotone9 } from "../../icons/FormatOutlineWeightDuotone9";
import { FormatOutlineWeightFill9 } from "../../icons/FormatOutlineWeightFill9";
import { FormatOutlineWeightLight9 } from "../../icons/FormatOutlineWeightLight9";
import { FormatOutlineWeightRegular9 } from "../../icons/FormatOutlineWeightRegular9";
import { FormatOutlineWeightThin9 } from "../../icons/FormatOutlineWeightThin9";
import { FormatStrokeWeightBold6 } from "../../icons/FormatStrokeWeightBold6";
import { FormatStrokeWeightDuotone6 } from "../../icons/FormatStrokeWeightDuotone6";
import { FormatStrokeWeightLight6 } from "../../icons/FormatStrokeWeightLight6";
import { FormatStrokeWeightRegular6 } from "../../icons/FormatStrokeWeightRegular6";
import { FormatStrokeWeightThin6 } from "../../icons/FormatStrokeWeightThin6";
import "./style.css";

export const YoutubeLogo = ({ format, weight }) => {
  return (
    <>
      {weight === "regular" && format === "outline" && <FormatOutlineWeightRegular9 className="instance-node-4" />}

      {format === "outline" && weight === "thin" && <FormatOutlineWeightThin9 className="instance-node-4" />}

      {weight === "light" && format === "outline" && <FormatOutlineWeightLight9 className="instance-node-4" />}

      {format === "outline" && weight === "bold" && <FormatOutlineWeightBold9 className="instance-node-4" />}

      {weight === "fill" && <FormatOutlineWeightFill9 className="instance-node-4" />}

      {format === "outline" && weight === "duotone" && <FormatOutlineWeightDuotone9 className="instance-node-4" />}

      {weight === "regular" && format === "stroke" && <FormatStrokeWeightRegular6 className="instance-node-4" />}

      {weight === "thin" && format === "stroke" && <FormatStrokeWeightThin6 className="instance-node-4" />}

      {weight === "light" && format === "stroke" && <FormatStrokeWeightLight6 className="instance-node-4" />}

      {weight === "bold" && format === "stroke" && <FormatStrokeWeightBold6 className="instance-node-4" />}

      {weight === "duotone" && format === "stroke" && <FormatStrokeWeightDuotone6 className="instance-node-4" />}
    </>
  );
};

YoutubeLogo.propTypes = {
  format: PropTypes.oneOf(["outline", "stroke"]),
  weight: PropTypes.oneOf(["duotone", "regular", "thin", "bold", "fill", "light"]),
};
