/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ArrowUpLeft = ({
  format,
  weight,
  className,
  formatOutline = "/img/format-outline-weight-regular-2.png",
}) => {
  return (
    <img
      className={`arrow-up-left ${className}`}
      alt="Format outline"
      src={
        weight === "regular"
          ? formatOutline
          : weight === "thin"
          ? "/img/format-outline-weight-thin-2.png"
          : weight === "light"
          ? "/img/format-outline-weight-light-2.png"
          : weight === "fill"
          ? "/img/format-outline-weight-fill-2.png"
          : weight === "duotone"
          ? "/img/format-outline-weight-duotone-2.png"
          : weight === "bold" && format === "stroke"
          ? "/img/format-stroke-weight-bold-3.png"
          : "/img/format-outline-weight-bold-2.png"
      }
    />
  );
};

ArrowUpLeft.propTypes = {
  format: PropTypes.oneOf(["outline", "stroke"]),
  weight: PropTypes.oneOf(["duotone", "regular", "thin", "bold", "fill", "light"]),
  formatOutline: PropTypes.string,
};
