/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Paperplanetilt3 = ({ className }) => {
  return (
    <svg
      className={`paperplanetilt-3 ${className}`}
      fill="none"
      height="25"
      viewBox="0 0 25 25"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M21.935 4.59175C21.935 4.59175 21.935 4.60113 21.935 4.60581L16.4788 22.6002C16.3962 22.8925 16.2262 23.1525 15.9916 23.3454C15.757 23.5382 15.469 23.6547 15.1663 23.6793C15.1231 23.683 15.08 23.6849 15.0369 23.6849C14.7532 23.6858 14.4752 23.6055 14.2356 23.4535C13.9961 23.3015 13.805 23.0841 13.685 22.8271L10.2725 15.8239C10.2384 15.7538 10.227 15.6747 10.2399 15.5977C10.2528 15.5207 10.2895 15.4497 10.3447 15.3946L15.7747 9.96456C15.9094 9.82277 15.9834 9.63395 15.9809 9.43838C15.9784 9.24281 15.8996 9.05596 15.7613 8.91765C15.623 8.77935 15.4361 8.70055 15.2406 8.69805C15.045 8.69554 14.8562 8.76954 14.7144 8.90425L9.28157 14.3343C9.22641 14.3895 9.1554 14.4261 9.07843 14.439C9.00146 14.452 8.92238 14.4406 8.85219 14.4064L1.84251 10.9949C1.5684 10.8634 1.34072 10.6517 1.18965 10.3879C1.03857 10.124 0.971228 9.82053 0.996543 9.51756C1.02186 9.2146 1.13863 8.92648 1.3314 8.69138C1.52416 8.45629 1.78381 8.28532 2.07594 8.20113L20.0703 2.74488H20.0844C20.3406 2.6729 20.6113 2.67037 20.8688 2.73756C21.1263 2.80476 21.3613 2.93924 21.5497 3.12723C21.738 3.31522 21.873 3.54993 21.9407 3.80729C22.0084 4.06466 22.0065 4.3354 21.935 4.59175Z"
        fill="white"
      />
    </svg>
  );
};
