const messages ={
  homepage: "Home",
  aboutCenter: "About the Center",
  waterTechNetwork: "Water Technologies Network",
  incubator: "Incubator",
  partners: "Partners",
  saudiWater: "Saudi Water ",
  InnovationCenter: "Innovation Center",
  showMore: "Show More",
  towardsSustainableFuture: "Towards a Sustainable Future",
  industrialCapacityDevelopment: "Industrial Capacity Development for Small and Medium Enterprises",
  learnAboutCenter: "Learn About the Center",
  ourPartners: "Our Partners",
  waterInnovationNetwork: "Water Innovation Network",
  latestNewsReportsWorkshopsEvents: "Latest News, Reports, Workshops, and Events",
  saudiWaterInnovationCenterReport2023: "Saudi Water Innovation Center Report 2023",
  downloadReport: "Download Report",
  newsletter: "Newsletter",
  connectWithUsOnSocialMedia: "Connect with Us on Social Media",
  waterInnovationNetwork: "Water Innovation Network",
  events: "Events",
  news: "News",
  incubator: "Incubator",
  waterIncubator: "Water Incubator",
  stages: "Stages",
  registrationRequirements: "Registration Requirements",
  currentIncubatees: "Current Incubatees",
  aboutCenter: "About the Center",
  aboutCenter: "About the Center",
  centerObjectives: "Center Objectives",
  visionMission: "Vision & Mission",
  homepage: "Home",
  centerTeam: "Center Team",
  news: "News",
  events: "Events",
  reports: "Reports",
  search: "Search",
  searchWord: "Search Word",
  connectWithUsOnSocialMedia: "Connect with Us on Social Media",
  allRightsReserved: "All Rights Reserved for Saudi Water Innovation Center 2022",
  privacyPolicy: "Privacy Policy",
  termsOfUse: "Terms of Use",
  incubatorTimeline: "Incubator Program Timeline",
  startupPathDescription: "The path of startups during the incubation program is summarized in these stages",
  may142024: "May 14, 2024",
  registrationStarts: "Registration Starts",
  may242024: "May 24, 2024",
  registrationCloses: "Registration Closes",
  may242024ToJune102024: "May 24, 2024 to June 10, 2024",
  sortingAndAcceptanceProcess: "Sorting and Acceptance Process",
  june242024: "June 24, 2024",
  programStarts: "Program Starts",
  december202024: "December 20, 2024",
  programConclusionAndGraduationCeremony: "Program Conclusion and Graduation Ceremony",
  currentIncubatees: "Current Incubatees",
  saudiWaterInnovationCenter: "Saudi Water Innovation Center",
  newUserRegistration: "New User Registration",
    userType: "User Type",
    personalInformation: "Personal Information",
    governmentalEntities: "Governmental Entities",
    businessSector: "Business Sector",
    individuals: "Individuals",
    accountInformation: "Account Information",
    nationalIDNumber: "National ID Number ",
    idType: "ID Type ",
    select: "Select",
    gender: "Gender ",
    nationality: "Nationality ",
    male: "Male",
    female: "Female",
    fatherName: "Father's Name ",
    firstName: "First Name ",
    lastName: "Last Name ",
    grandfatherName: "Grandfather's Name",
    mobileNumber: "Mobile Number ",
    confirm: "Confirm",
    username: "Username",
    email: "Email",
    password: "Password",
    confirmPassword: "Confirm Password",
    organizationName: "Organization Name",
    "commercialRegister":"Commercial Register",
    footer1:"Interested in learning about the latest developments about the Saudi Innovation Center for Water Technologies?",
    footer2:"Subscribe now to the newsletter to receive all the latest news!",
    "companyInformation": "Company Information",
    "commissionerInformation": "Commissioner Information",
    "accountInformation": "Account Information",
    "select": "Select",
    "citizenID": "Citizen ID",
    "residentID": "Resident ID",
    "passport": "Passport",
      "male": "Male",
  "female": "Female",

};
  
  export default messages;