/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const PaperPlaneTilt = ({
  format,
  weight,
  className,
  formatOutline = "/img/format-outline-weight-fill-4.png",
}) => {
  return (
    <img
      className={`paper-plane-tilt ${className}`}
      alt="Format outline"
      src={
        format === "outline" && weight === "thin"
          ? "/img/format-outline-weight-thin-4.png"
          : weight === "light" && format === "outline"
          ? "/img/format-outline-weight-light-4.png"
          : format === "outline" && weight === "bold"
          ? "/img/format-outline-weight-bold-4.png"
          : weight === "fill"
          ? formatOutline
          : format === "outline" && weight === "duotone"
          ? "/img/format-outline-weight-duotone-4.png"
          : weight === "regular" && format === "stroke"
          ? "/img/format-stroke-weight-regular-4.png"
          : weight === "thin" && format === "stroke"
          ? "/img/format-stroke-weight-thin-4.png"
          : weight === "light" && format === "stroke"
          ? "/img/format-stroke-weight-light-4.png"
          : weight === "bold" && format === "stroke"
          ? "/img/format-stroke-weight-bold-4.png"
          : weight === "duotone" && format === "stroke"
          ? "/img/format-stroke-weight-duotone-4.png"
          : "/img/format-outline-weight-regular-4.png"
      }
    />
  );
};

PaperPlaneTilt.propTypes = {
  format: PropTypes.oneOf(["outline", "stroke"]),
  weight: PropTypes.oneOf(["duotone", "regular", "thin", "bold", "fill", "light"]),
  formatOutline: PropTypes.string,
};
