/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatOutlineWeightBold = ({ className }) => {
  return (
    <svg
      className={`format-outline-weight-bold ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M27.0613 13.0615L17.0613 23.0615C16.9219 23.2013 16.7563 23.3123 16.574 23.388C16.3917 23.4637 16.1962 23.5027 15.9988 23.5027C15.8013 23.5027 15.6059 23.4637 15.4235 23.388C15.2412 23.3123 15.0756 23.2013 14.9363 23.0615L4.93626 13.0615C4.65446 12.7797 4.49615 12.3975 4.49615 11.999C4.49615 11.6005 4.65446 11.2183 4.93626 10.9365C5.21805 10.6547 5.60024 10.4964 5.99876 10.4964C6.39727 10.4964 6.77946 10.6547 7.06126 10.9365L16 19.8752L24.9388 10.9352C25.2205 10.6534 25.6027 10.4951 26.0013 10.4951C26.3998 10.4951 26.782 10.6534 27.0638 10.9352C27.3455 11.217 27.5039 11.5992 27.5039 11.9977C27.5039 12.3962 27.3455 12.7784 27.0638 13.0602L27.0613 13.0615Z"
        fill="#343330"
      />
    </svg>
  );
};
