import React, { useEffect } from "react";
import { useWindowWidth } from "../../breakpoints";
import { ArrowUpLeft } from "../../components/ArrowUpLeft";
import { Copyright } from "../../components/Copyright";
import { FacebookLogo } from "../../components/FacebookLogo";
import { Footer } from "../../components/Footer";
import { House } from "../../components/House";
import { InstagramLogo } from "../../components/InstagramLogo";
import { LinkedinLogo } from "../../components/LinkedinLogo";
import { Mobile } from "../../components/Mobile";
import { Navbar } from "../../components/Navbar";
import { Newsletter } from "../../components/Newsletter";
import { PaperPlaneTilt } from "../../components/PaperPlaneTilt";
import { Tablets } from "../../components/Tablets";
import { TextHover } from "../../components/TextHover";
import { XLogo } from "../../components/XLogo";
import { YoutubeLogo } from "../../components/YoutubeLogo";
import "./style.css";
import { SlideHover } from "../../components/SlideHover";
import { useIncubatores, usePage, usePartners } from "../../api/api";
import { Facebooklogo } from "../../icons/Facebooklogo";
import { TimeLine } from "../../components/TimeLine";
import { Form } from "../../components/form";
import { useTranslation } from "../../translations/TranslationContext";

export const IncubatorMobile = () => {




  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, []);
  const screenWidth = useWindowWidth();
  const data = usePage(3)
  const sections = data?.data?.sections;
  console.log(sections);
  let postOne = sections?.find((section) => section.id == 8)
  let postTwo = sections?.find((section) => section.id == 9)
  let postThree = sections?.find((section) => section.id == 10)
  // let postFour=sections?.find((section)=>section.id==11)
  const incubators = useIncubatores()
  const { t, changeLocale, locale } = useTranslation();

  return (
    <div
      dir={locale == "ar" ? "rtl" : "ltr"}
      className="incubator-mobile"
      style={{
        backgroundColor:
          (screenWidth >= 834 && screenWidth < 1250) || screenWidth < 834
            ? "#ffffff"
            : screenWidth >= 1250
              ? "var(--collection-1-white)"
              : undefined,
        // height:
        //   screenWidth < 834
        //     ? "8163px"
        //     : screenWidth >= 834 && screenWidth < 1250
        //     ? "6999px"
        //     : screenWidth >= 1250
        //     ? "6045px"
        //     : undefined,
        width:
          screenWidth < 834
            ? "100%"
            : screenWidth >= 834 && screenWidth < 1250
              ? "100%"
              : screenWidth >= 1250
                ? "100%"
                : undefined,
      }}
    >
      <Navbar className="navbar-instance" logo="/img/logo-3.png" />



      <div className="container-94 h-[55vh] md:h-[40vh]">
        <div
          className="container-95"
        >
          <div
            className="container-96"

          >
            <div
              className="text-wrapper-133"
              style={{
                width: "100%",
                fontFamily:
                  screenWidth < 500
                    ? "var(--arabic-heading-h4-font-family)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h3-font-family)"
                      : screenWidth >= 834 && screenWidth < 3000
                        ? "var(--arabic-heading-h1-font-family)"
                        : "var(--arabic-heading-h1-font-family)",
                fontSize:
                  screenWidth < 500
                    ? "var(--arabic-heading-h4-font-size)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h3-font-size)"
                      : screenWidth >= 834 && screenWidth < 3000
                        ? "var(--arabic-heading-h1-font-size)"
                        : "var(--extra-large-size)",
                fontStyle:
                  screenWidth < 500
                    ? "var(--arabic-heading-h4-font-style)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h3-font-style)"
                      : screenWidth >= 834 && screenWidth < 3000
                        ? "var(--arabic-heading-h1-font-style)"
                        : "var(--arabic-heading-h1-font-style)",
                fontWeight:
                  screenWidth < 500
                    ? "var(--arabic-heading-h4-font-weight)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h3-font-weight)"
                      : screenWidth >= 834 && screenWidth < 3000
                        ? "var(--arabic-heading-h1-font-weight)"
                        : "var(--arabic-heading-h1-font-weight)",
                letterSpacing:
                  screenWidth < 500
                    ? "var(--arabic-heading-h4-letter-spacing)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h3-letter-spacing)"
                      : screenWidth >= 834 && screenWidth < 3000
                        ? "var(--arabic-heading-h1-letter-spacing)"
                        : "var(--arabic-heading-h1-letter-spacing)",
                lineHeight:
                  screenWidth < 834
                    ? "var(--arabic-heading-h4-line-height)"
                    : screenWidth >= 834 && screenWidth < 3000
                      ? "var(--arabic-heading-h1-line-height)"
                      : "var(--arabic-heading-h1-line-height)",
              }}
            >
              {" "}
              {t("waterIncubator")}
            </div>
            <p className="text-wrapper-134"
              style={{
                width: "100%",
                fontFamily:
                  screenWidth < 500
                    ? "var(--arabic-heading-h6-font-family)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h6-font-family)"
                      : screenWidth >= 834 && screenWidth < 3000
                        ? "var(--arabic-heading-h6-font-family)"
                        : "var(--arabic-heading-h3-font-family)",
                fontSize:
                  screenWidth < 500
                    ? "var(--arabic-heading-h6-font-size)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h6-font-size)"
                      : screenWidth >= 834 && screenWidth < 3000
                        ? "var(--arabic-heading-h6-font-size)"
                        : "var(--meduam-large-size)",
                fontStyle:
                  screenWidth < 500
                    ? "var(--arabic-heading-h6-font-style)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h6-font-style)"
                      : screenWidth >= 834 && screenWidth < 3000
                        ? "var(--arabic-heading-h6-font-style)"
                        : "var(--arabic-heading-h3-font-style)",
                fontWeight:
                  screenWidth < 500
                    ? "var(--arabic-heading-h6-font-weight)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h6-font-weight)"
                      : screenWidth >= 834 && screenWidth < 3000
                        ? "var(--arabic-heading-h6-font-weight)"
                        : "var(--arabic-heading-h3-font-weight)",
                letterSpacing:
                  screenWidth < 500
                    ? "var(--arabic-heading-h6-letter-spacing)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h6-letter-spacing)"
                      : screenWidth >= 834 && screenWidth < 3000
                        ? "var(--arabic-heading-h6-letter-spacing)"
                        : "var(--arabic-heading-h3-letter-spacing)",
                lineHeight:
                  screenWidth < 834
                    ? "var(--arabic-heading-h6-line-height)"
                    : screenWidth >= 834 && screenWidth < 3000
                      ? "var(--arabic-heading-h6-line-height)"
                      : "var(--arabic-heading-h3-line-height)",
              }}
            >
{t("saudiWaterInnovationCenter")}
            </p>
          </div>
        </div>
      </div>
      <div className="aframe-127">
        <div className="aframe-128">
          <House className={screenWidth < 3000 ? "ainstance-node-8" : "ainstance-node-8more"} format="stroke" formatOutline="/img/house.png" weight="regular" />
          <div className={screenWidth < 3000 ? "aitem-link-home-5" : "aitem-link-home-5"}>{t("homepage")}</div>
        </div>
        <div className={screenWidth < 3000 ? "atext-wrapper-104" : "atext-wrapper-104"}>/</div>

        <div className={screenWidth < 3000 ? "atext-wrapper-103" : "atext-wrapper-103"}>
          {t("waterIncubator")}
        </div>

      </div>



      {screenWidth >= 834 ?
        <>
          <div className={screenWidth < 3000 ? "text-wrapper-163" : "text-wrapper-163more"}>
            {postOne?.title[`${locale}`]}
          </div>
          <div className="frame-208">
            <div className="rectangle-19 " alt="Rectangle" src="../../../" >
              <img src={postOne?.Posts[0]?.attachment[0]?.original} className="w-full" alt="" />
            </div>
            <div className="frame-209">
              <div className="frame-210">
                <div className={screenWidth < 3000 ? "text-wrapper-138" : "text-wrapper-138more"}
                  style={{ color: "var(--greygrey-900)" }}
                >

                  {postOne?.Posts[0]?.title[`${locale}`]}
                </div>
                <p className={screenWidth < 3000 ? "text-wrapper-164" : "text-wrapper-164more"}>
                  {postOne?.Posts[0]?.description[`${locale}`]}

                </p>
              </div>
              {/* <a href="#form">
              <div className="button-hover-4">

                <div className="text-wrapper-165" >قدّم الآن</div>
                    <svg
          fill="none"
          height="20"
          viewBox="0 0 20 20"
          width="20"
          xmlns="http://www.w3.org/3000/svg"
        >
          <path
            className="path"
            d="M14.8 14.8L5.2 5.19995"
            stroke="#2A499E"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
          <path
            className="path"
            d="M13 5.19995H5.2V13"
            stroke="#2A499E"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
                     </svg>


              </div>
                </a> */}
            </div>
          </div>
        </> :
        <div
          className="frame-172"
          style={{
            padding: "5% 2%",
            // left: screenWidth < 834 ? "16px" : screenWidth >= 834 && screenWidth < 1250 ? "24px" : undefined,
            // top: screenWidth < 834 ? "524px" : screenWidth >= 834 && screenWidth < 1250 ? "563px" : undefined,
            // width: screenWidth < 834 ? "328px" : screenWidth >= 834 && screenWidth < 1250 ? "786px" : undefined,
          }}
        >
          <div>
            <img
              className="image-3"
              alt="Image"
              src={postOne?.Posts[0]?.attachment[0]?.original}
            />

          </div>
          <div className="frame-173">
            <div className="div-11">
              {postOne?.Posts[0]?.title[`${locale}`]}
            </div>
            <p
              className="text-wrapper-137"
              style={{
                fontFamily:
                  screenWidth < 834
                    ? "var(--arabic-text-small-medium-font-family)"
                    : screenWidth >= 834 && screenWidth < 1250
                      ? "var(--arabic-text-large-medium-font-family)"
                      : undefined,
                fontSize:
                  screenWidth < 834
                    ? "var(--arabic-text-small-medium-font-size)"
                    : screenWidth >= 834 && screenWidth < 1250
                      ? "var(--arabic-text-large-medium-font-size)"
                      : undefined,
                fontStyle:
                  screenWidth < 834
                    ? "var(--arabic-text-small-medium-font-style)"
                    : screenWidth >= 834 && screenWidth < 1250
                      ? "var(--arabic-text-large-medium-font-style)"
                      : undefined,
                fontWeight:
                  screenWidth < 834
                    ? "var(--arabic-text-small-medium-font-weight)"
                    : screenWidth >= 834 && screenWidth < 1250
                      ? "var(--arabic-text-large-medium-font-weight)"
                      : undefined,
                letterSpacing:
                  screenWidth < 834
                    ? "var(--arabic-text-small-medium-letter-spacing)"
                    : screenWidth >= 834 && screenWidth < 1250
                      ? "var(--arabic-text-large-medium-letter-spacing)"
                      : undefined,
                lineHeight:
                  screenWidth < 834
                    ? "var(--arabic-text-small-medium-line-height)"
                    : screenWidth >= 834 && screenWidth < 1250
                      ? "var(--arabic-text-large-medium-line-height)"
                      : undefined,
              }}
            >
              {postOne?.Posts[0]?.description[`${locale}`]}
            </p>
          </div>
        </div>}

      <div className="frame-174">
        <div className="frame-175">
          <div style={{ textAlign: "center" }} className={screenWidth < 3000 ? "text-wrapper-138" : "text-wrapper-138more text-center"}>{postTwo?.title[`${locale}`]}</div>
          <div className="frame-176">
            <p className="text-wrapper-139"
              style={{
                fontFamily:
                  screenWidth < 834
                    ? "var(--arabic-text-small-medium-font-family)"
                    : screenWidth >= 834 && screenWidth < 3000
                      ? "var(--arabic-text-large-medium-font-family)"
                      : "var(--arabic-text-large-medium-font-family)",
                fontSize:
                  screenWidth < 834
                    ? "var(--arabic-text-small-medium-font-size)"
                    : screenWidth >= 834 && screenWidth < 3000
                      ? "var(--arabic-text-large-medium-font-size)"
                      : "var(--arabic-text-large-medium-font-size)",
                fontStyle:
                  screenWidth < 834
                    ? "var(--arabic-text-small-medium-font-style)"
                    : screenWidth >= 834 && screenWidth < 3000
                      ? "var(--arabic-text-large-medium-font-style)"
                      : "var(--arabic-text-large-medium-font-style)",
                fontWeight:
                  screenWidth < 834
                    ? "var(--arabic-text-small-medium-font-weight)"
                    : screenWidth >= 834 && screenWidth < 3000
                      ? "var(--arabic-text-large-medium-font-weight)"
                      : "var(--arabic-text-large-medium-font-weight)",
                letterSpacing:
                  screenWidth < 834
                    ? "var(--arabic-text-small-medium-letter-spacing)"
                    : screenWidth >= 834 && screenWidth < 3000
                      ? "var(--arabic-text-large-medium-letter-spacing)"
                      : "var(--arabic-text-large-medium-letter-spacing)",
                lineHeight:
                  screenWidth < 834
                    ? "var(--arabic-text-small-medium-line-height)"
                    : screenWidth >= 834 && screenWidth < 3000
                      ? "var(--arabic-text-large-medium-line-height)"
                      : "var(--arabic-text-large-medium-line-height)",
              }}
            >
              {postTwo?.Posts[0]?.description[`${locale}`]}

            </p>
          </div>
        </div>
        {screenWidth >= 1100 ?

          <>
            <div className="frame-66">
              <div className="frame-67">
                {/* <a href="#form">
                  <div className="button-hover-4">
                    <div className="text-wrapper-165">قدّم الآن</div>
                    <svg
          fill="none"
          height="20"
          viewBox="0 0 20 20"
          width="20"
          xmlns="http://www.w3.org/3000/svg"
        >
          <path
            className="path"
            d="M14.8 14.8L5.2 5.19995"
            stroke="#2A499E"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
          <path
            className="path"
            d="M13 5.19995H5.2V13"
            stroke="#2A499E"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
                     </svg>
                  </div>
                    </a> */}
                <div className={screenWidth < 3000 ? "text-wrapper-171" : "text-wrapper-171more"}>{postThree?.title[`${locale}`]}</div>
              </div>

              <div className="element-3">
                <SlideHover
                  className="slide-hover-instance"
                  divClassName={screenWidth < 3000 ? "slide-hover-11" : "slide-hover-11more"}
                  property1="default"
                  content={postThree?.Posts[4]?.description[`${locale}`]}
                  text={postThree?.Posts[4]?.title[`${locale}`]}
                  background={postThree?.Posts[4]?.attachment[0]?.original}

                />
                <SlideHover
                  className="slide-hover-instance"
                  divClassName={screenWidth < 3000 ? "slide-hover-11" : "slide-hover-11more"}
                  property1="default"
                  background={postThree?.Posts[3]?.attachment[0]?.original}

                  content={postThree?.Posts[3]?.description[`${locale}`]}
                  text={postThree?.Posts[3]?.title[`${locale}`]}
                />
                <SlideHover
                  className="slide-hover-instance"
                  divClassName={screenWidth < 3000 ? "slide-hover-11" : "slide-hover-11more"} property1="default"
                  background={postThree?.Posts[2]?.attachment[0]?.original}

                  content={postThree?.Posts[2]?.description[`${locale}`]}
                  text={postThree?.Posts[2]?.title[`${locale}`]} />
                <SlideHover
                  className="slide-hover-instance"
                  divClassName={screenWidth < 3000 ? "slide-hover-11" : "slide-hover-11more"} property1="default"
                  background={postThree?.Posts[1]?.attachment[0]?.original}

                  content={postThree?.Posts[1]?.description[`${locale}`]}
                  text={postThree?.Posts[1]?.title[`${locale}`]} />
                <SlideHover
                  className="slide-hover-instance"
                  divClassName={screenWidth < 3000 ? "slide-hover-11" : "slide-hover-11more"} property1="default"
                  background={postThree?.Posts[0]?.attachment[0]?.original}
                  content={postThree?.Posts[0]?.description[`${locale}`]}
                  text={postThree?.Posts[0]?.title[`${locale}`]}
                />
              </div>
            </div>
          </>



          : <>
            <div className="element-7">
              <div className="slide-hover-1" style={{ backgroundImage: `url(${postThree?.Posts[0]?.attachment[0]?.original})` }}>
                <div className="text-wrapper-140"><span style={{ backgroundColor: "#2A499E" }}>

                  {postThree?.Posts[0]?.title[`${locale}`]}

                </span></div>
                <p className="text-wrapper-29"
                  style={{
                    fontFamily:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-family)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-family)"
                          : undefined,
                    fontSize:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-size)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-size)"
                          : undefined,
                    fontStyle:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-style)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-style)"
                          : undefined,
                    fontWeight:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-weight)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-weight)"
                          : undefined,
                    letterSpacing:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-letter-spacing)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-letter-spacing)"
                          : undefined,
                    lineHeight:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-line-height)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-line-height)"
                          : undefined,
                    color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", padding: "10px"
                  }}
                >
                  {postThree?.Posts[0]?.description[`${locale}`]}
                </p>
              </div>
              <div className="slide-hover-2" style={{ backgroundImage: `url(${postThree?.Posts[1]?.attachment[0]?.original})` }}>
                <div className="text-wrapper-140"><span style={{ backgroundColor: "#2A499E" }}>
                  {postThree?.Posts[1]?.title[`${locale}`]}
                </span></div>
                <p className="text-wrapper-29"
                  style={{
                    fontFamily:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-family)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-family)"
                          : undefined,
                    fontSize:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-size)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-size)"
                          : undefined,
                    fontStyle:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-style)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-style)"
                          : undefined,
                    fontWeight:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-weight)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-weight)"
                          : undefined,
                    letterSpacing:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-letter-spacing)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-letter-spacing)"
                          : undefined,
                    lineHeight:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-line-height)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-line-height)"
                          : undefined,
                    color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", padding: "10px"
                  }}
                >
                  {postThree?.Posts[1]?.description[`${locale}`]}
                </p>

              </div>
              <div className="slide-hover-3" style={{ backgroundImage: `url(${postThree?.Posts[2]?.attachment[0]?.original})` }}>
                <div className="text-wrapper-140"><span style={{ backgroundColor: "#2A499E" }}>
                  {postThree?.Posts[2]?.title[`${locale}`]}

                </span></div>
                <p className="text-wrapper-29"

                  style={{
                    fontFamily:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-family)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-family)"
                          : undefined,
                    fontSize:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-size)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-size)"
                          : undefined,
                    fontStyle:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-style)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-style)"
                          : undefined,
                    fontWeight:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-weight)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-weight)"
                          : undefined,
                    letterSpacing:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-letter-spacing)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-letter-spacing)"
                          : undefined,
                    lineHeight:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-line-height)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-line-height)"
                          : undefined,
                    color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", padding: "10px"
                  }}
                >
                  {postThree?.Posts[2]?.description[`${locale}`]}
                </p>

              </div>
              <div className="slide-hover-4" style={{ backgroundImage: `url(${postThree?.Posts[3]?.attachment[0]?.original})` }}>
                <div className="text-wrapper-140"><span style={{ backgroundColor: "#2A499E" }}>
                  {postThree?.Posts[3]?.title[`${locale}`]}
                </span></div>
                <p className="text-wrapper-29"

                  style={{
                    fontFamily:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-family)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-family)"
                          : undefined,
                    fontSize:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-size)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-size)"
                          : undefined,
                    fontStyle:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-style)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-style)"
                          : undefined,
                    fontWeight:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-weight)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-weight)"
                          : undefined,
                    letterSpacing:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-letter-spacing)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-letter-spacing)"
                          : undefined,
                    lineHeight:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-line-height)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-line-height)"
                          : undefined,
                    color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", padding: "10px"
                  }}
                >
                  {postThree?.Posts[3]?.description[`${locale}`]}
                </p>

              </div>
              <div className="slide-hover-5" style={{ backgroundImage: `url(${postThree?.Posts[4]?.attachment[0]?.original})` }}>
                <div className="text-wrapper-140"><span style={{ backgroundColor: "#2A499E" }}>
                  {postThree?.Posts[4]?.title[`${locale}`]}
                </span></div>
                <p className="text-wrapper-29"
                  style={{
                    fontFamily:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-family)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-family)"
                          : undefined,
                    fontSize:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-size)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-size)"
                          : undefined,
                    fontStyle:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-style)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-style)"
                          : undefined,
                    fontWeight:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-font-weight)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-font-weight)"
                          : undefined,
                    letterSpacing:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-letter-spacing)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-letter-spacing)"
                          : undefined,
                    lineHeight:
                      screenWidth < 834
                        ? "var(--arabic-text-small-medium-line-height)"
                        : screenWidth >= 834 && screenWidth < 1250
                          ? "var(--arabic-text-large-medium-line-height)"
                          : undefined,
                    color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", padding: "10px"
                  }}
                >
                  {postThree?.Posts[4]?.description[`${locale}`]}
                </p>
              </div>
            </div>
          </>}

      </div>

      <div className="" >

        <div className="flex justify-center items-center flex-col gap-[32px] mt-[5%]">
          <p className={screenWidth < 3000 ? "text-wrapper-177" : "text-wrapper-177more"}>{t("incubatorTimeline")}</p>
          <p
            style={{
              fontFamily:
                screenWidth < 834
                  ? "var(--arabic-text-small-medium-font-family)"
                  : screenWidth >= 834 && screenWidth < 3000
                    ? "var(--arabic-text-large-medium-font-family)"
                    : "var(--arabic-text-large-medium-font-family)",
              fontSize:
                screenWidth < 834
                  ? "var(--arabic-text-small-medium-font-size)"
                  : screenWidth >= 834 && screenWidth < 3000
                    ? "var(--arabic-text-large-medium-font-size)"
                    : "var(--arabic-text-large-medium-font-size)",
              fontStyle:
                screenWidth < 834
                  ? "var(--arabic-text-small-medium-font-style)"
                  : screenWidth >= 834 && screenWidth < 3000
                    ? "var(--arabic-text-large-medium-font-style)"
                    : "var(--arabic-text-large-medium-font-style)",
              fontWeight:
                screenWidth < 834
                  ? "var(--arabic-text-small-medium-font-weight)"
                  : screenWidth >= 834 && screenWidth < 3000
                    ? "var(--arabic-text-large-medium-font-weight)"
                    : "var(--arabic-text-large-medium-font-weight)",
              letterSpacing:
                screenWidth < 834
                  ? "var(--arabic-text-small-medium-letter-spacing)"
                  : screenWidth >= 834 && screenWidth < 3000
                    ? "var(--arabic-text-large-medium-letter-spacing)"
                    : "var(--arabic-text-large-medium-letter-spacing)",
              lineHeight:
                screenWidth < 834
                  ? "var(--arabic-text-small-medium-line-height)"
                  : screenWidth >= 834 && screenWidth < 3000
                    ? "var(--arabic-text-large-medium-line-height)"
                    : "var(--arabic-text-large-medium-line-height)",
            }}
            className={screenWidth < 3000 ? "text-wrapper-178" : "text-wrapper-178more"}>{t("startupPathDescription")}</p>
        </div>
        <TimeLine />
        {/* <div className="frame-226 grid grid-cols-5 gap-1 ">
<div className="relative flex justify-center items-center">
              <div className="frame-229">
                <div className="component-7 flex justify-center items-center">
                  <div className={ screenWidth<3000?"text-wrapper-180":"text-wrapper-180more"}>5</div>
                </div>
                <img className="line-24" alt="Line" src="/img/line-15-1.png" />
              </div>
              <div className="overlap-group-8">
                <img className="polygon-15" alt="Polygon" src="/img/polygon-6-2.png" />
                <div className="rectangle-23" />
              </div>
              <div className="frame-236" >
                <div className={screenWidth<3000?"text-wrapper-182":"text-wrapper-182more"}> بدء التسجيل</div>
                <div className={screenWidth<3000?"text-wrapper-149":"text-wrapper-149more"}>14 مايو 2024</div>
              </div>

</div>
<div className="relative flex justify-center items-center">

<div className="overlap-11">
  <div className="component-6">
    <div className="rectangle-24" />
  </div>
  <img className="polygon-13" alt="Polygon" src="/img/polygon-2-1.png" />
</div>
<div className="frame-231">
  <img className="line-24" alt="Line" src="/img/line-13-1.png" />
  <div className="component-8">
    <div className="text-wrapper-181">4</div>
  </div>
</div>
<div className="frame-232">
<div className={screenWidth<3000?"text-wrapper-182":"text-wrapper-182more"}>إغلاق التسجيل</div>
                <div className={screenWidth<3000?"text-wrapper-149":"text-wrapper-149more"}>24 مايو 2024</div>
              </div>
</div>
<div className="relative flex justify-center items-center">
              <div className="frame-229">
                <div className="component-7 flex justify-center items-center">
                  <div className={ screenWidth<3000?"text-wrapper-180":"text-wrapper-180more"}>3</div>
                </div>
                <img className="line-24" alt="Line" src="/img/line-15-1.png" />
              </div>
              <div className="overlap-group-8">
                <img className="polygon-15" alt="Polygon" src="/img/polygon-6-2.png" />
                <div className="rectangle-23" />
              </div>
              <div className="frame-236" >
              <div className={screenWidth<3000?"text-wrapper-182":"text-wrapper-182more"}>عملية الفرز والقبول</div>
                <p className={screenWidth<3000?"text-wrapper-149":"text-wrapper-149more"}>24 مايو 2024 - 10 يونيو 2024</p>
              </div>

</div>
<div className="relative flex justify-center items-center">

<div className="overlap-11">
  <div className="component-6">
    <div className="rectangle-24" />
  </div>
  <img className="polygon-13" alt="Polygon" src="/img/polygon-2-1.png" />
</div>
<div className="frame-231">
  <img className="line-24" alt="Line" src="/img/line-13-1.png" />
  <div className="component-8">
    <div className="text-wrapper-181">2</div>
  </div>
</div>
<div className="frame-232">
<div className={screenWidth<3000?"text-wrapper-182":"text-wrapper-182more"}>إغلاق التسجيل</div>
                <div className={screenWidth<3000?"text-wrapper-149":"text-wrapper-149more"}>24 مايو 2024</div>
              </div>
</div>
<div className="relative flex justify-center items-center">
              <div className="frame-229">
                <div className="component-7 flex justify-center items-center">
                  <div className={ screenWidth<3000?"text-wrapper-180":"text-wrapper-180more"}>1</div>
                </div>
                <img className="line-24" alt="Line" src="/img/line-15-1.png" />
              </div>
              <div className="overlap-group-8">
                <img className="polygon-15" alt="Polygon" src="/img/polygon-6-2.png" />
                <div className="rectangle-23" />
              </div>
              <div className="frame-236" >
              <div className={screenWidth<3000?"text-wrapper-182":"text-wrapper-182more"}>ختام البرنامج وحفل التخريج</div>
                <div className={screenWidth<3000?"text-wrapper-149":"text-wrapper-149more"}>30&nbsp;&nbsp;ديسمبر 2024</div>
              </div>

</div>
            </div> */}
      </div>


      <div className="frame-238 ">
        <div className={screenWidth < 3000 ? "text-wrapper-183" : "text-wrapper-183more"}>{t("currentIncubatees")}</div>
        <div className="w-full">
          <div className={screenWidth < 3000 ? "grid grid-cols-3 2xl:grid-cols-3 xl:grid-cols-3 gap-[2.5rem] lg:grid-cols-2 sm:grid-cols-1 " : "grid grid-cols-3 2xl:grid-cols-3 xl:grid-cols-3 gap-[5rem] lg:grid-cols-2 sm:grid-cols-1 "}>
            {incubators?.data?.map((partner) => {
              return <div className="company-cards-3" key={"incubators" + partner.id}>
                <div className={screenWidth < 3000 ? "company-cards-4" : "company-cards-4more"}>
                  <div className="frame-198">
                    <div className={screenWidth < 3000 ? "frame-199" : "frame-199more"}>
                      <img className="group" alt="Group" src={partner.attachment[0].original} />
                    </div>
                    <div className="frame-200">
                      <div className="frame-239">
                        <div className="frame-194">
                          <div className="frame-195">
                            <div className={screenWidth < 3000 ? "text-wrapper-156" : "text-wrapper-156more"}>{locale=="ar"?"تحلية المياه":"Water desalination"}</div>
                            <img className={screenWidth < 3000 ? "img-6" : "img-6more"} alt="Buildings" src="/img/buildings-2x.png" />
                          </div>
                          <div className="frame-195">
                            <div className={screenWidth < 3000 ? "text-wrapper-156" : "text-wrapper-156more"}>{locale=="ar"?"المرحلة الخامسة":"level five"}</div>
                            <img className={screenWidth < 3000 ? "img-6" : "img-6more"} alt="Drop" src="/img/drop.png" />
                          </div>
                          <div className="frame-195">
                            <div className={screenWidth < 3000 ? "text-wrapper-156" : "text-wrapper-156more"}> {locale=="ar"?"السعودية":"Saudi Arabia"}</div>
                            <img className={screenWidth < 3000 ? "img-6" : "img-6more"} alt="Map pin line" src="/img/mappinline.png" />
                          </div>
                        </div>
                        <img className="line-18" alt="Line" src="/img/line-16.png" />
                        <p className={screenWidth < 3000 ? "text-wrapper-159" : "text-wrapper-159more"}>
                          {partner?.description[`${locale}`]}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="links">
                  <div className="links-2">
                    {/* <img className={screenWidth < 3000 ? "img-6" : "img-6more"} alt="Envelope simple" src="/img/envelopesimple-2.png" /> */}
                    {partner.social.instgram ?
                      <a href={partner.social.instgram} target="_blank">
                        <InstagramLogo
                          className="instance-node-14"
                          format="stroke"
                          formatStrokeWeight={partner.social.instgram}
                          weight="regular"
                        />
                      </a>

                      : ""

                    }

                    {partner.social.facebook ?
                      <a href={partner.social.facebook} target="_blank">
                        <Facebooklogo
                          className="instance-node-14"
                          format="stroke"
                          formatStrokeWeight={partner.social.facebook}
                          weight="regular"
                        />
                      </a>
                      : ""

                    }
                    {partner.social.twetter ?
                      <a href={partner.social.twetter} target="_blank">
                        <XLogo
                          className="instance-node-14"
                          format="stroke"
                          formatStrokeWeight={partner.social.twetter}
                          weight="regular"
                        />
                      </a>
                      : ""

                    }


                    {/* <img className={screenWidth < 3000 ? "img-6" : "img-6more"} alt="Globe simple" src="/img/globesimple-2.png" /> */}
                  </div>
                </div>
              </div>
            })}

            {/* <div className="company-cards-3">
                <div className={screenWidth<3000?"company-cards-4":"company-cards-4more"}>
                  <div className="frame-198">
                    <div className={screenWidth<3000?"frame-199":"frame-199more"}>
                      <img className="group" alt="Group" src="/img/group.png" />
                    </div>
                    <div className="frame-200">
                      <div className="frame-239">
                        <div className="frame-194">
                          <div className="frame-195">
                            <div className={screenWidth<3000?"text-wrapper-156":"text-wrapper-156more"}>تحلية المياه</div>
                            <img className={screenWidth<3000? "img-6":"img-6more"} alt="Buildings" src="/img/buildings-2x.png" />
                          </div>
                          <div className="frame-195">
                            <div className={screenWidth<3000?"text-wrapper-156":"text-wrapper-156more"}>المرحلة الخامسة</div>
                            <img className={screenWidth<3000? "img-6":"img-6more"} alt="Drop" src="/img/drop.png" />
                          </div>
                          <div className="frame-195">
                            <div className={screenWidth<3000?"text-wrapper-156":"text-wrapper-156more"}>السعودية</div>
                            <img className={screenWidth<3000? "img-6":"img-6more"} alt="Map pin line" src="/img/mappinline.png" />
                          </div>
                        </div>
                        <img className="line-18" alt="Line" src="/img/line-16.png" />
                        <p className={screenWidth<3000?"text-wrapper-159":"text-wrapper-159more"}>
                          تهدف الشبكة السعودية للابتكار في تقنيات المياه إلى تعزيز معدل نمو الأعمال في المملكة وتطويرها
                          من خلال تعزيز الابتكار في مجال المياه والاستفادة من ممارسات التصنيع المثلى وسد الفجوات في
                          الشركات الناشئة وغيرها من ممارسات التي تسهم في دفع عجلة
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="links">
                  <div className="links-2">
                    <img className={screenWidth<3000? "img-6":"img-6more"} alt="Envelope simple" src="/img/envelopesimple-2.png" />
                    <InstagramLogo
                      className="instance-node-14"
                      format="stroke"
                      formatStrokeWeight="/img/instagramlogo-6.png"
                      weight="regular"
                    />
                    <LinkedinLogo
                      className="instance-node-14"
                      format="stroke"
                      formatStrokeWeight="/img/linkedinlogo-6.png"
                      weight="regular"
                    />
                    <XLogo
                      className="instance-node-14"
                      format="stroke"
                      formatStrokeWeight="/img/xlogo-6.png"
                      weight="regular"
                    />
                    <img className={screenWidth<3000? "img-6":"img-6more"} alt="Globe simple" src="/img/globesimple-2.png" />
                  </div>
                </div>
              </div>
              <div className="company-cards-3">
                <div className={screenWidth<3000?"company-cards-4":"company-cards-4more"}>
                  <div className="frame-198">
                    <div className={screenWidth<3000?"frame-199":"frame-199more"}>
                      <img className="group" alt="Group" src="/img/group.png" />
                    </div>
                    <div className="frame-200">
                      <div className="frame-239">
                        <div className="frame-194">
                          <div className="frame-195">
                            <div className={screenWidth<3000?"text-wrapper-156":"text-wrapper-156more"}>تحلية المياه</div>
                            <img className={screenWidth<3000? "img-6":"img-6more"} alt="Buildings" src="/img/buildings-2x.png" />
                          </div>
                          <div className="frame-195">
                            <div className={screenWidth<3000?"text-wrapper-156":"text-wrapper-156more"}>المرحلة الخامسة</div>
                            <img className={screenWidth<3000? "img-6":"img-6more"} alt="Drop" src="/img/drop.png" />
                          </div>
                          <div className="frame-195">
                            <div className={screenWidth<3000?"text-wrapper-156":"text-wrapper-156more"}>السعودية</div>
                            <img className={screenWidth<3000? "img-6":"img-6more"} alt="Map pin line" src="/img/mappinline.png" />
                          </div>
                        </div>
                        <img className="line-18" alt="Line" src="/img/line-16.png" />
                        <p className={screenWidth<3000?"text-wrapper-159":"text-wrapper-159more"}>
                          تهدف الشبكة السعودية للابتكار في تقنيات المياه إلى تعزيز معدل نمو الأعمال في المملكة وتطويرها
                          من خلال تعزيز الابتكار في مجال المياه والاستفادة من ممارسات التصنيع المثلى وسد الفجوات في
                          الشركات الناشئة وغيرها من ممارسات التي تسهم في دفع عجلة
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="links">
                  <div className="links-2">
                    <img className={screenWidth<3000? "img-6":"img-6more"} alt="Envelope simple" src="/img/envelopesimple-2.png" />
                    <InstagramLogo
                      className="instance-node-14"
                      format="stroke"
                      formatStrokeWeight="/img/instagramlogo-6.png"
                      weight="regular"
                    />
                    <LinkedinLogo
                      className="instance-node-14"
                      format="stroke"
                      formatStrokeWeight="/img/linkedinlogo-6.png"
                      weight="regular"
                    />
                    <XLogo
                      className="instance-node-14"
                      format="stroke"
                      formatStrokeWeight="/img/xlogo-6.png"
                      weight="regular"
                    />
                    <img className={screenWidth<3000? "img-6":"img-6more"} alt="Globe simple" src="/img/globesimple-2.png" />
                  </div>
                </div>
              </div> */}
            {/* <div className="company-cards-3">
                <div className={screenWidth<3000?"company-cards-4":"company-cards-4more"}>
                  <div className="frame-198">
                    <div className={screenWidth<3000?"frame-199":"frame-199more"}>
                      <img className="group" alt="Group" src="/img/group.png" />
                    </div>
                    <div className="frame-200">
                      <div className="frame-239">
                        <div className="frame-194">
                          <div className="frame-195">
                            <div className={screenWidth<3000?"text-wrapper-156":"text-wrapper-156more"}>تحلية المياه</div>
                            <img className={screenWidth<3000? "img-6":"img-6more"} alt="Buildings" src="/img/buildings-2x.png" />
                          </div>
                          <div className="frame-195">
                            <div className={screenWidth<3000?"text-wrapper-156":"text-wrapper-156more"}>المرحلة الخامسة</div>
                            <img className={screenWidth<3000? "img-6":"img-6more"} alt="Drop" src="/img/drop.png" />
                          </div>
                          <div className="frame-195">
                            <div className={screenWidth<3000?"text-wrapper-156":"text-wrapper-156more"}>السعودية</div>
                            <img className="img-6" alt="Map pin line" src="/img/mappinline.png" />
                          </div>
                        </div>
                        <img className="line-18" alt="Line" src="/img/line-16.png" />
                        <p className={screenWidth<3000?"text-wrapper-159":"text-wrapper-159more"}>
                          تهدف الشبكة السعودية للابتكار في تقنيات المياه إلى تعزيز معدل نمو الأعمال في المملكة وتطويرها
                          من خلال تعزيز الابتكار في مجال المياه والاستفادة من ممارسات التصنيع المثلى وسد الفجوات في
                          الشركات الناشئة وغيرها من ممارسات التي تسهم في دفع عجلة
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="links">
                  <div className="links-2">
                    <img className="img-6" alt="Envelope simple" src="/img/envelopesimple-2.png" />
                    <InstagramLogo
                      className="instance-node-14"
                      format="stroke"
                      formatStrokeWeight="/img/instagramlogo-6.png"
                      weight="regular"
                    />
                    <LinkedinLogo
                      className="instance-node-14"
                      format="stroke"
                      formatStrokeWeight="/img/linkedinlogo-6.png"
                      weight="regular"
                    />
                    <XLogo
                      className="instance-node-14"
                      format="stroke"
                      formatStrokeWeight="/img/xlogo-6.png"
                      weight="regular"
                    />
                    <img className="img-6" alt="Globe simple" src="/img/globesimple-2.png" />
                  </div>
                </div>
              </div>
              <div className="company-cards-3">
                <div className={screenWidth<3000?"company-cards-4":"company-cards-4more"}>
                  <div className="frame-198">
                    <div className={screenWidth<3000?"frame-199":"frame-199more"}>
                      <img className="group" alt="Group" src="/img/group.png" />
                    </div>
                    <div className="frame-200">
                      <div className="frame-239">
                        <div className="frame-194">
                          <div className="frame-195">
                            <div className={screenWidth<3000?"text-wrapper-156":"text-wrapper-156more"}>تحلية المياه</div>
                            <img className="img-6" alt="Buildings" src="/img/buildings-2x.png" />
                          </div>
                          <div className="frame-195">
                            <div className={screenWidth<3000?"text-wrapper-156":"text-wrapper-156more"}>المرحلة الخامسة</div>
                            <img className="img-6" alt="Drop" src="/img/drop.png" />
                          </div>
                          <div className="frame-195">
                            <div className={screenWidth<3000?"text-wrapper-156":"text-wrapper-156more"}>السعودية</div>
                            <img className="img-6" alt="Map pin line" src="/img/mappinline.png" />
                          </div>
                        </div>
                        <img className="line-18" alt="Line" src="/img/line-16.png" />
                        <p className={screenWidth<3000?"text-wrapper-159":"text-wrapper-159more"}>
                          تهدف الشبكة السعودية للابتكار في تقنيات المياه إلى تعزيز معدل نمو الأعمال في المملكة وتطويرها
                          من خلال تعزيز الابتكار في مجال المياه والاستفادة من ممارسات التصنيع المثلى وسد الفجوات في
                          الشركات الناشئة وغيرها من ممارسات التي تسهم في دفع عجلة
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="links">
                  <div className="links-2">
                    <img className="img-6" alt="Envelope simple" src="/img/envelopesimple-2.png" />
                    <InstagramLogo
                      className="instance-node-14"
                      format="stroke"
                      formatStrokeWeight="/img/instagramlogo-6.png"
                      weight="regular"
                    />
                    <LinkedinLogo
                      className="instance-node-14"
                      format="stroke"
                      formatStrokeWeight="/img/linkedinlogo-6.png"
                      weight="regular"
                    />
                    <XLogo
                      className="instance-node-14"
                      format="stroke"
                      formatStrokeWeight="/img/xlogo-6.png"
                      weight="regular"
                    />
                    <img className="img-6" alt="Globe simple" src="/img/globesimple-2.png" />
                  </div>
                </div>
              </div> */}

          </div>

        </div>
      </div>
      <div className={screenWidth < 2000 ? "lg:mt-[250px]" :
        screenWidth < 3000 ? "mt-[400px]" :
          "mt-[550px]"}></div>



      <Footer className="footer-3" logo="/img/logo-9.png" property1="web" />

    </div>
  );
};
