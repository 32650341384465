import React, { useEffect } from "react";
import { useWindowWidth } from "../../breakpoints";
import { ArrowUpLeft } from "../../components/ArrowUpLeft";
import { CaretDown } from "../../components/CaretDown";
import { Copyright } from "../../components/Copyright";
import { FacebookLogo } from "../../components/FacebookLogo";
import { Footer } from "../../components/Footer";
import { House } from "../../components/House";
import { InstagramLogo } from "../../components/InstagramLogo";
import { LinkedinLogo } from "../../components/LinkedinLogo";
import { List } from "../../components/List";
import { Navbar } from "../../components/Navbar";
import { Newsletter } from "../../components/Newsletter";
import { PaperPlaneTilt } from "../../components/PaperPlaneTilt";
import { TextHover } from "../../components/TextHover";
import { User } from "../../components/User";
import { XLogo } from "../../components/XLogo";
import { YoutubeLogo } from "../../components/YoutubeLogo";
import "./style.css";
import { Clockafternoon8 } from "../../icons/Clockafternoon8";
import { useReports } from "../../api/api";
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Form } from "../../components/Form/Form";
import { useTranslation } from "../../translations/TranslationContext";

export const Register = () => {


  const screenWidth = useWindowWidth();
  const { t, changeLocale, locale } = useTranslation();

  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, []);

  return (<>
    <Navbar className="navbar-instance" logo="/img/logo-3.png" />
    <Form/>
    <div className={screenWidth < 2000 ? "mt-[100px] lg:mt-[300px] sm:mt-[80px]" : "mt-[700px]"}></div>
    <Footer className="footer-3" logo="/img/logo-9.png" property1="web" />
  </>);
};
