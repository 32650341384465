/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Caretdown } from "../../icons/Caretdown";
import "./style.css";
import { useWindowWidth } from "../../breakpoints";

export const NavPages = ({ property1, className, divClassName, text = "عن المركز", divClassNameOverride, to }) => {
  const screenWidth = useWindowWidth();

  return (
    <Link className={`nav-pages ${className}`} to={to}>
      <div
        className={`div ${property1 === "on" ? divClassName : property1 === "off" ? divClassName : undefined}`}
      >
        {text}
      </div>
      {property1 === "on" && <Caretdown className={screenWidth<2000?"caret-down":"caret-downmore"} color="#616161" />}

    </Link>
  );
};

NavPages.propTypes = {
  property1: PropTypes.oneOf(["off", "on"]),
  text: PropTypes.string,
  to: PropTypes.string,
};
