/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Linkedinlogo = ({ className }) => {
  return (
    <svg
      className={`linkedinlogo ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.875 2.5H3.125C2.77982 2.5 2.5 2.77982 2.5 3.125V16.875C2.5 17.2202 2.77982 17.5 3.125 17.5H16.875C17.2202 17.5 17.5 17.2202 17.5 16.875V3.125C17.5 2.77982 17.2202 2.5 16.875 2.5Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <path
        className="path"
        d="M9.375 8.75V13.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <path
        className="path"
        d="M6.875 8.75V13.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <path
        className="path"
        d="M9.375 10.9375C9.375 10.3573 9.60547 9.80094 10.0157 9.3907C10.4259 8.98047 10.9823 8.75 11.5625 8.75C12.1427 8.75 12.6991 8.98047 13.1093 9.3907C13.5195 9.80094 13.75 10.3573 13.75 10.9375V13.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <path
        className="path"
        d="M6.875 7.5C7.39277 7.5 7.8125 7.08027 7.8125 6.5625C7.8125 6.04473 7.39277 5.625 6.875 5.625C6.35723 5.625 5.9375 6.04473 5.9375 6.5625C5.9375 7.08027 6.35723 7.5 6.875 7.5Z"
        fill="white"
      />
    </svg>
  );
};
