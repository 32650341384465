import React, { useEffect } from "react";
import { useWindowWidth } from "../../breakpoints";
import { Copyright } from "../../components/Copyright";
import { Eye } from "../../components/Eye";
import { FacebookLogo } from "../../components/FacebookLogo";
import { Footer } from "../../components/Footer";
import { House } from "../../components/House";
import { InstagramLogo } from "../../components/InstagramLogo";
import { LinkedinLogo } from "../../components/LinkedinLogo";
import { Mobile } from "../../components/Mobile";
import { Navbar } from "../../components/Navbar";
import { Newsletter } from "../../components/Newsletter";
import { PaperPlaneTilt } from "../../components/PaperPlaneTilt";
import { Tablets } from "../../components/Tablets";
import { Target } from "../../components/Target";
import { TeamCard } from "../../components/TeamCard";
import { TextHover } from "../../components/TextHover";
import { XLogo } from "../../components/XLogo";
import { YoutubeLogo } from "../../components/YoutubeLogo";
import "./style.css";
import { NavLink } from "react-router-dom";
import { ButtonHover } from "../../components/ButtonHover";
import { SlideHover } from "../../components/SlideHover";
import { FormatStrokeWeightLight2 } from "../../icons/FormatStrokeWeightLight2";
import { usePage, useTeam } from "../../api/api";
import { useTranslation } from "../../translations/TranslationContext";

export const AboutTheCentre = () => {
  const screenWidth = useWindowWidth();
  const team = useTeam()
  const data = usePage(1)

  const sections = data?.data?.sections;
  let postOne = sections?.find((section) => section.id == 1)
  let postTwo = sections?.find((section) => section.id == 2)
  let postThree = sections?.find((section) => section.id == 3)
  // let postFour=sections?.find((section)=>section.id==7)
  console.log(team);
  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, []);

  const { t, changeLocale, locale } = useTranslation();

  return (
    <>
      <div
        dir={locale == "ar" ? "rtl" : "ltr"}
        className="about-the-centre"
        style={{
          backgroundColor:
            (screenWidth >= 834 && screenWidth < 1550) || screenWidth < 834
              ? "#ffffff"
              : screenWidth >= 1550
                ? "var(--collection-1-white)"
                : undefined,
          width: "100%"
        }}
      >
        <Navbar className="navbar-instance" logo="/img/logo-3.png" />

        <div
          className="container-35 h-[55vh] md:h-[40vh]"
          style={{
            backgroundImage:
              screenWidth < 834
                ? "url(/img/waterbackground.webp)"
                : screenWidth >= 834 && screenWidth < 1550
                  ? "url(/img/waterbackground.webp)"
                  : "url(/img/waterbackground.webp)",
          }}
        >
          <div
            className="container-36"

          >

            <div className="container-37">
              <div
                className="text-wrapper-28"
                style={{
                  width: "100%",
                  fontFamily:
                    screenWidth < 500
                      ? "var(--arabic-heading-h4-font-family)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h3-font-family)"
                        : screenWidth >= 834
                          ? "var(--arabic-heading-h1-font-family)"
                          : "var(--arabic-heading-h1-font-family)",
                  fontSize:
                    screenWidth < 500
                      ? "var(--arabic-heading-h4-font-size)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h3-font-size)"
                        : screenWidth >= 834
                          ? "var(--arabic-heading-h1-font-size)"
                          : "var(--extra-large-size)",
                  fontStyle:
                    screenWidth < 500
                      ? "var(--arabic-heading-h4-font-style)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h3-font-style)"
                        : screenWidth >= 834
                          ? "var(--arabic-heading-h1-font-style)"
                          : "var(--arabic-heading-h1-font-style)",
                  fontWeight:
                    screenWidth < 500
                      ? "var(--arabic-heading-h4-font-weight)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h3-font-weight)"
                        : screenWidth >= 834
                          ? "var(--arabic-heading-h1-font-weight)"
                          : "var(--arabic-heading-h1-font-weight)",
                  letterSpacing:
                    screenWidth < 500
                      ? "var(--arabic-heading-h4-letter-spacing)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h3-letter-spacing)"
                        : screenWidth >= 834
                          ? "var(--arabic-heading-h1-letter-spacing)"
                          : "var(--arabic-heading-h1-letter-spacing)",
                  lineHeight:
                    screenWidth < 834
                      ? "var(--arabic-heading-h4-line-height)"
                      : screenWidth >= 834
                        ? "var(--arabic-heading-h1-line-height)"
                        : "var(--arabic-heading-h1-line-height)",
                }}
              >
{t("aboutCenter")}              </div>
              <p className="text-wrapper-29"
                style={{
                  width: "100%",
                  fontFamily:
                    screenWidth < 500
                      ? "var(--arabic-heading-h6-font-family)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h6-font-family)"
                        : screenWidth >= 834
                          ? "var(--arabic-heading-h6-font-family)"
                          : "var(--arabic-heading-h3-font-family)",
                  fontSize:
                    screenWidth < 500
                      ? "var(--arabic-heading-h6-font-size)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h6-font-size)"
                        : screenWidth >= 834
                          ? "var(--arabic-heading-h6-font-size)"
                          : "var(--meduam-large-size)",
                  fontStyle:
                    screenWidth < 500
                      ? "var(--arabic-heading-h6-font-style)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h6-font-style)"
                        : screenWidth >= 834
                          ? "var(--arabic-heading-h6-font-style)"
                          : "var(--arabic-heading-h3-font-style)",
                  fontWeight:
                    screenWidth < 500
                      ? "var(--arabic-heading-h6-font-weight)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h6-font-weight)"
                        : screenWidth >= 834
                          ? "var(--arabic-heading-h6-font-weight)"
                          : "var(--arabic-heading-h3-font-weight)",
                  letterSpacing:
                    screenWidth < 500
                      ? "var(--arabic-heading-h6-letter-spacing)"
                      : screenWidth >= 500 && screenWidth < 834
                        ? "var(--arabic-heading-h6-letter-spacing)"
                        : screenWidth >= 834
                          ? "var(--arabic-heading-h6-letter-spacing)"
                          : "var(--arabic-heading-h3-letter-spacing)",
                  lineHeight:
                    screenWidth < 834
                      ? "var(--arabic-heading-h6-line-height)"
                      : screenWidth >= 834
                        ? "var(--arabic-heading-h6-line-height)"
                        : "var(--arabic-heading-h3-line-height)",
                }}

              >{t("saudiWaterInnovationCenter")}
              </p>
            </div>

          </div>
        </div>

        {/* <div
            className="frame-28"
            style={{

            }}
          >
            <div
              className="text-wrapper-30"
              style={{
                fontFamily:
                  screenWidth < 834
                    ? "var(--arabic-text-regular-medium-font-family)"
                    : screenWidth >= 834 && screenWidth < 1600
                    ? "var(--arabic-text-medium-medium-font-family)"
                    : undefined,
                fontSize:
                  screenWidth < 834
                    ? "var(--arabic-text-regular-medium-font-size)"
                    : screenWidth >= 834 && screenWidth < 1600
                    ? "var(--arabic-text-medium-medium-font-size)"
                    : undefined,
                fontStyle:
                  screenWidth < 834
                    ? "var(--arabic-text-regular-medium-font-style)"
                    : screenWidth >= 834 && screenWidth < 1600
                    ? "var(--arabic-text-medium-medium-font-style)"
                    : undefined,
                fontWeight:
                  screenWidth < 834
                    ? "var(--arabic-text-regular-medium-font-weight)"
                    : screenWidth >= 834 && screenWidth < 1600
                    ? "var(--arabic-text-medium-medium-font-weight)"
                    : undefined,
                letterSpacing:
                  screenWidth < 834
                    ? "var(--arabic-text-regular-medium-letter-spacing)"
                    : screenWidth >= 834 && screenWidth < 1600
                    ? "var(--arabic-text-medium-medium-letter-spacing)"
                    : undefined,
                lineHeight:
                  screenWidth < 834
                    ? "var(--arabic-text-regular-medium-line-height)"
                    : screenWidth >= 834 && screenWidth < 1600
                    ? "var(--arabic-text-medium-medium-line-height)"
                    : undefined,
              }}
            >
              عن المركز
            </div>
            <div
              className="text-wrapper-31"
              style={{
                fontFamily:
                  screenWidth < 834
                    ? "var(--arabic-text-regular-medium-font-family)"
                    : screenWidth >= 834 && screenWidth < 1600
                    ? "'Outfit', Helvetica"
                    : undefined,
                fontSize:
                  screenWidth < 834
                    ? "var(--arabic-text-regular-medium-font-size)"
                    : screenWidth >= 834 && screenWidth < 1600
                    ? "18px"
                    : undefined,
                fontStyle: screenWidth < 834 ? "var(--arabic-text-regular-medium-font-style)" : undefined,
                fontWeight:
                  screenWidth < 834
                    ? "var(--arabic-text-regular-medium-font-weight)"
                    : screenWidth >= 834 && screenWidth < 1600
                    ? "400"
                    : undefined,
                letterSpacing:
                  screenWidth < 834
                    ? "var(--arabic-text-regular-medium-letter-spacing)"
                    : screenWidth >= 834 && screenWidth < 1600
                    ? "0.50px"
                    : undefined,
                lineHeight:
                  screenWidth < 834
                    ? "var(--arabic-text-regular-medium-line-height)"
                    : screenWidth >= 834 && screenWidth < 1600
                    ? "20px"
                    : undefined,
                marginTop: screenWidth < 834 ? "-1.00px" : undefined,
              }}
            >
              /
            </div>
            <div className="frame-29">
              <div
                className="item-link-home"
                style={{
                  fontFamily:
                    screenWidth < 834
                      ? "var(--arabic-text-regular-medium-font-family)"
                      : screenWidth >= 834 && screenWidth < 1600
                      ? "var(--arabic-text-medium-medium-font-family)"
                      : undefined,
                  fontSize:
                    screenWidth < 834
                      ? "var(--arabic-text-regular-medium-font-size)"
                      : screenWidth >= 834 && screenWidth < 1600
                      ? "var(--arabic-text-medium-medium-font-size)"
                      : undefined,
                  fontStyle:
                    screenWidth < 834
                      ? "var(--arabic-text-regular-medium-font-style)"
                      : screenWidth >= 834 && screenWidth < 1600
                      ? "var(--arabic-text-medium-medium-font-style)"
                      : undefined,
                  fontWeight:
                    screenWidth < 834
                      ? "var(--arabic-text-regular-medium-font-weight)"
                      : screenWidth >= 834 && screenWidth < 1600
                      ? "var(--arabic-text-medium-medium-font-weight)"
                      : undefined,
                  letterSpacing:
                    screenWidth < 834
                      ? "var(--arabic-text-regular-medium-letter-spacing)"
                      : screenWidth >= 834 && screenWidth < 1600
                      ? "var(--arabic-text-medium-medium-letter-spacing)"
                      : undefined,
                  lineHeight:
                    screenWidth < 834
                      ? "var(--arabic-text-regular-medium-line-height)"
                      : screenWidth >= 834 && screenWidth < 1600
                      ? "var(--arabic-text-medium-medium-line-height)"
                      : undefined,
                }}
              >
                الرئيسية
              </div>
              <House
                className={`${screenWidth < 834 && "class-23"} ${
                  screenWidth >= 834 && screenWidth < 1600 && "instance-node-4"
                }`}
                format="stroke"
                formatOutline={
                  screenWidth < 834
                    ? "/img/house-1-2x.png"
                    : screenWidth >= 834 && screenWidth < 1600
                    ? "/img/house.png"
                    : undefined
                }
                weight="regular"
              />
            </div>
          </div> */}
        <div className="aframe-127">
          <div className="aframe-128">
            <House className={screenWidth < 3000 ? "ainstance-node-8" : "ainstance-node-8more"} format="stroke" formatOutline="/img/house.png" weight="regular" />
            <div className={screenWidth < 3000 ? "aitem-link-home-5" : "aitem-link-home-5"}>{t("homepage")}</div>
          </div>
          <div className={screenWidth < 3000 ? "atext-wrapper-104" : "atext-wrapper-104"}>/</div>

          <div className={screenWidth < 3000 ? "atext-wrapper-103" : "atext-wrapper-103"}>
{t("aboutCenter")}          </div>

        </div>
        {screenWidth >= 834 ?
          <>
            <div className={screenWidth < 2000 ? "text-wrapper-163" : "text-wrapper-163"} id="about">
              {postOne?.title[`${locale}`]}
            </div></> : ""}
        <div
          className="grid grid-cols-2 flex lg:row-reverse gap-12 lg:grid-cols-1 px-[5%] py-[2%]"
        >
          <div className="frame-31">
            <div className={screenWidth < 2000 ? "text-wrapper-32" : "text-wrapper-32"}>
              {postOne?.Posts[0]?.title[`${locale}`]}
            </div>
            <p
              className="text-wrapper-33"
              style={{
                fontFamily:
                  screenWidth < 834
                    ? "var(--arabic-text-medium-medium-font-family)"
                    : screenWidth >= 834 && screenWidth < 1600
                      ? "var(--arabic-text-large-medium-font-family)"
                      : "var(--arabic-text-large-medium-font-family)",
                fontSize:
                  screenWidth < 834
                    ? "var(--arabic-text-medium-medium-font-size)"
                    : screenWidth >= 834 && screenWidth < 1600
                      ? "var(--arabic-text-large-medium-font-size)"
                      : "var(--arabic-text-large-medium-font-size)",
                fontStyle:
                  screenWidth < 834
                    ? "var(--arabic-text-medium-medium-font-style)"
                    : screenWidth >= 834 && screenWidth < 1600
                      ? "var(--arabic-text-large-medium-font-style)"
                      : "var(--arabic-text-large-medium-font-style)",
                fontWeight:
                  screenWidth < 834
                    ? "var(--arabic-text-medium-medium-font-weight)"
                    : screenWidth >= 834 && screenWidth < 1600
                      ? "var(--arabic-text-large-medium-font-weight)"
                      : "var(--arabic-text-large-medium-font-weight)",
                letterSpacing:
                  screenWidth < 834
                    ? "var(--arabic-text-medium-medium-letter-spacing)"
                    : screenWidth >= 834 && screenWidth < 1600
                      ? "var(--arabic-text-large-medium-letter-spacing)"
                      : "var(--arabic-text-large-medium-letter-spacing)",
                lineHeight:
                  screenWidth < 834
                    ? "var(--arabic-text-medium-medium-line-height)"
                    : screenWidth >= 834 && screenWidth < 1600
                      ? "var(--arabic-text-large-medium-line-height)"
                      : "var(--arabic-text-large-medium-line-height)",
              }}
            >
              {postOne?.Posts[0]?.description[`${locale}`]}
            </p>
          </div>
          <div>
            <img
              className="image"
              alt="Image"
              src={
                screenWidth < 834
                  ? postOne?.Posts[0]?.attachment[0]?.original
                  : screenWidth >= 834 && screenWidth < 1550
                    ? postOne?.Posts[0]?.attachment[0]?.original
                    : postOne?.Posts[0]?.attachment[0]?.original
              }
            />

          </div>

        </div>
        {screenWidth > 1100 ?
          <>
            <div className="incubator">
              <div className="frame-65">
              </div>
              <div className="frame-66">
                <div className="frame-67">
                  <div className={screenWidth < 2000 ? "text-wrapper-72" : "text-wrapper-72"}>{t("centerObjectives")}</div>
                </div>
                <div className="element-3 flex-row-reverse">
                  <SlideHover
                    className="slide-hover-instance"
                    divClassName={screenWidth < 2000 ? "slide-hover-11" : "slide-hover-11"}
                    property1="default"
                    content={postTwo?.Posts[4]?.description[`${locale}`]}
                    text={postTwo?.Posts[4]?.title[`${locale}`]}
                    background={postTwo?.Posts[4]?.attachment[0]?.original}

                  />
                  <SlideHover
                    className="slide-hover-instance"
                    divClassName={screenWidth < 2000 ? "slide-hover-11" : "slide-hover-11"}
                    property1="default"
                    background={postTwo?.Posts[3]?.attachment[0]?.original}

                    content={postTwo?.Posts[3]?.description[`${locale}`]}
                    text={postTwo?.Posts[3]?.title[`${locale}`]}
                  />
                  <SlideHover
                    className="slide-hover-instance"
                    divClassName={screenWidth < 2000 ? "slide-hover-11" : "slide-hover-11"} property1="default"
                    background={postTwo?.Posts[2]?.attachment[0]?.original}

                    content={postTwo?.Posts[2]?.description[`${locale}`]}
                    text={postTwo?.Posts[2]?.title[`${locale}`]} />
                  <SlideHover
                    className="slide-hover-instance"
                    divClassName={screenWidth < 2000 ? "slide-hover-11" : "slide-hover-11"} property1="default"
                    background={postTwo?.Posts[1]?.attachment[0]?.original}

                    content={postTwo?.Posts[1]?.description[`${locale}`]}
                    text={postTwo?.Posts[1]?.title[`${locale}`]} />
                  <SlideHover
                    className="slide-hover-instance"
                    divClassName={screenWidth < 2000 ? "slide-hover-11" : "slide-hover-11"} property1="default"
                    background={postTwo?.Posts[0]?.attachment[0]?.original}
                    content={postTwo?.Posts[0]?.description[`${locale}`]}
                    text={postTwo?.Posts[0]?.title[`${locale}`]}
                  />
                </div>
              </div>
            </div>
          </> : <>

            <div className="frame-27 screen">
              <div className="frame-28">
                <div className="text-wrapper-28"
                  style={{
                    fontFamily:
                      screenWidth < 500
                        ? "var(--arabic-heading-h5-font-family)"
                        : screenWidth >= 500 && screenWidth < 834
                          ? "var(--arabic-heading-h4-font-family)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-font-family)"
                            : undefined,
                    fontSize:
                      screenWidth < 500
                        ? "var(--arabic-heading-h5-font-size)"
                        : screenWidth >= 500 && screenWidth < 834
                          ? "var(--arabic-heading-h4-font-size)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-font-size)"
                            : undefined,
                    fontStyle:
                      screenWidth < 500
                        ? "var(--arabic-heading-h5-font-style)"
                        : screenWidth >= 500 && screenWidth < 834
                          ? "var(--arabic-heading-h4-font-style)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-font-style)"
                            : undefined,
                    fontWeight:
                      screenWidth < 500
                        ? "var(--arabic-heading-h5-font-weight)"
                        : screenWidth >= 500 && screenWidth < 834
                          ? "var(--arabic-heading-h4-font-weight)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-font-weight)"
                            : undefined,
                    letterSpacing:
                      screenWidth < 500
                        ? "var(--arabic-heading-h4-letter-spacing)"
                        : screenWidth >= 500 && screenWidth < 834
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-letter-spacing)"
                            : undefined,
                    lineHeight:
                      screenWidth < 834
                        ? "var(--arabic-heading-h4-line-height)"
                        : screenWidth >= 834 && screenWidth < 1550
                          ? "var(--arabic-heading-h3-line-height)"
                          : undefined,
                  }}
                >

                  {postTwo?.title[`${locale}`]}


                </div>
              </div>
              <div className="element">
                <div className="slide-hover-2" style={{ backgroundImage: `url(${postTwo?.Posts[0]?.attachment[0]?.original})` }}>
                  <div className="text-wrapper-30"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-family)"
                              : undefined,
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-size)"
                              : undefined,
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-style)"
                              : undefined,
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-weight)"
                              : undefined,
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-letter-spacing)"
                              : undefined,
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-heading-h4-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-line-height)"
                            : undefined,
                    }}
                  >

                    {postTwo?.Posts[0]?.title[`${locale}`]}
                  </div>
                  <p className="text-wrapper-29"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-small-medium-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-small-medium-font-family)"
                              : undefined,
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-size)"
                              : undefined,
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-style)"
                              : undefined,
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-weight)"
                              : undefined,
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-letter-spacing)"
                              : undefined,
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-text-small-medium-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-text-small-medium-line-height)"
                            : undefined,
                      color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", padding: "10px"
                    }}
                  >
                    {postTwo?.Posts[0]?.description[`${locale}`]}

                  </p>
                </div>
                <div className="slide-hover-3" style={{ backgroundImage: `url(${postTwo?.Posts[1]?.attachment[0]?.original})` }}>
                  <div className="text-wrapper-30"

                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-family)"
                              : undefined,
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-size)"
                              : undefined,
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-style)"
                              : undefined,
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-weight)"
                              : undefined,
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-letter-spacing)"
                              : undefined,
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-heading-h4-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-line-height)"
                            : undefined,
                    }}
                  >
                    {postTwo?.Posts[1]?.title[`${locale}`]}
                  </div>
                  <p className="text-wrapper-29"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-small-medium-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-small-medium-font-family)"
                              : undefined,
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-size)"
                              : undefined,
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-style)"
                              : undefined,
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-weight)"
                              : undefined,
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-letter-spacing)"
                              : undefined,
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-text-small-medium-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-text-small-medium-line-height)"
                            : undefined,
                      color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", padding: "10px"
                    }}                    >
                    {postTwo?.Posts[1]?.description[`${locale}`]}
                  </p>
                </div>
                <div className="slide-hover-4" style={{ backgroundImage: `url(${postTwo?.Posts[2]?.attachment[0]?.original})` }}>
                  <div className="text-wrapper-30"

                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-family)"
                              : undefined,
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-size)"
                              : undefined,
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-style)"
                              : undefined,
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-weight)"
                              : undefined,
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-letter-spacing)"
                              : undefined,
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-heading-h4-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-line-height)"
                            : undefined,
                    }}
                  >
                    {postTwo?.Posts[2]?.title[`${locale}`]}
                  </div>
                  <p className="text-wrapper-29"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-small-medium-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-small-medium-font-family)"
                              : undefined,
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-size)"
                              : undefined,
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-style)"
                              : undefined,
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-weight)"
                              : undefined,
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-letter-spacing)"
                              : undefined,
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-text-small-medium-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-text-small-medium-line-height)"
                            : undefined,
                      color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", padding: "10px"
                    }}
                  >
                    {postTwo?.Posts[2]?.description[`${locale}`]}
                  </p>
                </div>
                <div className="slide-hover-5" style={{ backgroundImage: `url(${postTwo?.Posts[3]?.attachment[0]?.original})` }}>
                  <div className="text-wrapper-30"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-family)"
                              : undefined,
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-size)"
                              : undefined,
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-style)"
                              : undefined,
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-weight)"
                              : undefined,
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-letter-spacing)"
                              : undefined,
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-heading-h4-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-line-height)"
                            : undefined,
                    }}
                  >
                    {postTwo?.Posts[3]?.title[`${locale}`]}

                  </div>
                  <p className="text-wrapper-29"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-small-medium-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-small-medium-font-family)"
                              : undefined,
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-size)"
                              : undefined,
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-style)"
                              : undefined,
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-weight)"
                              : undefined,
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-letter-spacing)"
                              : undefined,
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-text-small-medium-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-text-small-medium-line-height)"
                            : undefined,
                      color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", padding: "10px"
                    }}
                  >
                    {postTwo?.Posts[3]?.description[`${locale}`]}

                  </p>
                </div>
                <div className="slide-hover-6" style={{ backgroundImage: `url(${postTwo?.Posts[4]?.attachment[0]?.original})` }}>
                  <div className="text-wrapper-30"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-family)"
                              : undefined,
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-size)"
                              : undefined,
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-style)"
                              : undefined,
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-heading-h5-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-font-weight)"
                              : undefined,
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h4-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-heading-h3-letter-spacing)"
                              : undefined,
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-heading-h4-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h3-line-height)"
                            : undefined,
                    }}
                  >

                    {postTwo?.Posts[4]?.title[`${locale}`]}


                  </div>
                  <p className="text-wrapper-29"
                    style={{
                      fontFamily:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-family)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-small-medium-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-small-medium-font-family)"
                              : undefined,
                      fontSize:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-size)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-size)"
                              : undefined,
                      fontStyle:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-style)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-style)"
                              : undefined,
                      fontWeight:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-font-weight)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-font-weight)"
                              : undefined,
                      letterSpacing:
                        screenWidth < 500
                          ? "var(--arabic-text-small-medium-letter-spacing)"
                          : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-text-large-medium-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                              ? "var(--arabic-text-large-medium-letter-spacing)"
                              : undefined,
                      lineHeight:
                        screenWidth < 834
                          ? "var(--arabic-text-small-medium-line-height)"
                          : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-text-small-medium-line-height)"
                            : undefined,
                      color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", padding: "10px"
                    }}                    >
                    {postTwo?.Posts[4]?.description[`${locale}`]}
                  </p>
                </div>
              </div>
            </div>

          </>}
        <div
          className="frame-40"
        >
          <div
            className={screenWidth < 2000 ? "text-wrapper-37" : "text-wrapper-37"}
            style={{
              textAlign: screenWidth < 834 || screenWidth > 1200 ? "center" : undefined,
            }}
            id="massage"
          >
            {postThree?.title[`${locale}`]}
          </div>
          <div className={screenWidth < 1200 ? "frame-41" : "flexbox"}>
            <div className="frame-42">
              <div
                className="frame-43"
                style={{
                  padding:
                    screenWidth >= 834 && screenWidth < 1600
                      ? "55px 46px"
                      : screenWidth < 834
                        ? "55px 18px"
                        : "55px 18px",
                }}
              >
                <div className="frame-44">
                  <Target
                    className={`${screenWidth >= 834 && screenWidth < 1600 && "instance-node-5"} ${screenWidth < 834 && "class-24"
                      }`}
                    format="stroke"
                    formatStrokeWeight={
                      screenWidth >= 834 && screenWidth < 1600
                        ? "/img/target-1.png"
                        : screenWidth < 834
                          ? "/img/target.png"
                          : "/img/target.png"
                    }
                    weight="duotone"
                  />
                  <div
                    className="text-wrapper-38"
                    style={{
                      fontFamily:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-heading-h4-font-family)"
                          : screenWidth < 834
                            ? "var(--arabic-heading-h5-font-family)"
                            : "var(--arabic-heading-h4-font-family)",
                      fontSize:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-heading-h4-font-size)"
                          : screenWidth < 834
                            ? "var(--arabic-heading-h5-font-size)"
                            : "var(--arabic-heading-h4-font-size)",
                      fontStyle:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-heading-h4-font-style)"
                          : screenWidth < 834
                            ? "var(--arabic-heading-h5-font-style)"
                            : "var(--arabic-heading-h4-font-style)",
                      fontWeight:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-heading-h4-font-weight)"
                          : screenWidth < 834
                            ? "var(--arabic-heading-h5-font-weight)"
                            : "var(--arabic-heading-h4-font-weight)",
                      letterSpacing:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth < 834
                            ? "var(--arabic-heading-h5-letter-spacing)"
                            : "var(--arabic-heading-h4-letter-spacing)",
                      lineHeight:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-heading-h4-line-height)"
                          : screenWidth < 834
                            ? "var(--arabic-heading-h5-line-height)"
                            : "var(--arabic-heading-h4-line-height)",
                    }}
                  >
                    {postThree?.Posts[1]?.title[`${locale}`]}
                  </div>
                  <p
                    className="element-3"
                    style={{
                      fontFamily:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-text-large-medium-font-family)"
                          : screenWidth < 834
                            ? "var(--arabic-text-regular-medium-font-family)"
                            : "var(--arabic-text-large-medium-font-family)",
                      fontSize:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-text-large-medium-font-size)"
                          : screenWidth < 834
                            ? "var(--arabic-text-regular-medium-font-size)"
                            : "var(--arabic-text-large-medium-font-size)",
                      fontStyle:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-text-large-medium-font-style)"
                          : screenWidth < 834
                            ? "var(--arabic-text-regular-medium-font-style)"
                            : "var(--arabic-text-large-medium-font-style)",
                      fontWeight:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-text-large-medium-font-weight)"
                          : screenWidth < 834
                            ? "var(--arabic-text-regular-medium-font-weight)"
                            : "var(--arabic-text-large-medium-font-weight)",
                      letterSpacing:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-text-large-medium-letter-spacing)"
                          : screenWidth < 834
                            ? "var(--arabic-text-regular-medium-letter-spacing)"
                            : "var(--arabic-text-large-medium-letter-spacing)",
                      lineHeight:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-text-large-medium-line-height)"
                          : screenWidth < 834
                            ? "var(--arabic-text-regular-medium-line-height)"
                            : "var(--arabic-text-large-medium-line-height)",
                    }}
                  >
                    {postThree?.Posts[1]?.description[`${locale}`]}

                  </p>
                </div>
              </div>
            </div>
            <div className="frame-42">
              <div
                className="frame-45"
                style={{
                  padding:
                    screenWidth >= 834 && screenWidth < 1600
                      ? "55px 46px"
                      : screenWidth < 834
                        ? "55px 18px"
                        : "55px 46px",
                }}
              >
                <div className="frame-44">
                  <Eye
                    className={`${screenWidth >= 834 && screenWidth < 1600 && "instance-node-5"} ${screenWidth < 834 && "class-24"
                      }`}
                    format="stroke"
                    formatStrokeWeight={
                      screenWidth >= 834 && screenWidth < 1600
                        ? "/img/eye-1.png"
                        : screenWidth < 834
                          ? "/img/eye-1.png"
                          : "/img/eye-1.png"
                    }
                    weight="duotone"
                  />
                  <div
                    className="text-wrapper-39"
                    style={{
                      fontFamily:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-heading-h4-font-family)"
                          : screenWidth < 834
                            ? "var(--arabic-heading-h5-font-family)"
                            : "var(--arabic-heading-h4-font-family)",
                      fontSize:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-heading-h4-font-size)"
                          : screenWidth < 834
                            ? "var(--arabic-heading-h5-font-size)"
                            : "var(--arabic-heading-h4-font-size)",
                      fontStyle:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-heading-h4-font-style)"
                          : screenWidth < 834
                            ? "var(--arabic-heading-h5-font-style)"
                            : "var(--arabic-heading-h4-font-style)",
                      fontWeight:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-heading-h4-font-weight)"
                          : screenWidth < 834
                            ? "var(--arabic-heading-h5-font-weight)"
                            : "var(--arabic-heading-h4-font-weight)",
                      letterSpacing:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-heading-h4-letter-spacing)"
                          : screenWidth < 834
                            ? "var(--arabic-heading-h5-letter-spacing)"
                            : "var(--arabic-heading-h4-letter-spacing)",
                      lineHeight:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-heading-h4-line-height)"
                          : screenWidth < 834
                            ? "var(--arabic-heading-h5-line-height)"
                            : "var(--arabic-heading-h4-line-height)",
                    }}
                  >
                    {postThree?.Posts[0]?.title[`${locale}`]}
                  </div>
                  <p
                    className="text-wrapper-40"
                    style={{
                      fontFamily:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-text-large-medium-font-family)"
                          : screenWidth < 834
                            ? "var(--arabic-text-regular-medium-font-family)"
                            : "var(--arabic-text-large-medium-font-family)",
                      fontSize:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-text-large-medium-font-size)"
                          : screenWidth < 834
                            ? "var(--arabic-text-regular-medium-font-size)"
                            : "var(--arabic-text-large-medium-font-size)",
                      fontStyle:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-text-large-medium-font-style)"
                          : screenWidth < 834
                            ? "var(--arabic-text-regular-medium-font-style)"
                            : "var(--arabic-text-large-medium-font-style)",
                      fontWeight:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-text-large-medium-font-weight)"
                          : screenWidth < 834
                            ? "var(--arabic-text-regular-medium-font-weight)"
                            : "var(--arabic-text-large-medium-font-weight)",
                      letterSpacing:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-text-large-medium-letter-spacing)"
                          : screenWidth < 834
                            ? "var(--arabic-text-regular-medium-letter-spacing)"
                            : "var(--arabic-text-large-medium-letter-spacing)",
                      lineHeight:
                        screenWidth >= 834 && screenWidth < 1600
                          ? "var(--arabic-text-large-medium-line-height)"
                          : screenWidth < 834
                            ? "var(--arabic-text-regular-medium-line-height)"
                            : "var(--arabic-text-large-medium-line-height)",
                    }}
                  >
                    {postThree?.Posts[0]?.description[`${locale}`]}

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="frame-46 flex-col"
          id="team"
        >
          <div
            className={screenWidth < 2000 ? "text-wrapper-41" : "text-wrapper-41"}
          >
            {t("centerTeam")}
          </div>
          <div className={screenWidth < 2000 ? "grid grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1  gap-12  px-[5%] py-[2%] w-full" : "grid grid-cols-4 gap-12  px-[5%] py-[2%] w-full"}>
            {team?.data?.map((person) => {
              return <TeamCard className="team-card-instance" image={person?.attachment[0]?.original} name={person.name[`${locale}`]} description={person.description[`${locale}`]} />
            })}


          </div>
        </div>



        {/* {screenWidth < 834 && <Mobile className="instance-node-2" />}

      {screenWidth >= 834 && screenWidth < 1600 && <Tablets className="instance-node-2" logo="/img/logo-8.svg" />} */}

        {((screenWidth >= 834 && screenWidth < 1600) || screenWidth < 834) && (
          <>
            {/* <div
            className="overlap-group-2"
            style={{

            }}
          >
            {screenWidth < 834 && (
              <>
                <Footer className="footer-instance" property1="mobile" />
                <div className="frame-17">
                  <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-18-1.png" />
                  <div className="frame-18">
                    <div className="frame-19">
                      <div className="text-wrapper-16">النشرة الإخبارية</div>
                      <p className="text-wrapper-17">
                        مهتم بمعرفة آخر المستجدات عن مركز الابتكار السعودي لتقنيات المياه؟ اشترك الآن بالنشرة الإخبارية
                        ليصلك كل جديد!
                      </p>
                    </div>
                    <div className="frame-20">
                      <div className="frame-21">
                        <div className="text-wrapper-18">اشتراك</div>
                        <PaperPlaneTilt
                          className="paper-plane-tilt-2"
                          format="stroke"
                          formatOutline="/img/paperplanetilt-2-2x.png"
                          weight="fill"
                        />
                      </div>
                      <div className="text-wrapper-19">ادخل بريدك الإلكتروني</div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {screenWidth >= 834 && screenWidth < 1600 && (
              <>
                <footer className="footer-2">
                  <div className="container-21">
                    <div className="container-22">
                      <div className="container-23">
                        <div className="vision-logo-dark-png-wrapper">
                          <div className="vision-logo-dark-png-2" />
                        </div>
                        <img className="line-3" alt="Line" src="/img/line-1-1.png" />
                        <img className="logo-4" alt="Logo" src="/img/logo-4.png" />
                      </div>
                      <div className="container-24">
                        <p className="text-wrapper-20">{t("connectWithUsOnSocialMedia")}</p>
                        <div className="container-25">
                          <FacebookLogo
                            className="instance-node-3"
                            format="stroke"
                            formatStrokeWeight="/img/facebooklogo.png"
                            weight="regular"
                          />
                          <LinkedinLogo
                            className="instance-node-3"
                            format="stroke"
                            formatStrokeWeight="/img/linkedinlogo.png"
                            weight="regular"
                          />
                          <YoutubeLogo
                            className="instance-node-3"
                            format="stroke"
                            formatStrokeWeight="/img/youtubelogo.png"
                            weight="regular"
                          />
                          <XLogo
                            className="instance-node-3"
                            format="stroke"
                            formatStrokeWeight="/img/xlogo.png"
                            weight="regular"
                          />
                          <InstagramLogo
                            className="instance-node-3"
                            format="stroke"
                            formatStrokeWeight="/img/instagramlogo.png"
                            weight="regular"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="container-26">
                      *<div className="container-27">
                        <div className="margin-2">
                          <div className="container-28">
                            <div className="heading-4">
                              <div className="text-wrapper-21">شبكة تقنيات المياه</div>
                            </div>
                          </div>
                        </div>
                        <div className="div-5">
                          <div className="list-5">
                            <div className="link-wrapper">
                              <div className="link-2">
                                <TextHover className="text-hover-15" divClassName="text-hover-16" text="التقارير" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="div-5">
                          <div className="list-5">
                            <div className="link-wrapper">
                              <div className="link-2">
                                <TextHover className="text-hover-15" divClassName="text-hover-17" text="الفعاليات" />
                              </div>
                            </div>
                          </div>
                          <div className="list-5">
                            <div className="link-wrapper">
                              <div className="link-2">
                                <TextHover className="text-hover-15" divClassName="text-hover-18" text="الأخبار" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-27">
                        <div className="margin-3">
                          <div className="container-28">
                            <div className="heading-5">
                              <div className="text-wrapper-21">الخدمات</div>
                            </div>
                          </div>
                        </div>
                        <div className="div-5">
                          <div className="list-5">
                            <div className="link-wrapper">
                              <div className="link-3">
                                <TextHover
                                  className="text-hover-15"
                                  divClassName="text-hover-19"
                                  text="لا يوجد محتوى"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-27">
                        <div className="margin-3">
                          <div className="container-28">
                            <div className="heading-5">
                              <div className="text-wrapper-21">الخدمات</div>
                            </div>
                          </div>
                        </div>
                        <div className="div-5">
                          <div className="list-5">
                            <div className="link-wrapper">
                              <div className="link-3">
                                <TextHover
                                  className="text-hover-15"
                                  divClassName="text-hover-19"
                                  text="لا يوجد محتوى"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-29">
                        <div className="margin-3">
                          <div className="container-28">
                            <div className="heading-5">
                                <div className="text-wrapper-21">الحاضنة</div>
                                
                            </div>
                          </div>
                        </div>
                        <div className="container-30">
                          <div className="list-6">
                            <div className="item-6">
                              <TextHover className="text-hover-20" divClassName="text-hover-21" text="حاضنة المياه" />
                            </div>
                            <div className="item-6">
                              <TextHover className="text-hover-20" divClassName="text-hover-22" text="المراحل" />
                            </div>
                            <div className="item-7">
                              <TextHover
                                className="text-hover-20"
                                divClassName="text-hover-23"
                                text="متطلبات التسجيل"
                              />
                            </div>
                            <div className="item-7">
                              <TextHover
                                className="text-hover-20"
                                divClassName="text-hover-24"
                                text="المحتضنين الحاليين"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-31">
                        <div className="margin-3">
                          <div className="container-32">
                            <div className="heading-6">
                              <div className="div-5">
                          <div className="div-5">
                            <div className="item-link-2">
                              <TextHover className="text-hover-20" divClassName="text-hover-25" text="نبذة عن المركز" />
                            </div>
                            <div className="item-link-2">
                              <TextHover className="text-hover-20" divClassName="text-hover-26" text="أهداف المركز" />
                            </div>
                            <div className="item-link-2">
                              <TextHover
                                className="text-hover-20"
                                divClassName="text-hover-18"
                                text="الرؤية والراسلة"
                              />
                            </div>
                            <div className="item-link-2">
                              <TextHover className="text-hover-20" divClassName="text-hover-27" text="فريق المركز" />
                            </div>
                          </div>
                        </div>
                            </div>
                          </div>
                        </div>
                      
                      </div>
                    </div>
                    <img className="line-4" alt="Line" src="/img/line-2-1.png" />
                    <div className="container-33">
                      <div className="frame-22">
                        <Copyright
                          className="instance-node-4"
                          format="stroke"
                          formatStrokeWeight="/img/copyright-1.png"
                          weight="regular"
                        />
                        <p className="element-2">
                          جميع الحقوق محفوظة لمركز الابتكار السعودي لتقنية المياه&nbsp;&nbsp;2024
                        </p>
                      </div>
                      <div className="container-34">
                        <div className="text-wrapper-23">شروط الاستخدام</div>
                        <div className="text-wrapper-24">سياسة الخصوصية</div>
                      </div>
                    </div>
                  </div>
                </footer>
                <div className="frame-23">
                  <img className="rectangle-4" alt="Rectangle" src="/img/rectangle-18-2.png" />
                  <div className="frame-24">
                    <div className="frame-25">
                      <div className="text-wrapper-16">النشرة الإخبارية</div>
                      <p className="text-wrapper-25">
                        مهتم بمعرفة آخر المستجدات عن مركز الابتكار السعودي لتقنيات المياه؟ اشترك الآن بالنشرة الإخبارية
                        ليصلك كل جديد!
                      </p>
                    </div>
                    <div className="frame-26">
                      <div className="frame-27">
                        <div className="text-wrapper-26">اشتراك</div>
                        <PaperPlaneTilt
                          className="instance-node-4"
                          format="stroke"
                          formatOutline="/img/paperplanetilt-4.png"
                          weight="fill"
                        />
                      </div>
                      <div className="text-wrapper-27">ادخل بريدك الإلكتروني</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div> */}





          </>
        )}

        {/* {screenWidth >= 1600 && (
        <>
          <div className="container-38">
            <div className="container-39">
              <div className="container-37">
                <div className="text-wrapper-42">عن المركز</div>
                <p className="text-wrapper-29">مركز الابتكار السعودي لتقنيات المياه</p>
              </div>
            </div>
          </div>
          <div className="overlap">
            <Footer className="footer-3" logo="/img/logo-9.png" property1="web" />
            <Newsletter
              className="newsletter-instance"
              clipPathGroup="/img/image.png"
              paperPlaneTiltFormatOutline="/img/paperplanetilt-1.png"
            />
          </div>
          <div className="frame-47">
            <div className="frame-48">
              <div className="text-wrapper-43">عن المركز</div>
              <div className="text-wrapper-44">/</div>
              <div className="frame-29">
                <div className="item-link-home-2">الرئيسية</div>
                <House className="instance-node-4" format="stroke" formatOutline="/img/house.png" weight="regular" />
              </div>
            </div>
            <div className="frame-49">
              <div className="text-wrapper-45">عن المركز</div>
              <div className="frame-50">
                <div className="frame-51">
                  <img className="image-2" alt="Image" src="/img/image-4.png" />
                  <div className="frame-52">
                    <div className="text-wrapper-46">نبذة عن المركز</div>
                    <p className="text-wrapper-47">
                      أنشئ مركز الابتكار السعودي لتقنيات المياه لتفعيل منظومة الابتكار وريادة الأعمال في تقنيات المياه
                      من خلال تطوير نظام ريادي يقوم على تعزيز البنية التحتية والمساعدة في خلق وتطوير الشركات الناشئة في
                      قطاع المياه .
                    </p>
                  </div>
                </div>
                <div className="frame-53">
                  <div className="text-wrapper-48">أهداف المركز</div>
                  <div className="frame-54">
                    <div className="frame-55">
                      <div className="img-wrapper">
                        <img className="img-2" alt="Personal development" src="/img/personal-development-1.png" />
                      </div>
                      <div className="frame-35">
                        <div className="text-wrapper-49">تحسين الفرص</div>
                        <p className="text-wrapper-50">خلق بيئة لريادة الأعمال في قطاعات المياه</p>
                      </div>
                    </div>
                    <div className="frame-56">
                      <div className="img-wrapper">
                        <img className="img-2" alt="Leadership" src="/img/leadership-1.png" />
                      </div>
                      <div className="frame-35">
                        <div className="text-wrapper-49">تطوير القطاع</div>
                        <p className="text-wrapper-50">خلق بيئة لريادة الأعمال في قطاعات المياه</p>
                      </div>
                    </div>
                    <div className="frame-57">
                      <div className="img-wrapper">
                        <img className="img-2" alt="Goal" src="/img/goal-1.png" />
                      </div>
                      <div className="frame-35">
                        <div className="text-wrapper-49">التطوير المحلي</div>
                        <p className="text-wrapper-50">زيادة المحتوى المحلي وتوطين الصناعة في قطاع المياه</p>
                      </div>
                    </div>
                    <div className="frame-58">
                      <div className="img-wrapper">
                        <img className="img-2" alt="Enterprise" src="/img/enterprise-1.png" />
                      </div>
                      <div className="frame-35">
                        <div className="text-wrapper-49">دعم الشركات</div>
                        <p className="text-wrapper-50">تمكين المنشآت الصغيرة والمتوسطة والمبتكرين</p>
                      </div>
                    </div>
                    <div className="frame-59">
                      <div className="img-wrapper">
                        <img className="img-2" alt="Profit" src="/img/profit-1.png" />
                      </div>
                      <div className="frame-35">
                        <div className="text-wrapper-49">فرص الاستثمار</div>
                        <div className="text-wrapper-50">توليد وإيجاد فرص استثمارية</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-60">
                  <div className="text-wrapper-51">الرؤية والرسالة</div>
                  <div className="frame-61">
                    <div className="frame-62">
                      <div className="frame-63">
                        <div className="frame-44">
                          <Target
                            className="instance-node-5"
                            format="stroke"
                            formatStrokeWeight="/img/target-1.png"
                            weight="duotone"
                          />
                          <div className="text-wrapper-52">الرسالة</div>
                          <p className="text-wrapper-53">
                            تسخير المعرفة لدعم الابتكارات في مجال تقنيات المياه ومواكبة التحولات التقنية
                            والتنموية&nbsp;&nbsp;لتحقيق أهداف رؤية 2030، بالإضافة إلى تقديم حلول متكاملة و استشارية
                            لتطوير الأعمال وتنميتها.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="frame-62">
                      <div className="frame-63">
                        <div className="frame-44">
                          <Eye
                            className="instance-node-5"
                            format="stroke"
                            formatStrokeWeight="/img/eye-1.png"
                            weight="duotone"
                          />
                          <div className="text-wrapper-52">الرؤية</div>
                          <p className="text-wrapper-53">
                            تعزيز البنية التحتية للابتكار في قطاع المياه ودعم الشركات الناشئة لزيادة فعاليتها وتوسعها
                            وتوطين قطاع تقنيات المياه لزيادة الناتج المحلي وتوفير فرص عمل ذات جودة عالية للسواعد الشابة.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-60">
                  <div className="text-wrapper-51">فريق المركز</div>
                  <div className="frame-64">
                    <TeamCard className="team-card-2" />
                    <TeamCard className="team-card-3" />
                    <TeamCard className="team-card-4" />
                    <TeamCard className="team-card-5" />
                    <TeamCard className="team-card-6" />
                    <TeamCard className="team-card-7" />
                    <TeamCard className="team-card-8" />
                    <TeamCard className="team-card-9" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Navbar className="navbar-instance" logo="/img/logo-10-2x.png" />
        </>
      )} */}
      </div>
      <div className={screenWidth < 2000 ? "mt-[100px] lg:mt-[300px]" : "mt-[750px]"}></div>
      <Footer className="footer-3" logo="/img/logo-9.png" property1="web" />
    </>);
};
