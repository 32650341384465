/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { FormatOutlineWeightBold1 } from "../../icons/FormatOutlineWeightBold1";
import { FormatOutlineWeightDuotone1 } from "../../icons/FormatOutlineWeightDuotone1";
import { FormatOutlineWeightFill1 } from "../../icons/FormatOutlineWeightFill1";
import { FormatOutlineWeightLight1 } from "../../icons/FormatOutlineWeightLight1";
import { FormatOutlineWeightRegular1 } from "../../icons/FormatOutlineWeightRegular1";
import { FormatOutlineWeightThin1 } from "../../icons/FormatOutlineWeightThin1";
import { FormatStrokeWeightBold1 } from "../../icons/FormatStrokeWeightBold1";
import { FormatStrokeWeightDuotone1 } from "../../icons/FormatStrokeWeightDuotone1";
import { FormatStrokeWeightLight1 } from "../../icons/FormatStrokeWeightLight1";
import { FormatStrokeWeightRegular1 } from "../../icons/FormatStrokeWeightRegular1";
import { FormatStrokeWeightThin1 } from "../../icons/FormatStrokeWeightThin1";
import "./style.css";

export const User = ({ format, weight }) => {
  return (
    <>
      {weight === "regular" && format === "outline" && <FormatOutlineWeightRegular1 className="instance-node-10" />}

      {format === "outline" && weight === "thin" && <FormatOutlineWeightThin1 className="instance-node-10" />}

      {weight === "light" && format === "outline" && <FormatOutlineWeightLight1 className="instance-node-10" />}

      {format === "outline" && weight === "bold" && <FormatOutlineWeightBold1 className="instance-node-10" />}

      {weight === "fill" && <FormatOutlineWeightFill1 className="instance-node-10" />}

      {format === "outline" && weight === "duotone" && <FormatOutlineWeightDuotone1 className="instance-node-10" />}

      {weight === "regular" && format === "stroke" && <FormatStrokeWeightRegular1 className="instance-node-10" />}

      {weight === "thin" && format === "stroke" && <FormatStrokeWeightThin1 className="instance-node-10" />}

      {weight === "light" && format === "stroke" && <FormatStrokeWeightLight1 className="instance-node-10" />}

      {weight === "bold" && format === "stroke" && <FormatStrokeWeightBold1 className="instance-node-10" />}

      {weight === "duotone" && format === "stroke" && <FormatStrokeWeightDuotone1 className="instance-node-10" />}
    </>
  );
};

User.propTypes = {
  format: PropTypes.oneOf(["outline", "stroke"]),
  weight: PropTypes.oneOf(["duotone", "regular", "thin", "bold", "fill", "light"]),
};
