/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatStrokeWeightThin11 = ({ className }) => {
  return (
    <svg
      className={`format-stroke-weight-thin-11 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M27 4H5C4.44772 4 4 4.44772 4 5V27C4 27.5523 4.44772 28 5 28H27C27.5523 28 28 27.5523 28 27V5C28 4.44772 27.5523 4 27 4Z"
        stroke="#343330"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path className="path" d="M15 14V22" stroke="#343330" strokeLinecap="round" strokeLinejoin="round" />
      <path className="path" d="M11 14V22" stroke="#343330" strokeLinecap="round" strokeLinejoin="round" />
      <path
        className="path"
        d="M15 17.5C15 16.5717 15.3687 15.6815 16.0251 15.0251C16.6815 14.3687 17.5717 14 18.5 14C19.4283 14 20.3185 14.3687 20.9749 15.0251C21.6313 15.6815 22 16.5717 22 17.5V22"
        stroke="#343330"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="path"
        d="M11 11.5C11.5523 11.5 12 11.0523 12 10.5C12 9.94772 11.5523 9.5 11 9.5C10.4477 9.5 10 9.94772 10 10.5C10 11.0523 10.4477 11.5 11 11.5Z"
        fill="#343330"
      />
    </svg>
  );
};
