/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Copyright = ({
  format,
  weight,
  className,
  formatStrokeWeight = "/img/format-stroke-weight-regular-5.png",
}) => {
  return (
    <img
      className={`copyright ${className}`}
      alt="Format outline"
      src={
        format === "outline" && weight === "thin"
          ? "/img/format-outline-weight-thin-5.png"
          : weight === "light" && format === "outline"
          ? "/img/format-outline-weight-light-5.png"
          : format === "outline" && weight === "bold"
          ? "/img/format-outline-weight-bold-5.png"
          : weight === "fill"
          ? "/img/format-outline-weight-fill-5.png"
          : format === "outline" && weight === "duotone"
          ? "/img/format-outline-weight-duotone-5.png"
          : weight === "regular" && format === "stroke"
          ? formatStrokeWeight
          : weight === "thin" && format === "stroke"
          ? "/img/format-stroke-weight-thin-5.png"
          : weight === "light" && format === "stroke"
          ? "/img/format-stroke-weight-light-5.png"
          : weight === "bold" && format === "stroke"
          ? "/img/format-stroke-weight-bold-5.png"
          : weight === "duotone" && format === "stroke"
          ? "/img/format-stroke-weight-duotone-5.png"
          : "/img/format-outline-weight-regular-5.png"
      }
    />
  );
};

Copyright.propTypes = {
  format: PropTypes.oneOf(["outline", "stroke"]),
  weight: PropTypes.oneOf(["duotone", "regular", "thin", "bold", "fill", "light"]),
  formatStrokeWeight: PropTypes.string,
};
