/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatOutlineWeightLight1 = ({ className }) => {
  return (
    <svg
      className={`format-outline-weight-light-1 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M28.6487 26.625C26.6724 23.21 23.5699 20.8138 19.9649 19.7975C21.7176 18.9066 23.1192 17.4513 23.9436 15.6664C24.768 13.8814 24.9672 11.8708 24.5092 9.95882C24.0511 8.04679 22.9624 6.34477 21.4186 5.12718C19.8749 3.9096 17.9661 3.24742 15.9999 3.24742C14.0338 3.24742 12.125 3.9096 10.5812 5.12718C9.03749 6.34477 7.94881 8.04679 7.49074 9.95882C7.03266 11.8708 7.23189 13.8814 8.05631 15.6664C8.88072 17.4513 10.2823 18.9066 12.0349 19.7975C8.42994 20.8125 5.32744 23.2087 3.35119 26.625C3.29693 26.7104 3.26049 26.8058 3.24407 26.9056C3.22766 27.0054 3.2316 27.1075 3.25567 27.2058C3.27973 27.304 3.32342 27.3964 3.38411 27.4773C3.44479 27.5583 3.52122 27.6261 3.6088 27.6767C3.69637 27.7273 3.79328 27.7597 3.89369 27.7719C3.99411 27.7841 4.09595 27.7758 4.1931 27.7477C4.29024 27.7195 4.38068 27.6719 4.45898 27.6079C4.53728 27.5438 4.60181 27.4646 4.64869 27.375C7.04994 23.2262 11.2924 20.75 15.9999 20.75C20.7074 20.75 24.9499 23.2262 27.3512 27.375C27.3981 27.4646 27.4626 27.5438 27.5409 27.6079C27.6192 27.6719 27.7096 27.7195 27.8068 27.7477C27.9039 27.7758 28.0058 27.7841 28.1062 27.7719C28.2066 27.7597 28.3035 27.7273 28.3911 27.6767C28.4787 27.6261 28.5551 27.5583 28.6158 27.4773C28.6765 27.3964 28.7202 27.304 28.7442 27.2058C28.7683 27.1075 28.7722 27.0054 28.7558 26.9056C28.7394 26.8058 28.703 26.7104 28.6487 26.625ZM8.74994 12C8.74994 10.5661 9.17515 9.16437 9.97179 7.97212C10.7684 6.77986 11.9007 5.85061 13.2255 5.30187C14.5503 4.75314 16.008 4.60956 17.4143 4.88931C18.8207 5.16905 20.1125 5.85954 21.1265 6.87348C22.1404 7.88741 22.8309 9.17923 23.1106 10.5856C23.3904 11.992 23.2468 13.4497 22.6981 14.7745C22.1493 16.0992 21.2201 17.2315 20.0278 18.0282C18.8356 18.8248 17.4339 19.25 15.9999 19.25C14.0778 19.2477 12.2351 18.4831 10.876 17.124C9.51684 15.7648 8.75226 13.9221 8.74994 12Z"
        fill="#343330"
      />
    </svg>
  );
};
