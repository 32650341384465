/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { FormatOutlineWeightBold8 } from "../../icons/FormatOutlineWeightBold8";
import { FormatOutlineWeightDuotone8 } from "../../icons/FormatOutlineWeightDuotone8";
import { FormatOutlineWeightFill8 } from "../../icons/FormatOutlineWeightFill8";
import { FormatOutlineWeightLight8 } from "../../icons/FormatOutlineWeightLight8";
import { FormatOutlineWeightRegular8 } from "../../icons/FormatOutlineWeightRegular8";
import { FormatOutlineWeightThin8 } from "../../icons/FormatOutlineWeightThin8";
import { FormatStrokeWeightBold9 } from "../../icons/FormatStrokeWeightBold9";
import { FormatStrokeWeightDuotone9 } from "../../icons/FormatStrokeWeightDuotone9";
import { FormatStrokeWeightLight9 } from "../../icons/FormatStrokeWeightLight9";
import { FormatStrokeWeightRegular9 } from "../../icons/FormatStrokeWeightRegular9";
import { FormatStrokeWeightThin9 } from "../../icons/FormatStrokeWeightThin9";
import "./style.css";

export const XLogo = ({ format, weight }) => {
  return (
    <>
      {weight === "regular" && format === "outline" && <FormatOutlineWeightRegular8 className="instance-node-5" />}

      {format === "outline" && weight === "thin" && <FormatOutlineWeightThin8 className="instance-node-5" />}

      {weight === "light" && format === "outline" && <FormatOutlineWeightLight8 className="instance-node-5" />}

      {format === "outline" && weight === "bold" && <FormatOutlineWeightBold8 className="instance-node-5" />}

      {weight === "fill" && <FormatOutlineWeightFill8 className="instance-node-5" />}

      {format === "outline" && weight === "duotone" && <FormatOutlineWeightDuotone8 className="instance-node-5" />}

      {weight === "regular" && format === "stroke" && <FormatStrokeWeightRegular9 className="instance-node-5" />}

      {weight === "thin" && format === "stroke" && <FormatStrokeWeightThin9 className="instance-node-5" />}

      {weight === "light" && format === "stroke" && <FormatStrokeWeightLight9 className="instance-node-5" />}

      {weight === "bold" && format === "stroke" && <FormatStrokeWeightBold9 className="instance-node-5" />}

      {weight === "duotone" && format === "stroke" && <FormatStrokeWeightDuotone9 className="instance-node-5" />}
    </>
  );
};

XLogo.propTypes = {
  format: PropTypes.oneOf(["outline", "stroke"]),
  weight: PropTypes.oneOf(["duotone", "regular", "thin", "bold", "fill", "light"]),
};
