/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { List } from "../../components/List";

export const FormatStrokeWeightLight1 = ({ className }) => {
  return (

    <>
    <div className="flex justify-center items-center w-[25px] ">
              <List
                      className={`${className} hidden md:block`}
                      format="stroke"
                      formatOutline="https://cdn.animaapp.com/projects/6641f8f0ac4f4bd7ada3d6f3/releases/664359750d6b76e08d71d2d3/img/list@2x.png"
                      weight="regular"
          />




    </div>
    
    </>
  );
};
