/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatOutlineWeightThin1 = ({ className }) => {
  return (
    <svg
      className={`format-outline-weight-thin-1 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M28.4325 26.75C26.3675 23.18 23.0575 20.7425 19.2225 19.8638C21.0448 19.1171 22.5513 17.7609 23.4847 16.0268C24.418 14.2927 24.7203 12.2883 24.3398 10.3561C23.9593 8.42387 22.9197 6.6837 21.3986 5.4329C19.8775 4.1821 17.9693 3.49829 16 3.49829C14.0306 3.49829 12.1224 4.1821 10.6013 5.4329C9.08017 6.6837 8.04057 8.42387 7.6601 10.3561C7.27963 12.2883 7.58189 14.2927 8.51525 16.0268C9.4486 17.7609 10.9551 19.1171 12.7775 19.8638C8.94746 20.7388 5.63246 23.18 3.56746 26.75C3.50768 26.8643 3.49437 26.9972 3.5303 27.1211C3.56623 27.2449 3.64863 27.3501 3.76028 27.4146C3.87193 27.4792 4.0042 27.4981 4.12945 27.4674C4.25471 27.4367 4.36327 27.3588 4.43246 27.25C6.87496 23.0238 11.2025 20.5 16 20.5C20.7975 20.5 25.125 23.0238 27.5675 27.25C27.6113 27.3259 27.6743 27.389 27.7502 27.4329C27.8262 27.4768 27.9123 27.4999 28 27.5C28.0878 27.5003 28.1742 27.477 28.25 27.4325C28.3646 27.3661 28.4482 27.257 28.4824 27.129C28.5166 27.001 28.4986 26.8647 28.4325 26.75ZM8.49996 12C8.49996 10.5166 8.93982 9.06659 9.76393 7.83322C10.588 6.59985 11.7594 5.63856 13.1298 5.0709C14.5003 4.50325 16.0083 4.35472 17.4631 4.64411C18.918 4.9335 20.2544 5.6478 21.3033 6.6967C22.3521 7.74559 23.0665 9.08196 23.3558 10.5368C23.6452 11.9917 23.4967 13.4997 22.9291 14.8701C22.3614 16.2406 21.4001 17.4119 20.1667 18.236C18.9334 19.0601 17.4833 19.5 16 19.5C14.0115 19.4977 12.1052 18.7068 10.6992 17.3007C9.29319 15.8947 8.50227 13.9884 8.49996 12Z"
        fill="#343330"
      />
    </svg>
  );
};
