/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";
import { useWindowWidth } from "../../breakpoints";

export const TeamCard = ({ className,image,name,description }) => {
  const screenWidth = useWindowWidth();

  return (
    <div className={`team-card ${className}`}>
      <img className="rectangle" alt="Rectangle" src={image} />
      <div className="frame-wrapper">
        <div className="frame-3">
          <div className={screenWidth<2000?"text-wrapper-5":"text-wrapper-5"}>{name}</div>
          <p className={screenWidth<2000?"text-wrapper-6":"text-wrapper-6"}>{description}</p>
        </div>
      </div>
    </div>
  );
};
