/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatOutlineWeightLight = ({ className }) => {
  return (
    <svg
      className={`format-outline-weight-light ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M26.53 12.5298L16.53 22.5298C16.3894 22.6703 16.1988 22.7492 16 22.7492C15.8013 22.7492 15.6107 22.6703 15.47 22.5298L5.47003 12.5298C5.33755 12.3877 5.26543 12.1996 5.26885 12.0053C5.27228 11.811 5.35099 11.6256 5.48841 11.4882C5.62582 11.3508 5.81121 11.2721 6.00551 11.2687C6.19981 11.2652 6.38785 11.3374 6.53003 11.4698L16 20.9386L25.47 11.4698C25.6122 11.3374 25.8003 11.2652 25.9946 11.2687C26.1889 11.2721 26.3742 11.3508 26.5117 11.4882C26.6491 11.6256 26.7278 11.811 26.7312 12.0053C26.7346 12.1996 26.6625 12.3877 26.53 12.5298Z"
        fill="#343330"
      />
    </svg>
  );
};
