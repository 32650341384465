import axios from "axios";
import { useState, useEffect } from "react";
let baseURL="https://api.swic.sa/api"

export function usePage(param) {
  let [page,setPage]=useState([])

  console.log(location.pathname.split("/")[1]);


    useEffect(() => {
      axios.get(`${baseURL}/pages/${param}`,{headers:{
        "Accept":"application/json",
        "Accept-Language":location.pathname.split("/")[1],
      }})
        .then(function (response) {
          setPage(response.data)
        })
        .catch(function (error) {
        });
    }, []);

  return page;
}
export function usePartners() {
  let [page,setPage]=useState([])


    useEffect(() => {
      axios.get(`${baseURL}/partners`,{headers:{
        "Accept":"application/json",
        "Accept-Language":location.pathname.split("/")[1],
      }})
        .then(function (response) {
          setPage(response.data)
          
        })
        .catch(function (error) {
        });
    }, []);

  return page;
}
export function useIncubatores() {
  let [page,setPage]=useState([])


    useEffect(() => {
      axios.get(`${baseURL}/incubators`,{headers:{
        "Accept":"application/json",
        "Accept-Language":location.pathname.split("/")[1],
      }})
        .then(function (response) {
          setPage(response.data)
          
        })
        .catch(function (error) {
        });
    }, []);

  return page;
}
export function useReports() {
  let [page,setPage]=useState([])


    useEffect(() => {
      axios.get(`${baseURL}/reports`,{headers:{
        "Accept":"application/json",
        "Accept-Language":location.pathname.split("/")[1],
      }})
        .then(function (response) {
          setPage(response.data)
          
        })
        .catch(function (error) {
        });
    }, []);

  return page;
}
export function useSpacificReports(param) {
  let [page,setPage]=useState([])


    useEffect(() => {
      axios.get(`${baseURL}/reports/${param}`,{headers:{
        "Accept":"application/json",
        "Accept-Language":location.pathname.split("/")[1],
      }})
        .then(function (response) {
          setPage(response.data)
          
        })
        .catch(function (error) {
        });
    }, []);

  return page;
}
export function useTeam() {
  let [page,setPage]=useState([])


    useEffect(() => {
      axios.get(`${baseURL}/teams`,{headers:{
        "Accept":"application/json",
        "Accept-Language":location.pathname.split("/")[1],
      }})
        .then(function (response) {
          setPage(response.data)
          
        })
        .catch(function (error) {
        });
    }, []);

  return page;
}
export function useIndividualsRegister(data) {
    useEffect(() => {
      axios.post(`${baseURL}/CreateUser`,{data:data},{headers:{
        "Accept":"application/json",
        "Accept-Language":location.pathname.split("/")[1],
      }})
        .then(function (response) {
          // setPage(response.data)
          
        })
        .catch(function (error) {
        });
    }, []);
}
