import React, { createContext, useContext, useState } from 'react';
import enMessages from './en';
import arMessages from './ar';
import { useLocation } from 'react-router-dom';

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
    let location = window.location

  const [locale, setLocale] = useState(location.pathname.split("/")[1]||"ar"); // Default locale
// console.log(location);
  const messages = {
    en: enMessages,
    ar: arMessages,
  };

  const t = (key, values) => {
    const message = messages[locale][key] || key;
    return Object.keys(values || {}).reduce(
      (acc, key) => acc.replace(new RegExp(`{${key}}`, 'g'), values[key]),
      message
    );
  };

  const changeLocale = (newLocale) => {
    setLocale(newLocale);
  };

  return (
    <TranslationContext.Provider value={{ locale, t, changeLocale }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => useContext(TranslationContext);
