/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { FormatOutlineWeightBold6 } from "../../icons/FormatOutlineWeightBold6";
import { FormatOutlineWeightDuotone6 } from "../../icons/FormatOutlineWeightDuotone6";
import { FormatOutlineWeightFill6 } from "../../icons/FormatOutlineWeightFill6";
import { FormatOutlineWeightLight6 } from "../../icons/FormatOutlineWeightLight6";
import { FormatOutlineWeightRegular6 } from "../../icons/FormatOutlineWeightRegular6";
import { FormatOutlineWeightThin6 } from "../../icons/FormatOutlineWeightThin6";
import { FormatStrokeWeightBold5 } from "../../icons/FormatStrokeWeightBold5";
import { FormatStrokeWeightDuotone5 } from "../../icons/FormatStrokeWeightDuotone5";
import { FormatStrokeWeightLight5 } from "../../icons/FormatStrokeWeightLight5";
import { FormatStrokeWeightRegular5 } from "../../icons/FormatStrokeWeightRegular5";
import { FormatStrokeWeightThin5 } from "../../icons/FormatStrokeWeightThin5";
import "./style.css";

export const ConcreteComponentNode = ({ format, weight }) => {
  return (
    <>
      {weight === "regular" && format === "outline" && <FormatOutlineWeightRegular6 className="instance-node-7" />}

      {format === "outline" && weight === "thin" && <FormatOutlineWeightThin6 className="instance-node-7" />}

      {weight === "light" && format === "outline" && <FormatOutlineWeightLight6 className="instance-node-7" />}

      {format === "outline" && weight === "bold" && <FormatOutlineWeightBold6 className="instance-node-7" />}

      {weight === "fill" && <FormatOutlineWeightFill6 className="instance-node-7" />}

      {format === "outline" && weight === "duotone" && <FormatOutlineWeightDuotone6 className="instance-node-7" />}

      {weight === "regular" && format === "stroke" && <FormatStrokeWeightRegular5 className="instance-node-7" />}

      {weight === "thin" && format === "stroke" && <FormatStrokeWeightThin5 className="instance-node-7" />}

      {weight === "light" && format === "stroke" && <FormatStrokeWeightLight5 className="instance-node-7" />}

      {weight === "bold" && format === "stroke" && <FormatStrokeWeightBold5 className="instance-node-7" />}

      {weight === "duotone" && format === "stroke" && <FormatStrokeWeightDuotone5 className="instance-node-7" />}
    </>
  );
};

ConcreteComponentNode.propTypes = {
  format: PropTypes.oneOf(["outline", "stroke"]),
  weight: PropTypes.oneOf(["duotone", "regular", "thin", "bold", "fill", "light"]),
};
