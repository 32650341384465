/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { List } from "../List";
import { User } from "../User";
import "./style.css";

export const Mobile = ({ className }) => {
  return (
    <div className={`mobile ${className}`}>
      <div className="frame">
        <div className="div">
          <List
            className="design-component-instance-node"
            format="stroke"
            formatOutline="/img/list.png"
            weight="regular"
          />
          <User
            className="design-component-instance-node"
            format="stroke"
            formatStrokeWeight="/img/user.png"
            weight="regular"
          />
          <div className="text-wrapper">ENG</div>
        </div>
        <img className="logo" alt="Logo" src="/img/logo-6.png" />
      </div>
    </div>
  );
};
