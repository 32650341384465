/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const List = ({
  format,
  weight,
  className,
  formatOutline = "https://cdn.animaapp.com/projects/6641f8f0ac4f4bd7ada3d6f3/releases/664359750d6b76e08d71d2d3/img/format-outline--weight-regular-13@2x.png",
}) => {
  return (
    <img
      className={`list ${className}`}
      alt="Format outline"
      src={
        weight === "thin"
          ? "https://cdn.animaapp.com/projects/6641f8f0ac4f4bd7ada3d6f3/releases/664359750d6b76e08d71d2d3/img/format-outline--weight-thin-13@2x.png"
          : weight === "light"
          ? "https://cdn.animaapp.com/projects/6641f8f0ac4f4bd7ada3d6f3/releases/664359750d6b76e08d71d2d3/img/format-outline--weight-light-13@2x.png"
          : weight === "bold"
          ? "https://cdn.animaapp.com/projects/6641f8f0ac4f4bd7ada3d6f3/releases/664359750d6b76e08d71d2d3/img/format-outline--weight-bold-13@2x.png"
          : weight === "fill"
          ? "https://cdn.animaapp.com/projects/6641f8f0ac4f4bd7ada3d6f3/releases/664359750d6b76e08d71d2d3/img/format-outline--weight-fill-13@2x.png"
          : weight === "duotone"
          ? "https://cdn.animaapp.com/projects/6641f8f0ac4f4bd7ada3d6f3/releases/664359750d6b76e08d71d2d3/img/format-outline--weight-duotone-13@2x.png"
          : formatOutline
      }
    />
  );
};

List.propTypes = {
  format: PropTypes.oneOf(["outline", "stroke"]),
  weight: PropTypes.oneOf(["duotone", "regular", "thin", "bold", "fill", "light"]),
  formatOutline: PropTypes.string,
};
