/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Copyright } from "../../icons/Copyright";
import { Facebooklogo } from "../../icons/Facebooklogo";
import { Instagramlogo } from "../../icons/Instagramlogo";
import { Linkedinlogo } from "../../icons/Linkedinlogo";
import { Xlogo2 } from "../../icons/Xlogo2";
import { Youtubelogo } from "../../icons/Youtubelogo";
import { TextHover } from "../TextHover";
import "./style.css";
import { Newsletter } from "../Newsletter";
import { useWindowWidth } from "../../breakpoints";
import { Link } from "react-router-dom";
import { useTranslation } from "../../translations/TranslationContext";

export const Footer = ({ className, logo = "/img/logo-1.svg" }) => {
  const screenWidth = useWindowWidth();
  const { t, changeLocale, locale } = useTranslation();

  return (
    <>
      <div dir={locale == "ar" ? "rtl" : "ltr"} className="overlap">
        <div className="relative">
          <div className={`footer ${className}`}>
            <div className="container">
              <div className={screenWidth < 2000 ? "flex items-center justify-between flex-row w-[100%] gap-[32px] lg:flex-col" : "flex items-center justify-between flex-row-reverse w-[100%] gap-[32px] lg:flex-col mt-[200px]"}>
                <div className="container-3">
                  <p className={screenWidth < 2000 ? "p" : "p"}>{t("connectWithUsOnSocialMedia")}</p>
                  <div className="container-4">
                    {/* <a href="">

              <Facebooklogo className="icon-instance-node" />
              </a> */}
                    <a href="https://www.linkedin.com/company/swic-sa/" target="_blank">

                      <Linkedinlogo className={screenWidth < 2000 ? "icon-instance-node" : "icon-instance-node"} />
                    </a>
                    {/* <a href="">
              <Youtubelogo className="icon-instance-node" />

              </a> */}
                    <a href="https://twitter.com/swic_sa" target="_blank">

                      <Xlogo2 className={screenWidth < 2000 ? "icon-instance-node" : "icon-instance-node"} />
                    </a>
                    {/* <a href="">

              <Instagramlogo className="icon-instance-node" />
              </a> */}
                  </div>
                </div>
                <div className="flex justify-around items-center w-80 ">
                  <div className="link-picture">
                    <div className={screenWidth < 2000 ? "vision-logo-dark-png" : "vision-logo-dark-png"} />
                  </div>
                  <img className={screenWidth < 2000 ? "line" : "line"} alt="Line" src="/img/line-1.svg" />
                  <img className={screenWidth < 2000 ? "img" : "img"} alt="Logo" src={logo} />
                </div>
              </div>
              <div className="grid grid-cols-3 w-[100%] md:grid-cols-1  sm:grid-cols-2 sm:gap-[1.5rem] ">

                <div className="flex flex-col gap-5 ">
                  <p className={screenWidth < 2000 ? "text-wrapper-3" : "text-wrapper-3"} >{t("aboutCenter")}</p>
                  <div className="md:flex md:justify-center   md:items-center gap-[10%] sm:block">
                    <p className={screenWidth < 2000 ? "text-wrapper-6 hover:text-white hover:cursor-pointer" : "text-wrapper-6 hover:text-white hover:cursor-pointer"} ><Link to={`/${locale}/about`}>{t("aboutCenter")}</Link></p>
                    <p className={screenWidth < 2000 ? "text-wrapper-6 hover:text-white hover:cursor-pointer" : "text-wrapper-6 hover:text-white hover:cursor-pointer"} ><Link to={`/${locale}/about`}>{t("centerObjectives")}</Link></p>
                    <p className={screenWidth < 2000 ? "text-wrapper-6 hover:text-white hover:cursor-pointer" : "text-wrapper-6 hover:text-white hover:cursor-pointer"} ><Link to={`/${locale}/about`}>{t("visionMission")}</Link></p>
                    <p className={screenWidth < 2000 ? "text-wrapper-6 hover:text-white hover:cursor-pointer" : "text-wrapper-6 hover:text-white hover:cursor-pointer"} ><Link to={`/${locale}/about`}>{t("centerTeam")}</Link></p>

                  </div>
                </div>

                <div className="flex flex-col gap-5 ">
                  <p className={screenWidth < 2000 ? "text-wrapper-3" : "text-wrapper-3"}>{t("incubator")}</p>
                  <div className="md:flex md:justify-center  md:items-center gap-[10%] sm:block">

                    <p className={screenWidth < 2000 ? "text-wrapper-6 hover:text-white hover:cursor-pointer" : "text-wrapper-6 hover:text-white hover:cursor-pointer"} > <Link to={`/${locale}/incubator`}>{t("waterIncubator")}</Link> </p>
                    <p className={screenWidth < 2000 ? "text-wrapper-6 hover:text-white hover:cursor-pointer" : "text-wrapper-6 hover:text-white hover:cursor-pointer"} > <Link to={`/${locale}/incubator`}>{t("stages")}</Link> </p>
                    <p className={screenWidth < 2000 ? "text-wrapper-6 hover:text-white hover:cursor-pointer" : "text-wrapper-6 hover:text-white hover:cursor-pointer"}><Link to={`/${locale}/incubator`}>{t("registrationRequirements")}</Link> </p>
                    <p className={screenWidth < 2000 ? "text-wrapper-6 hover:text-white hover:cursor-pointer" : "text-wrapper-6 hover:text-white hover:cursor-pointer"} > <Link to={`/${locale}/incubator`}>{t("currentIncubatees")}</Link></p>
                  </div>
                </div>

                <div className="flex flex-col gap-5 ">
                  <p className={screenWidth < 2000 ? "text-wrapper-3" : "text-wrapper-3"}>{t("waterInnovationNetwork")}</p>
                  <div className="md:flex md:justify-center md:items-center gap-[10%] sm:block">

                    <p className={screenWidth < 2000 ? "text-wrapper-6 hover:text-white hover:cursor-pointer" : "text-wrapper-6 hover:text-white hover:cursor-pointer"} ><Link to={`/${locale}/news?type=report&&serch=`}>{t("reports")}</Link></p>
                    <p className={screenWidth < 2000 ? "text-wrapper-6 hover:text-white hover:cursor-pointer" : "text-wrapper-6 hover:text-white hover:cursor-pointer"}><Link to={`/${locale}/news?type=event&&serch=`}>{t("events")}</Link></p>
                    <p className={screenWidth < 2000 ? "text-wrapper-6 hover:text-white hover:cursor-pointer" : "text-wrapper-6 hover:text-white hover:cursor-pointer"} ><Link to={`/${locale}/news?type=news&&serch=`}>{t("news")}</Link></p>
                  </div>

                </div>





              </div>

              <img className="line-2" alt="Line" src="/img/line-2.svg" />
              <div className="container-12 flex justify-center items-center w-[100%] md:flex-col" >
                <div className="container-13">
                  <div className={screenWidth < 2000 ? "text-wrapper-5" : "text-wrapper-5"}>{t("termsOfUse")}</div>
                  <div className={screenWidth < 2000 ? "text-wrapper-5" : "text-wrapper-5"}>{t("privacyPolicy")}</div>
                </div>
                <div className="frame-5">
                  <Copyright className="copyright-2" />
                  <p className={screenWidth < 2000 ? "text-wrapper-5" : "text-wrapper-5"}>{t("allRightsReserved")}</p>
                </div>

              </div>
            </div>
          </div>





          <div className="flex justify-center items-center">

            <Newsletter
              className={screenWidth < 2000 ? "newsletter-instance  lg:top-[-30%] md:top-[-22%] sm:top-[-7%]"
                : screenWidth < 3000 ? "newsletter-instance top-[-30%] " :
                  "newsletter-instance top-[-50%] "}
              clipPathGroup="/img/image.png"
              paperPlaneTiltFormatOutline="/img/paperplanetilt-1.png"
            />
          </div>
        </div>
      </div>

    </>
  );
};

Footer.propTypes = {
  logo: PropTypes.string,
};
