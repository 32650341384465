import React, { useEffect, useState } from "react";
import { useScrollToTop, useWindowWidth } from "../../breakpoints";
import { ArrowUpLeft } from "../../components/ArrowUpLeft";
import { CaretDown } from "../../components/CaretDown";
import { Copyright } from "../../components/Copyright";
import { FacebookLogo } from "../../components/FacebookLogo";
import { Footer } from "../../components/Footer";
import { House } from "../../components/House";
import { InstagramLogo } from "../../components/InstagramLogo";
import { LinkedinLogo } from "../../components/LinkedinLogo";
import { List } from "../../components/List";
import { Navbar } from "../../components/Navbar";
import { Newsletter } from "../../components/Newsletter";
import { PaperPlaneTilt } from "../../components/PaperPlaneTilt";
import { TextHover } from "../../components/TextHover";
import { User } from "../../components/User";
import { XLogo } from "../../components/XLogo";
import { YoutubeLogo } from "../../components/YoutubeLogo";
import "./style.css";
import { Clockafternoon8 } from "../../icons/Clockafternoon8";
import { useReports } from "../../api/api";
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from "../../translations/TranslationContext";

export const NewsMobile = () => {
  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, []);

  const screenWidth = useWindowWidth();
  const reports = useReports()
  // console.log(reports);
  let count = 0;

  useScrollToTop()

  let [searchParams, setSearchParams] = useSearchParams();
  let params = new URL(document.location).searchParams;
  console.log(params);
  console.log(params.get("serch"));
  function handelSerch() {
    // console.log(document.getElementById("serch_input").value)
    setSearchParams(`type=${params.get("type")}&serch=${document.getElementById("serch_input").value}`)
  }

  const { t, changeLocale, locale } = useTranslation();


  return (<>
    <div dir={locale == "ar" ? "rtl" : "ltr"}
      className="news-mobile"
    >
      <Navbar className="navbar-instance" logo="/img/logo-3.png" />
      <div
        className="container-74 h-[55vh] md:h-[40vh]"
        style={{
          backgroundImage:
            screenWidth < 834
              ? "url(/img/waterbackground.webp)"
              : screenWidth >= 834
                ? "url(/img/waterbackground.webp)"
                : "url(/img/waterbackground.webp)",
        }}
      >
        <div
          className="container-75"

        >
          <div
            className="container-76"
          >
            <div
              className="text-wrapper-101"
              style={{
                width: "100%",
                fontFamily:
                  screenWidth < 500
                    ? "var(--arabic-heading-h4-font-family)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h3-font-family)"
                      : screenWidth >= 834
                        ? "var(--arabic-heading-h1-font-family)"
                        : "var(--arabic-heading-h1-font-family)",
                fontSize:
                  screenWidth < 500
                    ? "var(--arabic-heading-h4-font-size)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h3-font-size)"
                      : screenWidth >= 834
                        ? "var(--arabic-heading-h1-font-size)"
                        : "var(--extra-large-size)",
                fontStyle:
                  screenWidth < 500
                    ? "var(--arabic-heading-h4-font-style)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h3-font-style)"
                      : screenWidth >= 834
                        ? "var(--arabic-heading-h1-font-style)"
                        : "var(--arabic-heading-h1-font-style)",
                fontWeight:
                  screenWidth < 500
                    ? "var(--arabic-heading-h4-font-weight)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h3-font-weight)"
                      : screenWidth >= 834
                        ? "var(--arabic-heading-h1-font-weight)"
                        : "var(--arabic-heading-h1-font-weight)",
                letterSpacing:
                  screenWidth < 500
                    ? "var(--arabic-heading-h4-letter-spacing)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h3-letter-spacing)"
                      : screenWidth >= 834
                        ? "var(--arabic-heading-h1-letter-spacing)"
                        : "var(--arabic-heading-h1-letter-spacing)",
                lineHeight:
                  screenWidth < 834
                    ? "var(--arabic-heading-h4-line-height)"
                    : screenWidth >= 834
                      ? "var(--arabic-heading-h1-line-height)"
                      : "var(--arabic-heading-h1-line-height)",
              }}
            >
{t("waterTechNetwork")}            </div>
            <p className="text-wrapper-102"
              style={{
                width: "100%",
                fontFamily:
                  screenWidth < 500
                    ? "var(--arabic-heading-h6-font-family)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h6-font-family)"
                      : screenWidth >= 834
                        ? "var(--arabic-heading-h6-font-family)"
                        : "var(--arabic-heading-h3-font-family)",
                fontSize:
                  screenWidth < 500
                    ? "var(--arabic-heading-h6-font-size)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h6-font-size)"
                      : screenWidth >= 834
                        ? "var(--arabic-heading-h6-font-size)"
                        : "var(--meduam-large-size)",
                fontStyle:
                  screenWidth < 500
                    ? "var(--arabic-heading-h6-font-style)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h6-font-style)"
                      : screenWidth >= 834
                        ? "var(--arabic-heading-h6-font-style)"
                        : "var(--arabic-heading-h3-font-style)",
                fontWeight:
                  screenWidth < 500
                    ? "var(--arabic-heading-h6-font-weight)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h6-font-weight)"
                      : screenWidth >= 834
                        ? "var(--arabic-heading-h6-font-weight)"
                        : "var(--arabic-heading-h3-font-weight)",
                letterSpacing:
                  screenWidth < 500
                    ? "var(--arabic-heading-h6-letter-spacing)"
                    : screenWidth >= 500 && screenWidth < 834
                      ? "var(--arabic-heading-h6-letter-spacing)"
                      : screenWidth >= 834
                        ? "var(--arabic-heading-h6-letter-spacing)"
                        : "var(--arabic-heading-h3-letter-spacing)",
                lineHeight:
                  screenWidth < 834
                    ? "var(--arabic-heading-h6-line-height)"
                    : screenWidth >= 834
                      ? "var(--arabic-heading-h6-line-height)"
                      : "var(--arabic-heading-h3-line-height)",
              }}
            >{t("saudiWaterInnovationCenter")}</p>
          </div>
        </div>
      </div>


      <div className="frame-125">
        <div className="frame-126">
          <div className="aframe-127">
            <div className="aframe-128">
              <House className={screenWidth < 3000 ? "ainstance-node-8" : "ainstance-node-8more"} format="stroke" formatOutline="/img/house.png" weight="regular" />
              <div className={screenWidth < 3000 ? "aitem-link-home-5" : "aitem-link-home-5"}>{t("homepage")}</div>
            </div>
            <div className={screenWidth < 3000 ? "atext-wrapper-104" : "atext-wrapper-104"}>/</div>
            <div className={screenWidth < 3000 ? "atext-wrapper-103" : "atext-wrapper-103"}>
          {t("waterTechNetwork")}    
            </div>
          </div>
          <div className="frame-129">
            <div className="frame-130">
              <div className={screenWidth < 3000 ? "text-wrapper-105" : "text-wrapper-105"}>{t("waterTechNetwork")}</div>
              <div className={screenWidth < 3000 ? "frame-131" : "frame-131more"}>
                {/* <div className="frame-132">
                    <div className="text-wrapper-106">الأخبار</div>
                  </div> */}
                <div
                  className={params.get("type") == "news" ? "frame-133 bg-[#2a499e] text-[white] cursor-pointer" : "frame-133 hover:bg-[#2a499e] hover:text-[white] cursor-pointer"}
                  onClick={() => {
                    setSearchParams("type=news&&serch=")
                    document.getElementById("serch_input").value = ""

                  }}
                >
                  <div
                    className={screenWidth < 3000 ? "text-wrapper-107 " : "text-wrapper-107 "}>{t("news")}</div>
                </div>
                <div
                  className={params.get("type") == "event" ? "frame-133 bg-[#2a499e] text-[white] cursor-pointer" : "frame-133 hover:bg-[#2a499e] hover:text-[white] cursor-pointer"}
                  onClick={() => {
                    setSearchParams("type=event&&serch=")
                    document.getElementById("serch_input").value = ""
                  }}

                >
                  <div className={screenWidth < 3000 ? "text-wrapper-107" : "text-wrapper-107"}>{t("events")}</div>
                </div>
                <div
                  className={params.get("type") == "report" ? "frame-133 bg-[#2a499e] text-[white] cursor-pointer" : "frame-133 hover:bg-[#2a499e] hover:text-[white] cursor-pointer"}
                  onClick={() => {
                    setSearchParams("type=report&&serch=")
                    document.getElementById("serch_input").value = ""
                  }}

                >
                  <div className={screenWidth < 3000 ? "text-wrapper-107" : "text-wrapper-107"}>{t("reports")}</div>
                </div>
              </div>
            </div>
            <div className="frame-134 w-[25%] lg:w-[100%]">
              <div className="frame-135">
                <div className={screenWidth < 3000 ? "text-wrapper-108" : "text-wrapper-108"}>{t("search")}</div>
                <div className="search-2">
                  <div className={screenWidth < 3000 ? "text-wrapper-110 w-full" : "text-wrapper-110 w-full"}>
                    <input id="serch_input" type="text" placeholder={t("searchWord")} className="w-full h-full focus:outline-none" style={{ background: "border-box" }} />
                  </div>
                  <div className={screenWidth < 3000 ? "frame-138 hover:bg-cyan-600  cursor-pointer" : "cursor-pointer frame-138more hover:bg-bg-cyan-600 "}
                    onClick={handelSerch}
                  >
                    <img className={screenWidth < 3000 ? "magnifying-glass-2" : "magnifying-glass-2more"} alt="Magnifying glass" src="/img/magnifyingglass.png" />
                  </div>

                </div>
              </div>
              {/* <div className="frame-135">
                  <div className={screenWidth<2000?"text-wrapper-108":"text-wrapper-108more"}>الفعاليات</div>
                  <div className="search-2">
                    <div className={screenWidth<2000?"frame-138":"frame-138more"}>
                      <img className={screenWidth<2000?"magnifying-glass-2":"magnifying-glass-2more"} alt="Magnifying glass" src="/img/magnifyingglass.png" />
                    </div>
                    <div className={screenWidth<2000?"text-wrapper-110":"text-wrapper-110more"}>كلمة البحث</div>
                  </div>
                </div> */}
            </div>
          </div>
        </div>


        <div className="frame-139">
          <div className="grid grid-cols-1 gap-5 lg:grid-cols-1 sm:grid-cols-1 w-full">
            {reports?.data?.map((report) => {
              if (report.type == params.get("type") && report.name[`${locale}`].includes(params.get("serch"))) {
                count++
                return <Link to={`/${locale}/newsdetails?id=${report.id}`} className="frame-33 hover:shadow-xl hover:shadow-[gray]" key={"report" + report.id}>
                  <img className="rectangle-5" alt="Rectangle" src={report.attachment[0].original} />
                  <div className="frame-34 z-20">
                    <div className="frame-35">
                      <div className={screenWidth < 3000 ? "text-wrapper-36" : "text-wrapper-36"}>الخميس 29 أبريل, 2024</div>
                      <Clockafternoon8 className="clockafternoon-8" />
                    </div>
                    <div className="frame-36">
                      <p className={"text-wrapper-37"}
                        style={{
                          fontFamily:
                            screenWidth < 500
                              ? "var(--arabic-heading-h5-font-family)"
                              : screenWidth >= 500 && screenWidth < 834
                                ? "var(--arabic-heading-h4-font-family)"
                                : screenWidth >= 834
                                  ? "var(--arabic-heading-h3-font-family)"
                                  : undefined,
                          fontSize:
                            screenWidth < 500
                              ? "var(--arabic-heading-h5-font-size)"
                              : screenWidth >= 500 && screenWidth < 834
                                ? "var(--arabic-heading-h4-font-size)"
                                : screenWidth >= 834
                                  ? "var(--arabic-heading-h3-font-size)"
                                  : "120px",
                          fontStyle:
                            screenWidth < 500
                              ? "var(--arabic-heading-h5-font-style)"
                              : screenWidth >= 500 && screenWidth < 834
                                ? "var(--arabic-heading-h4-font-style)"
                                : screenWidth >= 834
                                  ? "var(--arabic-heading-h3-font-style)"
                                  : undefined,
                          fontWeight:
                            screenWidth < 500
                              ? "var(--arabic-heading-h5-font-weight)"
                              : screenWidth >= 500 && screenWidth < 834
                                ? "var(--arabic-heading-h4-font-weight)"
                                : screenWidth >= 834
                                  ? "var(--arabic-heading-h3-font-weight)"
                                  : undefined,
                          letterSpacing:
                            screenWidth < 500
                              ? "var(--arabic-heading-h4-letter-spacing)"
                              : screenWidth >= 500 && screenWidth < 834
                                ? "var(--arabic-heading-h4-letter-spacing)"
                                : screenWidth >= 834
                                  ? "var(--arabic-heading-h3-letter-spacing)"
                                  : undefined,
                          lineHeight:
                            screenWidth < 834
                              ? "var(--arabic-heading-h4-line-height)"
                              : screenWidth >= 834
                                ? "var(--arabic-heading-h3-line-height)"
                                : undefined,
                        }}
                      >
                        {report?.name[`${locale}`]}
                      </p>
                      <p className={screenWidth < 3000 ? "text-wrapper-38" : "text-wrapper-38"}>
                        {report?.description[`${locale}`]}
                      </p>
                    </div>
                  </div>
                  <div className="frame-37">
                    <div className={screenWidth < 3000 ? "text-wrapper-39" : "text-wrapper-39"}>{report.type == "news" ?locale=="ar"? "أخبار":"News" : report.type == "event" ?locale=="ar"?  "فعاليات":"Event":locale=="ar"? "تقارير":"Report"}</div>
                  </div>
                </Link>
              }
            })}
            {count ? "" : <p className="text-wrapper-37" style={{ color: "black", textAlign: "center", fontSize: "1.5rem" }}>
            {locale=="ar"?"  عذرا , لا يوجد نتيجه بحث مطابقة لبحثك . ": "Sorry, there are no search results matching your search."}
            </p>}


          </div>
        </div>
      </div>

      {/* 
      {((screenWidth >= 834 && screenWidth < 1440) || screenWidth < 834) && (
        <>
          <div
            className="overlap-group-4"
            style={{
              height: screenWidth < 834 ? "1743px" : screenWidth >= 834 && screenWidth < 1440 ? "1422px" : undefined,
              top: screenWidth < 834 ? "2835px" : screenWidth >= 834 && screenWidth < 1440 ? "2794px" : undefined,
              width: screenWidth < 834 ? "360px" : screenWidth >= 834 && screenWidth < 1440 ? "834px" : undefined,
            }}
          >


            {screenWidth >= 834 && screenWidth < 1440 && (
              <>
                <footer className="footer-8">
                  <div className="container-60">
                    <div className="container-61">
                      <div className="container-62">
                        <div className="link-picture-3">
                          <div className="vision-logo-dark-png-4" />
                        </div>
                        <img className="line-7" alt="Line" src="/img/line-1-1.png" />
                        <img className="logo-8" alt="Logo" src="/img/logo-4.png" />
                      </div>
                      <div className="container-63">
                        <p className="text-wrapper-93">{t("connectWithUsOnSocialMedia")}</p>
                        <div className="container-64">
                          <FacebookLogo
                            className="instance-node-9"
                            format="stroke"
                            formatStrokeWeight="/img/facebooklogo.png"
                            weight="regular"
                          />
                          <LinkedinLogo
                            className="instance-node-9"
                            format="stroke"
                            formatStrokeWeight="/img/linkedinlogo.png"
                            weight="regular"
                          />
                          <YoutubeLogo
                            className="instance-node-9"
                            format="stroke"
                            formatStrokeWeight="/img/youtubelogo.png"
                            weight="regular"
                          />
                          <XLogo
                            className="instance-node-9"
                            format="stroke"
                            formatStrokeWeight="/img/xlogo.png"
                            weight="regular"
                          />
                          <InstagramLogo
                            className="instance-node-9"
                            format="stroke"
                            formatStrokeWeight="/img/instagramlogo.png"
                            weight="regular"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="container-65">
                      <div className="container-66">
                        <div className="margin-6">
                          <div className="container-67">
                            <div className="heading-10">
                              <div className="text-wrapper-94">{t("waterInnovationNetwork")}</div>
                            </div>
                          </div>
                        </div>
                        <div className="div-8">
                          <div className="list-9">
                            <div className="item-11">
                              <div className="link-6">
                                <TextHover className="text-hover-41" divClassName="text-hover-42" text="التقارير" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="div-8">
                          <div className="list-9">
                            <div className="item-11">
                              <div className="link-6">
                                <TextHover className="text-hover-41" divClassName="text-hover-43" text="الفعاليات" />
                              </div>
                            </div>
                          </div>
                          <div className="list-9">
                            <div className="item-11">
                              <div className="link-6">
                                <TextHover className="text-hover-41" divClassName="text-hover-44" text="الأخبار" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-66">
                        <div className="margin-7">
                          <div className="container-67">
                            <div className="heading-11">
                              <div className="text-wrapper-94">الشركاء</div>
                            </div>
                          </div>
                        </div>
                        <div className="div-8">
                          <div className="list-9">
                            <div className="item-11">
                              <div className="link-7">
                                <TextHover
                                  className="text-hover-41"
                                  divClassName="text-hover-45"
                                  text="لا يوجد محتوى"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-66">
                        <div className="margin-7">
                          <div className="container-67">
                            <div className="heading-11">
                              <div className="text-wrapper-94">الخدمات</div>
                            </div>
                          </div>
                        </div>
                        <div className="div-8">
                          <div className="list-9">
                            <div className="item-11">
                              <div className="link-7">
                                <TextHover
                                  className="text-hover-41"
                                  divClassName="text-hover-45"
                                  text="لا يوجد محتوى"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-68">
                        <div className="margin-7">
                          <div className="container-67">
                            <div className="heading-11">
                              <div className="text-wrapper-94">الحاضنة</div>
                            </div>
                          </div>
                        </div>
                        <div className="container-69">
                          <div className="list-10">
                            <div className="item-12">
                              <TextHover className="text-hover-46" divClassName="text-hover-47" text="حاضنة المياه" />
                            </div>
                            <div className="item-12">
                              <TextHover className="text-hover-46" divClassName="text-hover-48" text="المراحل" />
                            </div>
                            <div className="item-13">
                              <TextHover
                                className="text-hover-46"
                                divClassName="text-hover-49"
                                text="متطلبات التسجيل"
                              />
                            </div>
                            <div className="item-13">
                              <TextHover
                                className="text-hover-46"
                                divClassName="text-hover-50"
                                text="المحتضنين الحاليين"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-70">
                        <div className="margin-7">
                          <div className="container-71">
                            <div className="heading-12">
                              <div className="text-wrapper-95">عن المركز</div>
                            </div>
                          </div>
                        </div>
                        <div className="div-8">
                          <div className="div-8">
                            <div className="item-link-4">
                              <TextHover className="text-hover-46" divClassName="text-hover-51" text="نبذة عن المركز" />
                            </div>
                            <div className="item-link-4">
                              <TextHover className="text-hover-46" divClassName="text-hover-52" text="أهداف المركز" />
                            </div>
                            <div className="item-link-4">
                              <TextHover
                                className="text-hover-46"
                                divClassName="text-hover-44"
                                text="الرؤية والراسلة"
                              />
                            </div>
                            <div className="item-link-4">
                              <TextHover className="text-hover-46" divClassName="text-hover-53" text="فريق المركز" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img className="line-8" alt="Line" src="/img/line-2-1.png" />
                    <div className="container-72">
                      <div className="frame-119">
                        <Copyright
                          className="instance-node-8"
                          format="stroke"
                          formatStrokeWeight="/img/copyright-1.png"
                          weight="regular"
                        />
                        <p className="element-5">
                          جميع الحقوق محفوظة لمركز الابتكار السعودي لتقنية المياه&nbsp;&nbsp;2024
                        </p>
                      </div>
                      <div className="container-73">
                        <div className="text-wrapper-96">شروط الاستخدام</div>
                        <div className="text-wrapper-97">سياسة الخصوصية</div>
                      </div>
                    </div>
                  </div>
                </footer>
                <div className="frame-120">
                  <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-18-2.png" />
                  <div className="frame-121">
                    <div className="frame-122">
                      <div className="text-wrapper-89">النشرة الإخبارية</div>
                      <p className="text-wrapper-98">
                        مهتم بمعرفة آخر المستجدات عن مركز الابتكار السعودي لتقنيات المياه؟ اشترك الآن بالنشرة الإخبارية
                        ليصلك كل جديد!
                      </p>
                    </div>
                    <div className="frame-123">
                      <div className="frame-124">
                        <div className="text-wrapper-99">اشتراك</div>
                        <PaperPlaneTilt
                          className="instance-node-8"
                          format="stroke"
                          formatOutline="/img/paperplanetilt-3.png"
                          weight="fill"
                        />
                      </div>
                      <div className="text-wrapper-100">ادخل بريدك الإلكتروني</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

        </>
      )} */}

      {/* {screenWidth >= 834 && screenWidth < 1440 && (
        <div className="frame-125">
          <div className="frame-126">
            <div className="frame-127">
              <div className="text-wrapper-103">شبكة تقنيات المياه</div>
              <div className="text-wrapper-104">/</div>
              <div className="frame-128">
                <div className="item-link-home-5">الرئيسية</div>
                <House className="instance-node-8" format="stroke" formatOutline="/img/house.png" weight="regular" />
              </div>
            </div>
            <div className="frame-129">
              <div className="frame-130">
                <div className="text-wrapper-105">شبكة تقنيات المياه</div>
                <div className="frame-131">
                  <div className="frame-132">
                    <div className="text-wrapper-106">الأخبار</div>
                  </div>
                  <div className="frame-133">
                    <div className={screenWidth<2000?"text-wrapper-107":"text-wrapper-107more"}>الفعاليات</div>
                  </div>
                  <div className="frame-133">
                    <div className={screenWidth<2000?"text-wrapper-107":"text-wrapper-107more"}>التقارير</div>
                  </div>
                </div>
              </div>
              <div className="frame-134">
                <div className="frame-135">
                  <div className="text-wrapper-108">ترتيب حسب</div>
                  <div className="frame-136">
                    <div className="frame-137">
                      <CaretDown
                        className="instance-node-10"
                        format="stroke"
                        formatOutline="/img/caretdown-5.png"
                        weight="regular"
                      />
                      <div className="text-wrapper-109">الأقدم</div>
                    </div>
                  </div>
                </div>
                <div className="frame-135">
                  <div className="text-wrapper-108">اسم التقرير</div>
                  <div className="search-2">
                    <div className="frame-138">
                      <img className="magnifying-glass-2" alt="Magnifying glass" src="/img/magnifyingglass.png" />
                    </div>
                    <div className="text-wrapper-110">كلمة البحث</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-139">
            <div className="frame-140">
              <div className="card-4">
                <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-29.png" />
                <div className="frame-141">
                  <div className="frame-142">
                    <div className="frame-143">
                      <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                      <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                    </div>
                  </div>
                  <p className="text-wrapper-112">
                    عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد الصناعية
                  </p>
                  <div className="link-to-wrapper">
                    <div className="link-to">
                      <ArrowUpLeft
                        className="instance-node-8"
                        format="stroke"
                        formatOutline="/img/arrowupleft-2.png"
                        weight="regular"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-4">
                <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-29.png" />
                <div className="frame-141">
                  <div className="frame-142">
                    <div className="frame-143">
                      <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                      <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                    </div>
                  </div>
                  <p className="text-wrapper-112">
                    عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد الصناعية
                  </p>
                  <div className="link-to-wrapper">
                    <div className="link-to">
                      <ArrowUpLeft
                        className="instance-node-8"
                        format="stroke"
                        formatOutline="/img/arrowupleft-2.png"
                        weight="regular"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-4">
                <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-29.png" />
                <div className="frame-141">
                  <div className="frame-142">
                    <div className="frame-143">
                      <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                      <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                    </div>
                  </div>
                  <p className="text-wrapper-112">
                    عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد الصناعية
                  </p>
                  <div className="link-to-wrapper">
                    <div className="link-to">
                      <ArrowUpLeft
                        className="instance-node-8"
                        format="stroke"
                        formatOutline="/img/arrowupleft-2.png"
                        weight="regular"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-4">
                <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-29.png" />
                <div className="frame-141">
                  <div className="frame-142">
                    <div className="frame-143">
                      <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                      <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                    </div>
                  </div>
                  <p className="text-wrapper-112">
                    عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد الصناعية
                  </p>
                  <div className="link-to-wrapper">
                    <div className="link-to">
                      <ArrowUpLeft
                        className="instance-node-8"
                        format="stroke"
                        formatOutline="/img/arrowupleft-2.png"
                        weight="regular"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-4">
                <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-29.png" />
                <div className="frame-141">
                  <div className="frame-142">
                    <div className="frame-143">
                      <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                      <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                    </div>
                  </div>
                  <p className="text-wrapper-112">
                    عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد الصناعية
                  </p>
                  <div className="link-to-wrapper">
                    <div className="link-to">
                      <ArrowUpLeft
                        className="instance-node-8"
                        format="stroke"
                        formatOutline="/img/arrowupleft-2.png"
                        weight="regular"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-4">
                <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-29.png" />
                <div className="frame-141">
                  <div className="frame-142">
                    <div className="frame-143">
                      <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                      <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                    </div>
                  </div>
                  <p className="text-wrapper-112">
                    عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد الصناعية
                  </p>
                  <div className="link-to-wrapper">
                    <div className="link-to">
                      <ArrowUpLeft
                        className="instance-node-8"
                        format="stroke"
                        formatOutline="/img/arrowupleft-2.png"
                        weight="regular"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination-v">
              <div className="div-9">
                <img className="img-5" alt="Icon pagination" src="/img/icon-pagination-first-2.png" />
              </div>
              <div className="div-9">
                <img className="img-5" alt="Icon pagination prev" src="/img/icon-pagination-prev-2.png" />
              </div>
              <div className="page-2">
                <div className="date-4">1</div>
              </div>
              <div className="div-9">
                <div className="date-5">2</div>
              </div>
              <div className="div-9">
                <div className="date-5">3</div>
              </div>
              <div className="page-3">
                <div className="date-6">...</div>
              </div>
              <div className="div-9">
                <div className="date-5">10</div>
              </div>
              <div className="div-9">
                <img className="img-5" alt="Icon pagination next" src="/img/icon-pagination-next-2.png" />
              </div>
              <div className="div-9">
                <img className="img-5" alt="Icon pagination last" src="/img/icon-pagination-last-2.png" />
              </div>
            </div>
          </div>
        </div>
      )} */}

      {/* {screenWidth >= 1440 && (
        <>
          <div className="container-77">
            <div className="container-78">
              <div className="container-79">
                <div className="text-wrapper-113">شبكة تقنيات المياه</div>
                <p className="text-wrapper-102">مركز الابتكار السعودي لتقنيات المياه</p>
              </div>
            </div>
          </div>
          <div className="overlap-3">
            <Footer className="footer-9" logo="/img/logo-3.png" property1="web" />
            <Newsletter
              className="newsletter-3"
              clipPathGroup="/img/clip-path-group-2-2.png"
              paperPlaneTiltFormatOutline="/img/paperplanetilt-1.png"
            />
          </div>
          <div className="frame-144">
            <div className="frame-127">
              <div className="text-wrapper-103">شبكة تقنيات المياه</div>
              <div className="text-wrapper-104">/</div>
              <div className="frame-128">
                <div className="item-link-home-5">الرئيسية</div>
                <House className="instance-node-8" format="stroke" formatOutline="/img/house.png" weight="regular" />
              </div>
            </div>
            <div className="frame-145">
              <div className="frame-146">
                <div className="text-wrapper-114">شبكة تقنيات المياه</div>
                <div className="frame-131">
                  <div className="frame-132">
                    <div className="text-wrapper-106">الأخبار</div>
                  </div>
                  <div className="frame-133">
                    <div className={screenWidth<2000?"text-wrapper-107":"text-wrapper-107more"}>الفعاليات</div>
                  </div>
                  <div className="frame-133">
                    <div className={screenWidth<2000?"text-wrapper-107":"text-wrapper-107more"}>التقارير</div>
                  </div>
                </div>
              </div>
              <div className="frame-146">
                <div className="frame-147">
                  <div className="frame-148">
                    <div className="text-wrapper-108">ترتيب حسب</div>
                    <div className="frame-136">
                      <div className="frame-137">
                        <CaretDown
                          className="instance-node-10"
                          format="stroke"
                          formatOutline="/img/caretdown-5.png"
                          weight="regular"
                        />
                        <div className="text-wrapper-109">الأقدم</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-148">
                    <div className="text-wrapper-108">اسم الخبر</div>
                    <div className="search-2">
                      <div className="frame-138">
                        <img className="magnifying-glass-2" alt="Magnifying glass" src="/img/magnifyingglass.png" />
                      </div>
                      <div className="text-wrapper-110">كلمة البحث</div>
                    </div>
                  </div>
                </div>
                <div className="frame-149">
                  <div className="frame-150">
                    <div className="frame-151">
                      <div className="card-5">
                        <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-29.png" />
                        <div className="frame-141">
                          <div className="frame-142">
                            <div className="frame-143">
                              <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                              <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                            </div>
                          </div>
                          <p className="text-wrapper-112">
                            عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد
                            الصناعية
                          </p>
                          <div className="link-to-wrapper">
                            <div className="link-to">
                              <ArrowUpLeft
                                className="instance-node-8"
                                format="stroke"
                                formatOutline="/img/arrowupleft-2.png"
                                weight="regular"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-5">
                        <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-29.png" />
                        <div className="frame-141">
                          <div className="frame-142">
                            <div className="frame-143">
                              <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                              <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                            </div>
                          </div>
                          <p className="text-wrapper-112">
                            عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد
                            الصناعية
                          </p>
                          <div className="link-to-wrapper">
                            <div className="link-to">
                              <ArrowUpLeft
                                className="instance-node-8"
                                format="stroke"
                                formatOutline="/img/arrowupleft-2.png"
                                weight="regular"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-5">
                        <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-29.png" />
                        <div className="frame-141">
                          <div className="frame-142">
                            <div className="frame-143">
                              <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                              <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                            </div>
                          </div>
                          <p className="text-wrapper-112">
                            عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد
                            الصناعية
                          </p>
                          <div className="link-to-wrapper">
                            <div className="link-to">
                              <ArrowUpLeft
                                className="instance-node-8"
                                format="stroke"
                                formatOutline="/img/arrowupleft-2.png"
                                weight="regular"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-151">
                      <div className="card-5">
                        <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-29.png" />
                        <div className="frame-141">
                          <div className="frame-142">
                            <div className="frame-143">
                              <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                              <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                            </div>
                          </div>
                          <p className="text-wrapper-112">
                            عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد
                            الصناعية
                          </p>
                          <div className="link-to-wrapper">
                            <div className="link-to">
                              <ArrowUpLeft
                                className="instance-node-8"
                                format="stroke"
                                formatOutline="/img/arrowupleft-2.png"
                                weight="regular"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-5">
                        <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-29.png" />
                        <div className="frame-141">
                          <div className="frame-142">
                            <div className="frame-143">
                              <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                              <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                            </div>
                          </div>
                          <p className="text-wrapper-112">
                            عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد
                            الصناعية
                          </p>
                          <div className="link-to-wrapper">
                            <div className="link-to">
                              <ArrowUpLeft
                                className="instance-node-8"
                                format="stroke"
                                formatOutline="/img/arrowupleft-2.png"
                                weight="regular"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-5">
                        <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-29.png" />
                        <div className="frame-141">
                          <div className="frame-142">
                            <div className="frame-143">
                              <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                              <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                            </div>
                          </div>
                          <p className="text-wrapper-112">
                            عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد
                            الصناعية
                          </p>
                          <div className="link-to-wrapper">
                            <div className="link-to">
                              <ArrowUpLeft
                                className="instance-node-8"
                                format="stroke"
                                formatOutline="/img/arrowupleft-2.png"
                                weight="regular"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-151">
                      <div className="card-5">
                        <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-29.png" />
                        <div className="frame-141">
                          <div className="frame-142">
                            <div className="frame-143">
                              <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                              <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                            </div>
                          </div>
                          <p className="text-wrapper-112">
                            عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد
                            الصناعية
                          </p>
                          <div className="link-to-wrapper">
                            <div className="link-to">
                              <ArrowUpLeft
                                className="instance-node-8"
                                format="stroke"
                                formatOutline="/img/arrowupleft-2.png"
                                weight="regular"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-5">
                        <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-29.png" />
                        <div className="frame-141">
                          <div className="frame-142">
                            <div className="frame-143">
                              <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                              <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                            </div>
                          </div>
                          <p className="text-wrapper-112">
                            عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد
                            الصناعية
                          </p>
                          <div className="link-to-wrapper">
                            <div className="link-to">
                              <ArrowUpLeft
                                className="instance-node-8"
                                format="stroke"
                                formatOutline="/img/arrowupleft-2.png"
                                weight="regular"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-5">
                        <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-29.png" />
                        <div className="frame-141">
                          <div className="frame-142">
                            <div className="frame-143">
                              <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                              <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                            </div>
                          </div>
                          <p className="text-wrapper-112">
                            عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد
                            الصناعية
                          </p>
                          <div className="link-to-wrapper">
                            <div className="link-to">
                              <ArrowUpLeft
                                className="instance-node-8"
                                format="stroke"
                                formatOutline="/img/arrowupleft-2.png"
                                weight="regular"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pagination-v">
                    <div className="div-9">
                      <img className="img-5" alt="Icon pagination" src="/img/icon-pagination-first-2.png" />
                    </div>
                    <div className="div-9">
                      <img className="img-5" alt="Icon pagination prev" src="/img/icon-pagination-prev-2.png" />
                    </div>
                    <div className="page-2">
                      <div className="date-4">1</div>
                    </div>
                    <div className="div-9">
                      <div className="date-5">2</div>
                    </div>
                    <div className="div-9">
                      <div className="date-5">3</div>
                    </div>
                    <div className="page-3">
                      <div className="date-6">...</div>
                    </div>
                    <div className="div-9">
                      <div className="date-5">10</div>
                    </div>
                    <div className="div-9">
                      <img className="img-5" alt="Icon pagination next" src="/img/icon-pagination-next-2.png" />
                    </div>
                    <div className="div-9">
                      <img className="img-5" alt="Icon pagination last" src="/img/icon-pagination-last-2.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Navbar className="navbar-9" logo="/img/logo-2.png" />
        </>
      )}

      {screenWidth < 834 && (
        <>
          <div className="frame-152">
            <div className="text-wrapper-115">شبكة تقنيات المياه</div>
            <div className="text-wrapper-116">/</div>
            <div className="frame-128">
              <div className="item-link-home-6">الرئيسية</div>
              <House
                className="instance-node-10"
                format="stroke"
                formatOutline="/img/house-1-2x.png"
                weight="regular"
              />
            </div>
          </div>
          <div className="frame-153">
            <div className="frame-154">
              <div className="frame-155">
                <div className="text-wrapper-117">شبكة تقنيات المياه</div>
                <div className="frame-131">
                  <div className="frame-132">
                    <div className="text-wrapper-118">الأخبار</div>
                  </div>
                  <div className="frame-133">
                    <div className="text-wrapper-119">الفعاليات</div>
                  </div>
                  <div className="frame-133">
                    <div className="text-wrapper-119">التقارير</div>
                  </div>
                </div>
              </div>
              <div className="frame-156">
                <div className="frame-142">
                  <div className="text-wrapper-108">اسم التقرير</div>
                  <div className="search-2">
                    <div className="frame-138">
                      <img className="magnifying-glass-2" alt="Magnifying glass" src="/img/magnifyingglass.png" />
                    </div>
                    <div className="text-wrapper-110">كلمة البحث</div>
                  </div>
                </div>
                <div className="frame-142">
                  <div className="text-wrapper-108">ترتيب حسب</div>
                  <div className="frame-136">
                    <div className="frame-137">
                      <CaretDown
                        className="instance-node-10"
                        format="stroke"
                        formatOutline="/img/caretdown-5.png"
                        weight="regular"
                      />
                      <div className="text-wrapper-109">الأقدم</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-139">
              <div className="frame-157">
                <div className="card-6">
                  <img className="rectangle-12" alt="Rectangle" src="/img/rectangle-29-6.png" />
                  <div className="frame-158">
                    <div className="frame-142">
                      <div className="frame-143">
                        <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                        <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                      </div>
                    </div>
                    <p className="text-wrapper-120">
                      عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد الصناعية
                    </p>
                    <div className="link-to-wrapper">
                      <div className="link-to">
                        <ArrowUpLeft
                          className="instance-node-8"
                          format="stroke"
                          formatOutline="/img/arrowupleft-2.png"
                          weight="regular"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-6">
                  <img className="rectangle-12" alt="Rectangle" src="/img/rectangle-29-6.png" />
                  <div className="frame-158">
                    <div className="frame-142">
                      <div className="frame-143">
                        <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                        <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                      </div>
                    </div>
                    <p className="text-wrapper-120">
                      عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد الصناعية
                    </p>
                    <div className="link-to-wrapper">
                      <div className="link-to">
                        <ArrowUpLeft
                          className="instance-node-8"
                          format="stroke"
                          formatOutline="/img/arrowupleft-2.png"
                          weight="regular"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-6">
                  <img className="rectangle-12" alt="Rectangle" src="/img/rectangle-29-6.png" />
                  <div className="frame-158">
                    <div className="frame-142">
                      <div className="frame-143">
                        <div className="text-wrapper-111">الخميس 29 أبريل, 2024</div>
                        <img className="clock-afternoon" alt="Clock afternoon" src="/img/clockafternoon.png" />
                      </div>
                    </div>
                    <p className="text-wrapper-120">
                      عباره عن منصة إلكترونية (Marketplace) تحتوي قاعدة بيانات تتيح للشركات الصناعية عرض المواد الصناعية
                    </p>
                    <div className="link-to-wrapper">
                      <div className="link-to">
                        <ArrowUpLeft
                          className="instance-node-8"
                          format="stroke"
                          formatOutline="/img/arrowupleft-2.png"
                          weight="regular"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pagination-v">
                <div className="div-9">
                  <img className="img-5" alt="Icon pagination" src="/img/icon-pagination-first-1.png" />
                </div>
                <div className="div-9">
                  <img className="img-5" alt="Icon pagination prev" src="/img/icon-pagination-prev-1.png" />
                </div>
                <div className="page-2">
                  <div className="date-4">1</div>
                </div>
                <div className="div-9">
                  <div className="date-5">2</div>
                </div>
                <div className="page-3">
                  <div className="date-6">...</div>
                </div>
                <div className="div-9">
                  <div className="date-5">10</div>
                </div>
                <div className="div-9">
                  <img className="img-5" alt="Icon pagination next" src="/img/icon-pagination-next-1.png" />
                </div>
                <div className="div-9">
                  <img className="img-5" alt="Icon pagination last" src="/img/icon-pagination-last-1.png" />
                </div>
              </div>
            </div>
          </div>
        </>
      )} */}
    </div>
    <div className={screenWidth < 2000 ? "mt-[100px] lg:mt-[300px] sm:mt-[80px]" : "mt-[700px]"}></div>
    <Footer className="footer-3" logo="/img/logo-9.png" property1="web" />
  </>);
};
