/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatOutlineWeightLight11 = ({ className }) => {
  return (
    <svg
      className={`format-outline-weight-light-11 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16 3.5C13.5277 3.5 11.111 4.23311 9.05538 5.60663C6.99976 6.98015 5.39761 8.93238 4.45151 11.2165C3.50542 13.5005 3.25787 16.0139 3.74019 18.4386C4.2225 20.8634 5.41301 23.0907 7.16117 24.8388C8.90933 26.587 11.1366 27.7775 13.5614 28.2598C15.9861 28.7421 18.4995 28.4946 20.7835 27.5485C23.0676 26.6024 25.0199 25.0002 26.3934 22.9446C27.7669 20.889 28.5 18.4723 28.5 16C28.4964 12.6859 27.1782 9.5086 24.8348 7.16518C22.4914 4.82177 19.3141 3.50364 16 3.5ZM16.5 27.4888V18.5H20C20.1326 18.5 20.2598 18.4473 20.3536 18.3536C20.4473 18.2598 20.5 18.1326 20.5 18C20.5 17.8674 20.4473 17.7402 20.3536 17.6464C20.2598 17.5527 20.1326 17.5 20 17.5H16.5V14C16.5 13.337 16.7634 12.7011 17.2322 12.2322C17.7011 11.7634 18.337 11.5 19 11.5H21C21.1326 11.5 21.2598 11.4473 21.3536 11.3536C21.4473 11.2598 21.5 11.1326 21.5 11C21.5 10.8674 21.4473 10.7402 21.3536 10.6464C21.2598 10.5527 21.1326 10.5 21 10.5H19C18.0717 10.5 17.1815 10.8687 16.5251 11.5251C15.8688 12.1815 15.5 13.0717 15.5 14V17.5H12C11.8674 17.5 11.7402 17.5527 11.6465 17.6464C11.5527 17.7402 11.5 17.8674 11.5 18C11.5 18.1326 11.5527 18.2598 11.6465 18.3536C11.7402 18.4473 11.8674 18.5 12 18.5H15.5V27.4888C12.4962 27.358 9.66298 26.0561 7.6074 23.8618C5.55182 21.6676 4.43735 18.7555 4.50272 15.7496C4.5681 12.7436 5.80814 9.88277 7.95717 7.77996C10.1062 5.67716 12.9933 4.49962 16 4.49962C19.0067 4.49962 21.8938 5.67716 24.0428 7.77996C26.1919 9.88277 27.4319 12.7436 27.4973 15.7496C27.5627 18.7555 26.4482 21.6676 24.3926 23.8618C22.337 26.0561 19.5038 27.358 16.5 27.4888Z"
        fill="#343330"
      />
    </svg>
  );
};
