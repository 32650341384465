/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Paperplanetilt } from "../../icons/Paperplanetilt";
import "./style.css";
import { useWindowWidth } from "../../breakpoints";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from "../../translations/TranslationContext";

export const Newsletter = ({ className, clipPathGroup = "/img/clip-path-group.png" }) => {
  let baseURL="https://api.swic.sa/api"
  const { t, changeLocale, locale } = useTranslation();

let [placehod,setPlacehold]=useState(locale=="ar"?"ادخل بريدك الإلكتروني":"Enter your email address.")
  const screenWidth = useWindowWidth();
  async function handelMaill(data) {
    await axios.post(`${baseURL}/newsletter`,{"email":data},{headers:{
      "Accept":"application/json",
      "Accept-Language":"ar",
      'Authorization':'Bearer ZiW1eN69MhwX70jTMLXuXq1PUYaU66j6oMGb9VLS81b52fa0',
    }})
      .then(function (response) {
        // setPage(response.data)
        console.log(response);
        toast.success("Success")

        // toast()
      })
      .catch(function () {
        
        toast.error("your email address already exists")
      });
  
}

 function handelSubmit() {
  if(document.getElementById("mailto").value&&document.getElementById("mailto").value.includes("@")&&document.getElementById("mailto").value.includes(".")){
    handelMaill(document.getElementById("mailto").value)
  }else{
    document.getElementById("mailto").value=""
    setPlacehold(locale=="ar"?"لم تدخل بريدك الالكترونى":"You did not enter an email address.")
  }

  
}

  return (
    <div className={`newsletter ${className}`}>
      <div className="overlap-group">

<div>
        <img className="clip-path-group" alt="Clip path group" src={clipPathGroup} />
</div>

<div className="flex justify-between items-center lg:flex-col-reverse gap-12">

<div className="frame-7 w-[50%] lg:w-[100%] ">

<div className="frame-8">
  <div className="text-wrapper-7"
   style={{
                        fontFamily:
                          screenWidth < 500
                            ? "var(--arabic-heading-h5-font-family)"
                            : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h3-font-family)"
                            : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h1-font-family)"
                            : "var(--arabic-heading-h1-font-family)",
                        fontSize:
                          screenWidth < 500
                            ? "var(--arabic-heading-h5-font-size)"
                            : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h3-font-size)"
                            : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h1-font-size)"
                            : "var(--arabic-heading-h1-font-size)",
                        fontStyle:
                          screenWidth < 500
                            ? "var(--arabic-heading-h5-font-style)"
                            : screenWidth >= 500&& screenWidth < 834
                            ? "var(--arabic-heading-h3-font-style)"
                            : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h1-font-style)"
                            : "var(--arabic-heading-h1-font-style)",
                        fontWeight:
                          screenWidth < 500
                            ? "var(--arabic-heading-h5-font-weight)"
                            : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h3-font-weight)"
                            : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h1-font-weight)"
                            : "var(--arabic-heading-h1-font-weight)",
                        letterSpacing:
                          screenWidth < 500
                            ? "var(--arabic-heading-h4-letter-spacing)"
                            : screenWidth >= 500 && screenWidth < 834
                            ? "var(--arabic-heading-h3-letter-spacing)"
                            : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h1-letter-spacing)"
                            : "var(--arabic-heading-h1-letter-spacing)",
                        lineHeight:
                          screenWidth < 834
                            ? "var(--arabic-heading-h3-line-height)"
                            : screenWidth >= 834 && screenWidth < 1550
                            ? "var(--arabic-heading-h1-line-height)"
                            : "var(--arabic-heading-h1-line-height)",
                      }} >{t("newsletter")}</div>
  <p className={screenWidth<2000?"text-wrapper-8":"text-wrapper-8"}>
{t("footer1")} 
   <br />
   {t("footer2")} 
   </p>
</div>


<div className={screenWidth<2000?"frame-9":"frame-9"}>

  <div className={screenWidth<2000?"text-wrapper-10 h-full":"text-wrapper-10 h-full"}>
    <input id="mailto" type="email" placeholder={placehod} className={placehod=="لم تدخل بريدك الالكترونى"?"w-full h-full focus:outline-none placeholder-red-600":"w-full h-full focus:outline-none"} style={{background:"border-box"}} />
  </div>
  <button onClick={handelSubmit}>
  <div  className={screenWidth<2000?"frame-10":"frame-10"}>
    <div className={screenWidth<2000?"text-wrapper-9":"text-wrapper-9"}> { locale=="ar"?"اشتراك":"Subscribe"}</div>
    <Paperplanetilt className={screenWidth<2000?"paper-plane-tilt":"paper-plane-tilt"} />
  </div>
  </button>
</div>





</div>
          <img className=
          {
            screenWidth<2000?"rectangle h-[407px] sm:h-auto"
            :screenWidth<3000?"rectangle  sm:h-auto":"rectangle h-[1000px] sm:h-auto"
            } alt="Rectangle" src="/img/rectangle-18.png" />



  
</div>


      </div>
      <Toaster containerStyle={{zIndex:"10000000000000000000000000000"}} />
    </div>

  );
};

Newsletter.propTypes = {
  clipPathGroup: PropTypes.string,
};
