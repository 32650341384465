/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatStrokeWeightLight5 = ({ className }) => {
  return (
    <svg
      className={`format-stroke-weight-light-5 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        stroke="#343330"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M20 19C19.3704 19.8395 18.4925 20.4597 17.4908 20.7726C16.4892 21.0855 15.4144 21.0753 14.4189 20.7434C13.4233 20.4116 12.5574 19.7749 11.9438 18.9236C11.3302 18.0722 11 17.0494 11 16C11 14.9506 11.3302 13.9278 11.9438 13.0764C12.5574 12.2251 13.4233 11.5884 14.4189 11.2566C15.4144 10.9247 16.4892 10.9145 17.4908 11.2274C18.4925 11.5403 19.3704 12.1605 20 13"
        stroke="#343330"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
