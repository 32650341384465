/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { FormatOutlineWeightDuotone11 } from "../../icons/FormatOutlineWeightDuotone11";
import { FormatOutlineWeightFill11 } from "../../icons/FormatOutlineWeightFill11";
import { FormatOutlineWeightLight11 } from "../../icons/FormatOutlineWeightLight11";
import { FormatStrokeWeightBold10 } from "../../icons/FormatStrokeWeightBold10";
import { FormatStrokeWeightDuotone11 } from "../../icons/FormatStrokeWeightDuotone11";
import { FormatStrokeWeightLight10 } from "../../icons/FormatStrokeWeightLight10";
import { FormatStrokeWeightRegular10 } from "../../icons/FormatStrokeWeightRegular10";
import { FormatStrokeWeightThin10 } from "../../icons/FormatStrokeWeightThin10";
import "./style.css";

export const FacebookLogo = ({ format, weight }) => {
  return (
    <>
      {((format === "outline" && weight === "bold") ||
        (format === "outline" && weight === "light") ||
        (format === "outline" && weight === "regular") ||
        (format === "outline" && weight === "thin")) && <FormatOutlineWeightLight11 className="instance-node-2" />}

      {weight === "fill" && <FormatOutlineWeightFill11 className="instance-node-2" />}

      {format === "outline" && weight === "duotone" && <FormatOutlineWeightDuotone11 className="instance-node-2" />}

      {weight === "regular" && format === "stroke" && <FormatStrokeWeightRegular10 className="instance-node-2" />}

      {weight === "thin" && format === "stroke" && <FormatStrokeWeightThin10 className="instance-node-2" />}

      {weight === "light" && format === "stroke" && <FormatStrokeWeightLight10 className="instance-node-2" />}

      {weight === "bold" && format === "stroke" && <FormatStrokeWeightBold10 className="instance-node-2" />}

      {weight === "duotone" && format === "stroke" && <FormatStrokeWeightDuotone11 className="instance-node-2" />}
    </>
  );
};

FacebookLogo.propTypes = {
  format: PropTypes.oneOf(["outline", "stroke"]),
  weight: PropTypes.oneOf(["duotone", "regular", "thin", "bold", "fill", "light"]),
};
