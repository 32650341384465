/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatStrokeWeightRegular6 = ({ className }) => {
  return (
    <svg
      className={`format-stroke-weight-regular-6 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M20 16L14 12V20L20 16Z"
        stroke="#343330"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M3 16.0001C3 19.7389 3.38375 21.9314 3.67625 23.0589C3.7536 23.3655 3.90258 23.6495 4.11096 23.8875C4.31935 24.1254 4.58119 24.3105 4.875 24.4276C9.065 26.0439 16 26.0001 16 26.0001C16 26.0001 22.935 26.0439 27.125 24.4276C27.4197 24.3112 27.6826 24.1263 27.8919 23.8883C28.1012 23.6504 28.2509 23.3661 28.3288 23.0589C28.6213 21.9339 29.005 19.7389 29.005 16.0001C29.005 12.2614 28.6213 10.0689 28.3288 8.94137C28.2518 8.63302 28.1024 8.34746 27.8931 8.10834C27.6838 7.86922 27.4205 7.68343 27.125 7.56637C22.935 5.95637 16 6.00012 16 6.00012C16 6.00012 9.065 5.95637 4.875 7.57262C4.57954 7.68968 4.31623 7.87547 4.1069 8.11459C3.89756 8.35371 3.74822 8.63927 3.67125 8.94762C3.38375 10.0676 3 12.2614 3 16.0001Z"
        stroke="#343330"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
