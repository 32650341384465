/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatOutlineWeightRegular1 = ({ className }) => {
  return (
    <svg
      className={`format-outline-weight-regular-1 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M28.865 26.5C26.9612 23.2088 24.0275 20.8488 20.6037 19.73C22.2973 18.7218 23.613 17.1856 24.349 15.3572C25.0849 13.5289 25.2004 11.5095 24.6775 9.6092C24.1547 7.70889 23.0226 6.03274 21.4549 4.83816C19.8873 3.64357 17.9709 2.9966 16 2.9966C14.0291 2.9966 12.1126 3.64357 10.545 4.83816C8.97737 6.03274 7.84522 7.70889 7.3224 9.6092C6.79958 11.5095 6.91501 13.5289 7.65096 15.3572C8.38691 17.1856 9.70268 18.7218 11.3962 19.73C7.97247 20.8475 5.03872 23.2075 3.13497 26.5C3.06516 26.6138 3.01885 26.7405 2.99878 26.8725C2.97872 27.0045 2.98529 27.1392 3.01813 27.2687C3.05096 27.3981 3.10939 27.5197 3.18996 27.6262C3.27053 27.7327 3.37162 27.8219 3.48725 27.8887C3.60289 27.9555 3.73073 27.9985 3.86323 28.0151C3.99574 28.0316 4.13022 28.0215 4.25875 27.9853C4.38728 27.9491 4.50725 27.8875 4.61158 27.8041C4.71592 27.7208 4.8025 27.6174 4.86622 27.5C7.22122 23.43 11.3837 21 16 21C20.6162 21 24.7787 23.43 27.1337 27.5C27.1974 27.6174 27.284 27.7208 27.3884 27.8041C27.4927 27.8875 27.6127 27.9491 27.7412 27.9853C27.8697 28.0215 28.0042 28.0316 28.1367 28.0151C28.2692 27.9985 28.3971 27.9555 28.5127 27.8887C28.6283 27.8219 28.7294 27.7327 28.81 27.6262C28.8906 27.5197 28.949 27.3981 28.9818 27.2687C29.0147 27.1392 29.0212 27.0045 29.0012 26.8725C28.9811 26.7405 28.9348 26.6138 28.865 26.5ZM8.99997 12C8.99997 10.6155 9.41051 9.26215 10.1797 8.11101C10.9489 6.95987 12.0421 6.06266 13.3212 5.53284C14.6003 5.00303 16.0077 4.86441 17.3656 5.1345C18.7235 5.4046 19.9708 6.07129 20.9497 7.05025C21.9287 8.02922 22.5954 9.2765 22.8655 10.6344C23.1356 11.9922 22.9969 13.3997 22.4671 14.6788C21.9373 15.9579 21.0401 17.0511 19.889 17.8203C18.7378 18.5895 17.3844 19 16 19C14.1441 18.998 12.3647 18.2599 11.0524 16.9476C9.74009 15.6352 9.00196 13.8559 8.99997 12Z"
        fill="#343330"
      />
    </svg>
  );
};
