/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatOutlineWeightBold8 = ({ className }) => {
  return (
    <svg
      className={`format-outline-weight-bold-8 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M27.265 26.195L19.64 14.22L27.105 6.00875C27.367 5.71348 27.502 5.32687 27.4808 4.93273C27.4595 4.53859 27.2838 4.16869 26.9917 3.90323C26.6996 3.63777 26.3147 3.49814 25.9203 3.51462C25.5259 3.53109 25.154 3.70235 24.885 3.99125L17.9763 11.5962L13.265 4.195C13.1296 3.98212 12.9427 3.80683 12.7216 3.68535C12.5005 3.56386 12.2523 3.50012 12 3.5H6.00001C5.73131 3.50012 5.46757 3.57242 5.23637 3.70934C5.00517 3.84626 4.81499 4.04277 4.68572 4.27833C4.55644 4.51389 4.49281 4.77985 4.50148 5.04841C4.51016 5.31697 4.59081 5.57827 4.73501 5.805L12.36 17.78L4.89001 25.9912C4.75476 26.1365 4.64969 26.3072 4.58089 26.4934C4.5121 26.6796 4.48094 26.8776 4.48922 27.0759C4.49751 27.2742 4.54508 27.469 4.62916 27.6488C4.71325 27.8286 4.83219 27.9899 4.97909 28.1234C5.12599 28.2569 5.29792 28.3599 5.48493 28.4265C5.67193 28.493 5.87029 28.5218 6.0685 28.5111C6.26671 28.5005 6.46083 28.4506 6.63961 28.3643C6.81839 28.2781 6.97828 28.1572 7.11001 28.0088L14.0238 20.4037L18.735 27.805C18.8704 28.0179 19.0573 28.1932 19.2784 28.3147C19.4995 28.4361 19.7477 28.4999 20 28.5H26C26.2687 28.4999 26.5324 28.4276 26.7636 28.2907C26.9949 28.1537 27.185 27.9572 27.3143 27.7217C27.4436 27.4861 27.5072 27.2202 27.4985 26.9516C27.4899 26.683 27.4092 26.4217 27.265 26.195ZM20.8238 25.5L8.73251 6.5H11.1763L23.2675 25.5H20.8238Z"
        fill="#343330"
      />
    </svg>
  );
};
