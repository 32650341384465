/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatOutlineWeightFill = ({ className }) => {
  return (
    <svg
      className={`format-outline-weight-fill ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M26.7075 12.7075L16.7075 22.7075C16.6146 22.8005 16.5043 22.8742 16.3829 22.9246C16.2615 22.9749 16.1314 23.0008 16 23.0008C15.8686 23.0008 15.7385 22.9749 15.6171 22.9246C15.4957 22.8742 15.3854 22.8005 15.2925 22.7075L5.29249 12.7075C5.15248 12.5676 5.05712 12.3894 5.01846 12.1953C4.97981 12.0012 4.99962 11.8 5.07537 11.6172C5.15112 11.4344 5.27942 11.2782 5.44401 11.1683C5.60861 11.0584 5.8021 10.9998 5.99999 11H26C26.1979 10.9998 26.3914 11.0584 26.556 11.1683C26.7206 11.2782 26.8489 11.4344 26.9246 11.6172C27.0004 11.8 27.0202 12.0012 26.9815 12.1953C26.9429 12.3894 26.8475 12.5676 26.7075 12.7075Z"
        fill="#343330"
      />
    </svg>
  );
};
