/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const FormatStrokeWeightThin3 = ({ color = "#343330", className }) => {
  return (
    <svg
      className={`format-stroke-weight-thin-3 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path className="path" d="M11.5 8H8L10.5 10.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

FormatStrokeWeightThin3.propTypes = {
  color: PropTypes.string,
};
