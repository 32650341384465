/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatOutlineWeightBold1 = ({ className }) => {
  return (
    <svg
      className={`format-outline-weight-bold-1 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M29.2976 26.25C27.5838 23.2421 24.9077 20.8985 21.7001 19.5963C23.2952 18.3999 24.4734 16.732 25.0679 14.8288C25.6624 12.9257 25.6431 10.8837 25.0125 8.99209C24.382 7.10052 23.1723 5.45529 21.5548 4.28945C19.9373 3.12361 17.9939 2.49625 16.0001 2.49625C14.0062 2.49625 12.0628 3.12361 10.4453 4.28945C8.82779 5.45529 7.61809 7.10052 6.98756 8.99209C6.35704 10.8837 6.33767 12.9257 6.93218 14.8288C7.52669 16.732 8.70495 18.3999 10.3001 19.5963C7.09246 20.8985 4.41636 23.2421 2.70256 26.25C2.59636 26.4208 2.52558 26.6111 2.49443 26.8098C2.46328 27.0084 2.47238 27.2113 2.52121 27.4064C2.57003 27.6014 2.65758 27.7847 2.77864 27.9453C2.8997 28.1058 3.05181 28.2404 3.22592 28.341C3.40003 28.4416 3.59259 28.5061 3.79215 28.5308C3.99171 28.5555 4.1942 28.5398 4.38758 28.4847C4.58096 28.4296 4.76127 28.3361 4.91781 28.2099C5.07435 28.0837 5.20392 27.9273 5.29881 27.75C7.56381 23.835 11.5638 21.5 16.0001 21.5C20.4363 21.5 24.4363 23.8363 26.7013 27.75C26.9069 28.0807 27.2331 28.3184 27.6109 28.4129C27.9886 28.5075 28.3883 28.4514 28.7255 28.2566C29.0626 28.0618 29.3108 27.7436 29.4176 27.3691C29.5244 26.9946 29.4813 26.5933 29.2976 26.25ZM9.50006 12C9.50006 10.7144 9.88127 9.45771 10.5955 8.38879C11.3097 7.31987 12.3249 6.48675 13.5126 5.99478C14.7003 5.50281 16.0073 5.37409 17.2681 5.6249C18.529 5.8757 19.6872 6.49476 20.5962 7.40381C21.5053 8.31285 22.1244 9.47104 22.3752 10.7319C22.626 11.9928 22.4972 13.2997 22.0053 14.4874C21.5133 15.6752 20.6802 16.6903 19.6113 17.4046C18.5423 18.1188 17.2856 18.5 16.0001 18.5C14.2768 18.498 12.6246 17.8126 11.4061 16.594C10.1875 15.3754 9.50204 13.7233 9.50006 12Z"
        fill="#343330"
      />
    </svg>
  );
};
