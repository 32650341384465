import React from "react";
import ReactDOMClient from "react-dom/client";
import { App} from "./App";
import { TranslationProvider } from "./translations/TranslationContext";

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(
    <TranslationProvider>
<App />
</TranslationProvider>

);
