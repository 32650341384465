/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Paperplanetilt = ({ className }) => {
  return (
    <svg
      className={`paperplanetilt ${className}`}
      fill="none"
      height="19"
      viewBox="0 0 18 19"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.2703 3.61801C16.2703 3.61801 16.2703 3.62504 16.2703 3.62855L12.1781 17.1243C12.1161 17.3435 11.9886 17.5385 11.8127 17.6832C11.6367 17.8279 11.4207 17.9152 11.1937 17.9336C11.1613 17.9364 11.129 17.9379 11.0967 17.9379C10.8839 17.9385 10.6754 17.8783 10.4957 17.7643C10.3161 17.6503 10.1728 17.4873 10.0828 17.2945L7.52338 12.0421C7.49777 11.9895 7.48922 11.9302 7.49893 11.8725C7.50863 11.8147 7.53611 11.7615 7.57752 11.7201L11.65 7.64762C11.7511 7.54127 11.8066 7.39966 11.8047 7.25298C11.8028 7.1063 11.7437 6.96616 11.64 6.86243C11.5362 6.75871 11.3961 6.69961 11.2494 6.69773C11.1027 6.69585 10.9611 6.75135 10.8548 6.85238L6.78018 10.9249C6.73881 10.9663 6.68555 10.9938 6.62782 11.0035C6.5701 11.0132 6.51078 11.0046 6.45815 10.979L1.20088 8.42035C0.9953 8.32172 0.824542 8.16295 0.711235 7.96509C0.597928 7.76722 0.547422 7.53959 0.566408 7.31237C0.585394 7.08514 0.672976 6.86905 0.81755 6.69273C0.962123 6.51641 1.15686 6.38818 1.37596 6.32504L14.8717 2.23285H14.8823C15.0744 2.17887 15.2775 2.17697 15.4706 2.22737C15.6637 2.27776 15.84 2.37863 15.9813 2.51962C16.1225 2.66061 16.2238 2.83664 16.2745 3.02966C16.3253 3.22269 16.3238 3.42575 16.2703 3.61801Z"
        fill="white"
      />
    </svg>
  );
};
