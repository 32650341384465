/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Clockafternoon8 = ({ className }) => {
  return (
    <svg
      className={`clockafternoon-8 ${className}`}
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7 12.25C9.8995 12.25 12.25 9.8995 12.25 7C12.25 4.10051 9.8995 1.75 7 1.75C4.10051 1.75 1.75 4.10051 1.75 7C1.75 9.8995 4.10051 12.25 7 12.25Z"
        stroke="#616161"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.875"
      />
      <path
        className="path"
        d="M10.0625 7H7L9.1875 9.1875"
        stroke="#616161"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.875"
      />
    </svg>
  );
};
