import React, { useEffect } from "react";
import { useWindowWidth } from "../../breakpoints";
import "./style.css";
import ReactVisibilitySensor from "react-visibility-sensor";
import { Spring } from "react-spring/renderprops";
import { useTranslation } from "../../translations/TranslationContext";

export const TimeLineAt = () => {

  const { t, changeLocale, locale } = useTranslation();

  const screenWidth = useWindowWidth();
  const h2Styles = {
    fontSize: "82px"
    // color: "white",
    // backgroundColor: "black",
    // padding: "16px 32px"
  };


  return (<>

  {/* <div className="mt-[200vh]"></div> */}

        
        
        <div className="timeline2 d-md-none d-block ">
                    <ReactVisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              delay={300}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateY(0)" : "translateY(200px)"
              }}
            >
              {(props) => 
            <div style={{...props}} className="item item1 w-full">
            <b style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("may142024")}</b>
            <br />
            <span style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("registrationStarts")}</span>
        </div>
              }
            </Spring>
          )}
        </ReactVisibilitySensor>
                    <ReactVisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              delay={300}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateY(0)" : "translateY(200px)"
              }}
            >
              {(props) => 
            <div style={{...props}} className="item item2 aos-init aos-animate w-full" data-aos="fade-up" data-aos-delay="100">
              <b style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>
{t("may242024")}  
            </b>
              <br />
              <span style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("registrationCloses")}</span>
        </div>
              }
            </Spring>
          )}
        </ReactVisibilitySensor>
                    <ReactVisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              delay={300}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateY(0)" : "translateY(200px)"
              }}
            >
              {(props) => 
            <div style={{...props}} className="item item3 aos-init aos-animate w-full" data-aos="fade-up" data-aos-delay="100">
            <b style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("may242024ToJune102024")}</b>
            <br />
            <span style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("sortingAndAcceptanceProcess")}</span>
        </div>
              }
            </Spring>
          )}
        </ReactVisibilitySensor>
                    <ReactVisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              delay={300}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateY(0)" : "translateY(200px)"
              }}
            >
              {(props) => 
            <div style={{...props}} className="item item4 aos-init aos-animate w-full" data-aos="fade-up" data-aos-delay="100">
            <b style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("june242024")}</b>
            <br />
            <span style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("programStarts")}</span>
        </div>
              }
            </Spring>
          )}
        </ReactVisibilitySensor>
                    <ReactVisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              delay={300}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateY(0)" : "translateY(200px)"
              }}
            >
              {(props) => 
            <div style={{...props}} className="item item5 aos-init aos-animate w-full" data-aos="fade-up" data-aos-delay="100">
            <b style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("december202024")}</b>
            <br />
            <span style={{fontFamily:"var(--arabic-heading-h4-font-family)"}}>{t("programConclusionAndGraduationCeremony")}</span>
        </div>
              }
            </Spring>
          )}
        </ReactVisibilitySensor>











        </div>
        
        





 </> );
};
