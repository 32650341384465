/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Youtubelogo = ({ className }) => {
  return (
    <svg
      className={`youtubelogo ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12.5 10L8.75 7.5V12.5L12.5 10Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <path
        className="path"
        d="M1.875 10.0001C1.875 12.3368 2.11484 13.7071 2.29766 14.4118C2.346 14.6035 2.43911 14.7809 2.56935 14.9297C2.69959 15.0784 2.86325 15.1941 3.04688 15.2673C5.66563 16.2774 10 16.2501 10 16.2501C10 16.2501 14.3344 16.2774 16.9531 15.2673C17.1373 15.1945 17.3016 15.079 17.4324 14.9302C17.5632 14.7815 17.6568 14.6038 17.7055 14.4118C17.8883 13.7087 18.1281 12.3368 18.1281 10.0001C18.1281 7.66335 17.8883 6.29304 17.7055 5.58835C17.6574 5.39564 17.564 5.21716 17.4332 5.06771C17.3024 4.91826 17.1378 4.80214 16.9531 4.72898C14.3344 3.72273 10 3.75007 10 3.75007C10 3.75007 5.66563 3.72273 3.04688 4.73289C2.86221 4.80605 2.69765 4.92217 2.56681 5.07162C2.43597 5.22107 2.34264 5.39955 2.29453 5.59226C2.11484 6.29226 1.875 7.66335 1.875 10.0001Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </svg>
  );
};
