/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatOutlineWeightThin = ({ className }) => {
  return (
    <svg
      className={`format-outline-weight-thin ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M26.3537 12.3535L16.3537 22.3535C16.3073 22.4 16.2521 22.4369 16.1914 22.4621C16.1307 22.4872 16.0657 22.5002 16 22.5002C15.9343 22.5002 15.8692 22.4872 15.8085 22.4621C15.7478 22.4369 15.6927 22.4 15.6462 22.3535L5.64622 12.3535C5.59977 12.3071 5.56292 12.2519 5.53778 12.1912C5.51263 12.1305 5.49969 12.0655 5.49969 11.9998C5.49969 11.9341 5.51263 11.869 5.53778 11.8083C5.56292 11.7476 5.59977 11.6925 5.64622 11.646C5.74004 11.5522 5.86729 11.4995 5.99997 11.4995C6.06567 11.4995 6.13072 11.5125 6.19142 11.5376C6.25212 11.5627 6.30727 11.5996 6.35372 11.646L16 21.2923L25.6462 11.646C25.6927 11.5996 25.7478 11.5627 25.8085 11.5376C25.8692 11.5125 25.9343 11.4995 26 11.4995C26.0657 11.4995 26.1307 11.5125 26.1914 11.5376C26.2521 11.5627 26.3073 11.5996 26.3537 11.646C26.4002 11.6925 26.437 11.7476 26.4622 11.8083C26.4873 11.869 26.5003 11.9341 26.5003 11.9998C26.5003 12.0655 26.4873 12.1305 26.4622 12.1912C26.437 12.2519 26.4002 12.3071 26.3537 12.3535Z"
        fill="#343330"
      />
    </svg>
  );
};
