import React, { useEffect, useState } from "react";
import { useWindowWidth } from "../../breakpoints";
import { ArrowUpLeft } from "../ArrowUpLeft";
import { CaretDown } from "../CaretDown";
import { Copyright } from "../Copyright";
import { FacebookLogo } from "../FacebookLogo";
import { Footer } from "../Footer";
import { House } from "../House";
import { InstagramLogo } from "../InstagramLogo";
import { LinkedinLogo } from "../LinkedinLogo";
import { List } from "../List";
import { Navbar } from "../Navbar";
import { Newsletter } from "../Newsletter";
import { PaperPlaneTilt } from "../PaperPlaneTilt";
import { TextHover } from "../TextHover";
import { User } from "../User";
import { XLogo } from "../XLogo";
import { YoutubeLogo } from "../YoutubeLogo";
import "./style.css";
import { Clockafternoon8 } from "../../icons/Clockafternoon8";
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from "formik";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from "../../translations/TranslationContext";

export const Form = () => {
  let baseURL="https://api.swic.sa/api"
  let navigate=useNavigate()
  async function individualsRegister(data) {
      await axios.post(`${baseURL}/CreateUser`,data,{headers:{
        "Accept":"application/json",
        "Accept-Language":"ar",
      }})
        .then(function (response) {
          // setPage(response.data)
          console.log(response.data);
          toast.success("Success")
          navigate("/home")

          // toast()
        })
        .catch(function (error) {
          console.log(error);
          toast.error(error?.response?.data?.message)
        });
    
}
const { t, changeLocale, locale } = useTranslation();

  const screenWidth = useWindowWidth();
let [typeInput,setTypeInput]=useState(1)
let [person,setPersonUpdate]=useState(0)
let [businesSector,setBusinesSector]=useState(0)
let [government,setGovernment]=useState(0)
  function handelInput(param){
    setTypeInput(param)
}

function handelBusinesSector(param){
  if(param==1&&document.getElementById("name_distination").value&&
  document.getElementById("commercial_register").value){
    setBusinesSector(param)
  }
  if(param==2&&document.getElementById("first_name").value&&
  document.getElementById("father_name").value&&
  document.getElementById("grandfather_name").value&&
  document.getElementById("family_name").value&&
  document.getElementById("mobile_number").value){
    setBusinesSector(param)
}
}


function handelGovernment(param){
    if(param==1&&document.getElementById("name_distination").value){
      setGovernment(param)
    }
    if(param==2&&document.getElementById("first_name").value&&
    document.getElementById("father_name").value&&
    document.getElementById("grandfather_name").value&&
    document.getElementById("family_name").value&&
    document.getElementById("mobile_number").value){
      setGovernment(param)
    }
}

function handelPerson(){

if(document.getElementById("gender").value&&
document.getElementById("first_name").value&&
document.getElementById("grandfather_name").value&&
document.getElementById("national_id_number").value&&
document.getElementById("family_name").value&&
document.getElementById("nationality").value&&
document.getElementById("mobile_number").value){
  setPersonUpdate(1)
}

}


const formik = useFormik({
  initialValues:{
    "type": "Individuals", //'Individuals', 'Business Sector', 'Government Entities'
    "national_id_number": "",
    "email": "",
    "first_name": "",
    "father_name": "",
    "grandfather_name": "",
    "family_name": "",
    "nationality": "",
    "gender": "Male",
    "mobile_number": "",
    "password": "",
    "password_confirmation": ""
  },
  onSubmit: individualsRegister,
});

const businessSectorForm = useFormik({
  initialValues:{
    "type": "Business Sector", //'Individuals', 'Business Sector', 'Government Entities'
    "commercial_register":"",
    "name_distination":"",
    "email": "",
    "first_name": "",
    "father_name": "",
    "grandfather_name": "",
    "family_name": "",
    "mobile_number": "",
    "password": "",
    "password_confirmation": ""
  },
  onSubmit: individualsRegister,
});
const governmentSectorForm = useFormik({
  initialValues:{
    "type": "Government Entities", //'Individuals', 'Business Sector', 'Government Entities'
    "name_distination":"",
    "email": "",
    "first_name": "",
    "father_name": "",
    "grandfather_name": "",
    "family_name": "",
    "mobile_number": "",
    "password": "",
    "password_confirmation": ""
  },
  onSubmit: individualsRegister,
});



console.log(typeInput);


  return (<>

<div  dir={locale == "ar" ? "ltr" : "rtl"} className="form p-[5%] mt-[90px]">
<div className=" main-container">
      <p className="register-user-new">{t("newUserRegistration")}</p>
      <div className="w-full flex justify-center items-start gap-[3%] md:flex-col-reverse md:items-end">
        <div className="w-[65%] md:w-full">
          <div className="mb-[5%]">
            <span className="user-type mb-2">{t("userType")}</span>
            <div className="flex items-center justify-end gap-[2%] md:flex-col-reverse md:items-end ">
              <button onClick={()=>handelInput(3)} className={typeInput==3?"button-placeholder border-click md:mt-2":"button-placeholder border-color md:mt-2"}>
                <span className="government-agencies">{t("governmentalEntities")}</span>
                <div className="frame1-4">
                  <span className="text"></span>
                  <div className={typeInput==3?"radio-button-b":"radio-button-7"}></div>
                </div></button>
                <button onClick={()=>handelInput(2)} className={typeInput==2?"placeholder border-click md:mt-2":"placeholder border-color md:mt-2"}>
                <span className="sector-business">{t("businessSector")}</span>
                <div className="frame1-5">
                  <span className="text-6"></span>
                  <div className={typeInput==2?"radio-button-b":"radio-button-7"}></div>
                </div></button>
                <button onClick={()=>handelInput(1)} className={typeInput==1?"placeholder-8 border-click md:mt-2":"placeholder-8 border-color md:mt-2"}>
                <span className="individuals">{t("individuals")}</span>
                <div className="frame1-9">
                  <span className="text-a"></span>
                  <div className={typeInput==1?"radio-button-b":"radio-button-7"}></div>
                </div>
              </button>
            </div>
          </div>

<form onSubmit={formik.handleSubmit}>
          {typeInput==1?person==0?

                    <div className="">
            <div className="frame1-d">
              <div className="frame1-12 md:flex-col">
                <div className="input-field-13">
                  <span className="label-14">{t("nationalIDNumber")}<span className="text-red-500	">*</span></span>
                  <div className="input-container-15">
                  <input
                           id="national_id_number"
                           name="national_id_number"
                           type="text"
                           onChange={formik.handleChange}
                           value={formik.values.national_id_number}
                           className="w-full h-full text-end p-1 rounded-[5%]" />
                  </div>
                </div>
                <div className="input-field-16">
                  <span className="label-17">{t("idType")}<span className="text-red-500	">*</span></span>
                  <div className="input-container-18 px-[5%]">
                  <select className="w-full h-full  p-1 rounded-[10%]">
                  <option value="" className="hidden" disabled selected>{t('select')}</option>
        <option className="p-3" value="1">{t('citizenID')}</option>
        <option value="2">{t('residentID')}</option>
        <option value="3">{t('passport')}</option>
				          </select>
                  </div>
                </div>
              </div>
              <div className="frame1-12 md:flex-col mt-3">

                <div className="input-field-16">
                  <span className="label-17">{t("gender")}<span className="text-red-500	">*</span></span>
                  <div className="input-container-18 px-[5%]">
                  <select  className="w-full h-full  p-1 rounded-[5%]"
                    id="gender"
                    name="gender"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.gender}
                  >
        <option value="" className="hidden" disabled selected>{t('select')}</option>
        <option value="male">{t('male')}</option>
        <option value="female">{t('female')}</option>
                </select>
                  </div>
                </div>
                <div className="input-field-13">
                  <span className="label-14">{t("nationality")}<span className="text-red-500	">*</span></span>
                  <div className="input-container-15 px-[5%]">
                  <select 
                                                               id="nationality"
                                                               name="nationality"
                                                               type="text"
                                                               onChange={formik.handleChange}
                                                               value={formik.values.nationality}
                    className="w-full h-full  p-1 rounded-[5%]" >

{locale=="ar"?
                      <>
                      <option value="" className="hidden" disabled selected>أختر</option>
					<option value="100">السعودية</option>
					<option value="110">سوريا</option>
					<option value="111">لبنان</option>
					<option value="112">الأردن</option>
					<option value="113">الكويت</option>
					<option value="114">العراق</option>
					<option value="115">اليمن</option>
					<option value="116">البحرين</option>
					<option value="117">قطر</option>
					<option value="119">عمان</option>
					<option value="120">الإمارات</option>
					<option value="128">أفغانستان</option>
					<option value="129">باكستان</option>
					<option value="130">بنغلادش</option>
					<option value="131">الهند</option>
					<option value="132">سريلانكا</option>
					<option value="133">ماينامار</option>
					<option value="134">تايلاند</option>
					<option value="135">ماليزيا</option>
					<option value="136">سنغافوره</option>
					<option value="137">لاو</option>
					<option value="138">كامبوديا</option>
					<option value="140">فيتنام</option>
					<option value="141">منغوليا</option>
					<option value="142">الصين الشعبية</option>
					<option value="143">تايوان</option>
					<option value="144">ماكاو</option>
					<option value="145">هونج كونج</option>
					<option value="146">كوريا الشمالية</option>
					<option value="147">كوريا الجنوبية</option>
					<option value="148">اليابان</option>
					<option value="150">الفلبين</option>
					<option value="151">بروناي دار السلام</option>
					<option value="152">أندونيسيا</option>
					<option value="154">نيبال</option>
					<option value="155">مالديف</option>
					<option value="156">أذربيجان</option>
					<option value="157">أرمينيا</option>
					<option value="158">أوزباكستان</option>
					<option value="159">تركمانستان</option>
					<option value="160">جورجيا</option>
					<option value="162">طاجكستان</option>
					<option value="163">قيرقيزيستان</option>
					<option value="164">كازاخستان</option>
					<option value="165">فلسطين</option>
					<option value="166">بيلاروس</option>
					<option value="167">بهوتان</option>
					<option value="168">جزيرة بوفيت</option>
					<option value="169">بريتش انديان اوشن</option>
					<option value="170">جزيرة كريسماس</option>
					<option value="171">جزر كوكوس (كيلينج)</option>
					<option value="172">ميكرونيسيا</option>
					<option value="173">جزر ماريانا الشمالية</option>
					<option value="174">تيمور ليستي</option>
					<option value="175">انتاركتيكا</option>
					<option value="210">المغرب</option>
					<option value="211">الجزائر</option>
					<option value="212">تونس</option>
					<option value="213">ليبيا</option>
					<option value="214">مصر</option>
					<option value="215">السودان</option>
					<option value="217">موريتانيا</option>
					<option value="218">مالي</option>
					<option value="219">النيجر</option>
					<option value="220">تشاد</option>
					<option value="221">السنيغال</option>
					<option value="222">جامبيا</option>
					<option value="223">غينيا</option>
					<option value="224">سيراليون</option>
					<option value="225">ليبريا</option>
					<option value="226">غانا</option>
					<option value="227">ساحل العاج(كوتديفوار</option>
					<option value="229">توجو</option>
					<option value="230">بنين</option>
					<option value="231">نيجيريا</option>
					<option value="232">الكاميرون</option>
					<option value="233">جمهورية افريقيا وسطى</option>
					<option value="234">جابون</option>
					<option value="235">كونغو</option>
					<option value="237">غينيا الاستوائية</option>
					<option value="238">رواندا</option>
					<option value="239">بوروندي</option>
					<option value="240">اثيوبيا</option>
					<option value="241">جيبوتي</option>
					<option value="242">الصومال</option>
					<option value="243">اوغندا</option>
					<option value="244">كينيا</option>
					<option value="245">تنزانيا</option>
					<option value="246">أنجولا</option>
					<option value="247">زامبيا</option>
					<option value="248">ملاوي</option>
					<option value="249">زيمبابوي</option>
					<option value="250">موزمبيق</option>
					<option value="251">بوتسوانا</option>
					<option value="252">ليسوتو</option>
					<option value="253">سوازي لاند</option>
					<option value="254">جنوب افريقيا</option>
					<option value="255">ناميبيا</option>
					<option value="256">سانت هيلينا</option>
					<option value="257">جزر القمر</option>
					<option value="258">مدغشقر</option>
					<option value="259">موريشيوس</option>
					<option value="260">ريونيون</option>
					<option value="261">سيشلس</option>
					<option value="262">جزر كيب فردي</option>
					<option value="263">أريتريا</option>
					<option value="264">بوركينا فاسو</option>
					<option value="265">كونجو</option>
					<option value="266">غينيا بيساو</option>
					<option value="267">مايوتي</option>
					<option value="268">ساو تومي &amp; برينسيبي</option>
					<option value="269">الصحراء الغربية</option>
					<option value="310">أستراليا</option>
					<option value="311">فيجي</option>
					<option value="312">ساموا</option>
					<option value="313">نيوزيلاندا</option>
					<option value="316">جوام</option>
					<option value="317">جزر سولومون</option>
					<option value="318">كاليدونيا الجديدة</option>
					<option value="320">بابوا(غينيا الجديدة)</option>
					<option value="321">بولينسيا الفرنسية</option>
					<option value="322">مناطق فرنسا الشمالية</option>
					<option value="323">جزر هيرد &amp; ماكدونالد</option>
					<option value="324">كيريباتي</option>
					<option value="325">ناورو</option>
					<option value="326">نايوي</option>
					<option value="327">بالاو</option>
					<option value="328">بيتكايرن</option>
					<option value="329">توكيلاو</option>
					<option value="330">تونجا</option>
					<option value="331">توفاليو</option>
					<option value="332">واليس &amp; فوتونا</option>
					<option value="333">جزر كوك</option>
					<option value="410">أمريكا</option>
					<option value="411">كندا</option>
					<option value="412">بورتوريكو</option>
					<option value="413">برمودا</option>
					<option value="414">بهاما</option>
					<option value="415">جامايكا</option>
					<option value="416">أنتيقوا و باربودا</option>
					<option value="417">دومينكا</option>
					<option value="418">بربدوس</option>
					<option value="419">ترينيداد و توباكو</option>
					<option value="421">هوندوراس</option>
					<option value="422">غوانا</option>
					<option value="423">سيرنام</option>
					<option value="424">غينيا الفرنسية</option>
					<option value="425">كوبا</option>
					<option value="426">هاييتي</option>
					<option value="427">جمهورية الدومينيكان</option>
					<option value="428">جواتيمالا</option>
					<option value="429">سلفادور</option>
					<option value="430">نيكاراجوا</option>
					<option value="431">كوستاريكا</option>
					<option value="432">بنما</option>
					<option value="433">كولومبيا</option>
					<option value="434">فنزويلا</option>
					<option value="435">أكوادور</option>
					<option value="436">بيرو</option>
					<option value="437">تشيلي</option>
					<option value="438">بوليفيا</option>
					<option value="439">البرازيل</option>
					<option value="440">براجواي</option>
					<option value="441">أرجواي</option>
					<option value="442">الأرجنتين</option>
					<option value="443">مكسيك</option>
					<option value="447">سانت لوسيا</option>
					<option value="448">بيليز</option>
					<option value="449">جزر الكيمان</option>
					<option value="450">فنواتو</option>
					<option value="451">سانت فينسنت</option>
					<option value="452">جزر مارشال</option>
					<option value="453">أروبا</option>
					<option value="454">جرينادا</option>
					<option value="455">جواديلوبي</option>
					<option value="456">مارتينيقوي</option>
					<option value="457">مونتسيرات</option>
					<option value="458">نيثرلاندز انتيليز</option>
					<option value="459">جزيرة نورفولك</option>
					<option value="460">سانت كيتس &amp; نيفيس</option>
					<option value="461">جزر أمريكا الثانوية</option>
					<option value="462">جزر فيرجين البريطاني</option>
					<option value="463">جزر فيرجيني الأمريكي</option>
					<option value="464">أنجويلا</option>
					<option value="500">آيرلندا الشمالية</option>
					<option value="510">جمهورية فنلندا</option>
					<option value="511">السويد</option>
					<option value="512">النرويج</option>
					<option value="513">مملكة الدانمارك</option>
					<option value="514">سويسرا</option>
					<option value="515">الجمهورية البرتغالية</option>
					<option value="516">جمهورية النمسا</option>
					<option value="517">جمهورية ألمانيا الاتحادية</option>
					<option value="518">هولندا</option>
					<option value="519">مملكة بلجيكا</option>
					<option value="520">لوكسمبورغ</option>
					<option value="521">المملكة المتحدة</option>
					<option value="522">الجمهورية الفرنسية</option>
					<option value="523">الجمهورّيّة الإيطالّيّة</option>
					<option value="524">اليونان</option>
					<option value="525">تركيا</option>
					<option value="526">إسبانيا</option>
					<option value="528">أيسلندا</option>
					<option value="529">جبل طارق</option>
					<option value="530">جمهورية مالطة</option>
					<option value="531">جمهورية قبرص</option>
					<option value="533">الجمهورية البولندية</option>
					<option value="535">هنغاريا</option>
					<option value="537">الجمهورية التشيكية</option>
					<option value="538">ألبانيا</option>
					<option value="539">بلغاريا</option>
					<option value="540">جمهورية رومانيا</option>
					<option value="541">جمهورية إستونيا</option>
					<option value="542">جمهورية لاتفيا</option>
					<option value="543">جمهورية لتوانيا</option>
					<option value="544">اوكرانيا</option>
					<option value="546">مولدافيا</option>
					<option value="547">مملكة إسبانيا</option>
					<option value="548">جمهورية كرواتيا</option>
					<option value="549">البوسنة والهرسك</option>
					<option value="551">روسيا</option>
					<option value="553">جمهورية سلوفاكيا</option>
					<option value="554">مقدونيا</option>
					<option value="555">موناكو</option>
					<option value="556">سان مارينو</option>
					<option value="557">ليختنشتاين</option>
					<option value="558">أندورا</option>
					<option value="559">أيسل أوف مان</option>
					<option value="560">جزر فولكلاند</option>
					<option value="561">جزر فاروي</option>
					<option value="562">جرينلاند</option>
					<option value="563">هولي سي</option>
					<option value="564">سانت بيري &amp; ميقويلون</option>
					<option value="565">جزر جورجيا &amp; ساندويش</option>
					<option value="566">سفالبارد &amp; جان ماين</option>
					<option value="567">جزر توركس &amp; كايكوس</option>
					<option value="568">صربيا</option>
					<option value="569">ساموا الأمريكيه</option>
					<option value="570">غوريسني</option>
					<option value="571">كوراكاو</option>
					<option value="572">جيرسي</option>
					<option value="573">الجبل الأسود</option>
					<option value="950">الإتحاد الأوربي</option>
					<option value="990">مقيم-نازح-قبائل-غيره</option>
					<option value="1000">جنوب السودان</option>
                      
                      
                      </>

:
<>
<option value="" className="hidden" disabled selected>Choose</option>
    <option value="100">Saudi Arabia</option>
    <option value="110">Syria</option>
    <option value="111">Lebanon</option>
    <option value="112">Jordan</option>
    <option value="113">Kuwait</option>
    <option value="114">Iraq</option>
    <option value="115">Yemen</option>
    <option value="116">Bahrain</option>
    <option value="117">Qatar</option>
    <option value="119">Oman</option>
    <option value="120">United Arab Emirates</option>
    <option value="128">Afghanistan</option>
    <option value="129">Pakistan</option>
    <option value="130">Bangladesh</option>
    <option value="131">India</option>
    <option value="132">Sri Lanka</option>
    <option value="133">Myanmar</option>
    <option value="134">Thailand</option>
    <option value="135">Malaysia</option>
    <option value="136">Singapore</option>
    <option value="137">Laos</option>
    <option value="138">Cambodia</option>
    <option value="140">Vietnam</option>
    <option value="141">Mongolia</option>
    <option value="142">People's Republic of China</option>
    <option value="143">Taiwan</option>
    <option value="144">Macau</option>
    <option value="145">Hong Kong</option>
    <option value="146">North Korea</option>
    <option value="147">South Korea</option>
    <option value="148">Japan</option>
    <option value="150">Philippines</option>
    <option value="151">Brunei Darussalam</option>
    <option value="152">Indonesia</option>
    <option value="154">Nepal</option>
    <option value="155">Maldives</option>
    <option value="156">Azerbaijan</option>
    <option value="157">Armenia</option>
    <option value="158">Uzbekistan</option>
    <option value="159">Turkmenistan</option>
    <option value="160">Georgia</option>
    <option value="162">Tajikistan</option>
    <option value="163">Kyrgyzstan</option>
    <option value="164">Kazakhstan</option>
    <option value="165">Palestine</option>
    <option value="166">Belarus</option>
    <option value="167">Bhutan</option>
    <option value="168">Bouvet Island</option>
    <option value="169">British Indian Ocean Territory</option>
    <option value="170">Christmas Island</option>
    <option value="171">Cocos (Keeling) Islands</option>
    <option value="172">Micronesia</option>
    <option value="173">Northern Mariana Islands</option>
    <option value="174">East Timor</option>
    <option value="175">Antarctica</option>
    <option value="210">Morocco</option>
    <option value="211">Algeria</option>
    <option value="212">Tunisia</option>
    <option value="213">Libya</option>
    <option value="214">Egypt</option>
    <option value="215">Sudan</option>
    <option value="217">Mauritania</option>
    <option value="218">Mali</option>
    <option value="219">Niger</option>
    <option value="220">Chad</option>
    <option value="221">Senegal</option>
    <option value="222">Gambia</option>
    <option value="223">Guinea</option>
    <option value="224">Sierra Leone</option>
    <option value="225">Liberia</option>
    <option value="226">Ghana</option>
    <option value="227">Ivory Coast (Côte d'Ivoire)</option>
    <option value="229">Togo</option>
    <option value="230">Benin</option>
    <option value="231">Nigeria</option>
    <option value="232">Cameroon</option>
    <option value="233">Central African Republic</option>
    <option value="234">Gabon</option>
    <option value="235">Congo</option>
    <option value="237">Equatorial Guinea</option>
    <option value="238">Rwanda</option>
    <option value="239">Burundi</option>
    <option value="240">Ethiopia</option>
    <option value="241">Djibouti</option>
    <option value="242">Somalia</option>
    <option value="243">Uganda</option>
    <option value="244">Kenya</option>
    <option value="245">Tanzania</option>
    <option value="246">Angola</option>
    <option value="247">Zambia</option>
    <option value="248">Malawi</option>
    <option value="249">Zimbabwe</option>
    <option value="250">Mozambique</option>
    <option value="251">Botswana</option>
    <option value="252">Lesotho</option>
    <option value="253">Swaziland</option>
    <option value="254">South Africa</option>
    <option value="255">Namibia</option>
    <option value="256">Saint Helena</option>
    <option value="257">Comoros</option>
    <option value="258">Madagascar</option>
    <option value="259">Mauritius</option>
    <option value="260">Réunion</option>
    <option value="261">Seychelles</option>
    <option value="262">Bouvet Island</option>
    <option value="263">Eritrea</option>
    <option value="264">Burkina Faso</option>
    <option value="265">Congo</option>
    <option value="266">Guinea-Bissau</option>
    <option value="267">Mayotte</option>
    <option value="268">São Tomé and Príncipe</option>
    <option value="269">Western Sahara</option>
    <option value="310">Australia</option>
    <option value="311">Fiji</option>
    <option value="312">Samoa</option>
    <option value="313">New Zealand</option>
    <option value="316">Guam</option>
    <option value="317">Solomon Islands</option>
    <option value="318">New Caledonia</option>
    <option value="320">Papua New Guinea</option>
    <option value="321">French Polynesia</option>
    <option value="322">Northern France Territories</option>
    <option value="323">Heard & McDonald Islands</option>
    <option value="324">Kiribati</option>
    <option value="325">Nauru</option>
    <option value="326">Niue</option>
    <option value="327">Palau</option>
    <option value="328">Pitcairn</option>
    <option value="329">Tokelau</option>
    <option value="330">Tonga</option>
    <option value="331">Tuvalu</option>
    <option value="332">Wallis & Futuna</option>
    <option value="333">Cook Islands</option>
    <option value="410">United States</option>
    <option value="411">Canada</option>
    <option value="412">Puerto Rico</option>
    <option value="413">Bermuda</option>
    <option value="414">Bahamas</option>
    <option value="415">Jamaica</option>
    <option value="416">Antigua and Barbuda</option>
    <option value="417">Dominica</option>
    <option value="418">Barbados</option>
    <option value="419">Trinidad and Tobago</option>
    <option value="421">Honduras</option>
    <option value="422">Guiana</option>
    <option value="423">Suriname</option>
    <option value="424">French Guiana</option>
    <option value="425">Cuba</option>
    <option value="426">Haiti</option>
    <option value="427">Dominican Republic</option>
    <option value="428">Guatemala</option>
    <option value="429">El Salvador</option>
    <option value="430">Nicaragua</option>
    <option value="431">Costa Rica</option>
    <option value="432">Panama</option>
    <option value="433">Colombia</option>
    <option value="434">Venezuela</option>
    <option value="435">Guyana</option>
    <option value="436">Ecuador</option>
    <option value="437">Peru</option>
    <option value="438">Bolivia</option>
    <option value="439">Chile</option>
    <option value="440">Paraguay</option>
    <option value="441">Uruguay</option>
    <option value="442">Argentina</option>
    <option value="443">Brazil</option>
    <option value="444">Mexico</option>
    <option value="445">Greenland</option>
    <option value="446">Iceland</option>
    <option value="447">Svalbard and Jan Mayen</option>
    <option value="448">Norway</option>
    <option value="449">Sweden</option>
    <option value="450">Finland</option>
    <option value="451">Denmark</option>
    <option value="452">Faroe Islands</option>
    <option value="453">Estonia</option>
    <option value="454">Latvia</option>
    <option value="455">Lithuania</option>
    <option value="456">Belgium</option>
    <option value="457">Netherlands</option>
    <option value="458">Luxembourg</option>
    <option value="459">Ireland</option>
    <option value="460">United Kingdom</option>
    <option value="461">Portugal</option>
    <option value="462">Spain</option>
    <option value="463">Gibraltar</option>
    <option value="464">Andorra</option>
    <option value="465">Monaco</option>
    <option value="466">France</option>
    <option value="467">Liechtenstein</option>
    <option value="468">Switzerland</option>
    <option value="469">Italy</option>
    <option value="470">San Marino</option>
    <option value="471">Vatican City State</option>
    <option value="472">Malta</option>
    <option value="473">Croatia</option>
    <option value="474">Bosnia and Herzegovina</option>
    <option value="475">Montenegro</option>
    <option value="476">Albania</option>
    <option value="477">Serbia</option>
    <option value="478">Macedonia</option>
    <option value="479">Greece</option>
    <option value="480">Cyprus</option>
</>

}


                </select>          
                        </div>
                </div>
              </div>
              <div className="frame1-12 md:flex-col mt-3">
                <div className="input-field-13">
                  <span className="label-14">{t("fatherName")}<span className="text-red-500	">*</span></span>
                  <div className="input-container-15">
                  <input
                                                                                 id="father_name"
                                                                                 name="father_name"
                                                                                 type="text"
                                                                                 onChange={formik.handleChange}
                                                                                 value={formik.values.father_name}
                 className="w-full h-full text-end p-1 rounded-[5%]" />
                  </div>
                </div>
                <div className="input-field-13">
                  <span className="label-14">{t("firstName")}<span className="text-red-500	">*</span></span>
                  <div className="input-container-15">
                  <input 
                  id="first_name"
                  name="first_name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.first_name}
                  className="w-full h-full text-end p-1 rounded-[5%]" />
                  </div>
                </div>

              </div>
              <div className="frame1-12 md:flex-col mt-3">
                <div className="input-field-13">
                  <span className="label-14">{t("lastName")}<span className="text-red-500	">*</span></span>
                  <div className="input-container-15">
                  <input
                                    id="family_name"
                                    name="family_name"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.family_name}
                  className="w-full h-full text-end p-1 rounded-[5%]" />
                  </div>
                </div>
                <div className="input-field-13">
                  <span className="label-14">{t("grandfatherName")}<span className="text-red-500	">*</span></span>
                  <div className="input-container-15">
                  <input
                   id="grandfather_name"
                   name="grandfather_name"
                   type="text"
                   onChange={formik.handleChange}
                   value={formik.values.grandfather_name}
                   className="w-full h-full text-end p-1 rounded-[5%]" />
                  </div>
                </div>

              </div>
              <div className="frame1-12 md:flex-col mt-3">
                <div className="input-field-13">
                  <span className="label-14">{t("mobileNumber")} <span className="text-red-500	">*</span></span>
                  <div className="input-container-15">
                  <input 
                                     id="mobile_number"
                                     name="mobile_number"
                                     onChange={formik.handleChange}
                                     value={formik.values.mobile_number}
                  type="tel" className="w-full h-full text-end p-1 rounded-[5%]" />
                  </div>
                </div>
                


              </div>
            </div>
            <div onClick={handelPerson} className="button mt-5">
              <span className="button-text">{t("confirm")}</span>
            </div>
          </div>

          :
                              <div className="">
            <div className="frame1-d">
              <div className="input-field">
                <span className="label">{t("username")}<span className="text-red-500	">*</span></span>
                <div className="input-container">
                    <input type="text" className="w-full h-full text-end p-1 rounded-[5%]" />
                </div>
              </div>
              <div className="frame1-e">
                <div className="input-field-f">
                  <span className="label-10">{t("email")}<span className="text-red-500	">*</span></span>
                  <div className="input-container-11">
                  <input 
                  type="email"
                                                       id="email"
                                                       name="email"
                                                       onChange={formik.handleChange}
                                                       value={formik.values.email}
                  className="w-full h-full text-end p-1 rounded-[5%]" />

                  </div>
                </div>
              </div>
              <div className="frame1-12 md:flex-col">
                <div className="input-field-13">
                  <span className="label-14">{t("confirmPassword")}<span className="text-red-500	">*</span></span>
                  <div className="input-container-15">
                  <input type="password" 
                  id="password_confirmation"
                  name="password_confirmation"
                  onChange={formik.handleChange}
                  value={formik.values.password_confirmation}
                  className="w-full h-full text-end p-1 rounded-[5%]" />
                  </div>
                  {/* <span className="hint-19"
                    >يجب ان تحتوى {t("password")} على حرف كبير وحرف صغير وحرف خاص
                    مثل @#$
                  </span> */}
                </div>
                <div className="input-field-16">
                  <span className="label-17">{t("password")}<span className="text-red-500	">*</span></span>
                  <div className="input-container-18">
                  <input type="password"
                                    id="password"
                                    name="password"
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                  className="w-full h-full text-end p-1 rounded-[5%]" />
                  </div>
                  {/* <span className="hint-19"
                    >يجب ان تحتوى {t("password")} على حرف كبير وحرف صغير وحرف خاص
                    مثل @#$
                  </span> */}
                </div>
              </div>
            </div>
            <button type="submit" className="button mt-5">
              <span className="button-text">تسجيل</span>
            </button>
          </div>
          
          :""}
</form>


{/* <input
                           id="national_id_number"
                           name="national_id_number"
                           type="text"
                           onChange={formik.handleChange}
                           value={formik.values.national_id_number}
                            */}

<form onSubmit={businessSectorForm.handleSubmit}>
          {typeInput==2?
          businesSector==0?
                    <div className="">
            <div className="frame1-d">
              <div className="input-field">
                <span className="label">{t("organizationName")}<span className="text-red-500	">*</span></span>
                <div className="input-container">
                    <input 
                     id="name_distination"
                     name="name_distination"
                     onChange={businessSectorForm.handleChange}
                     value={businessSectorForm.values.name_distination}
                     type="text"
                     className="w-full h-full text-end p-1 rounded-[5%]" />
                </div>
              </div>
              <div className="frame1-e">
                <div className="input-field-f">
                  <span className="label-10">{t("commercialRegister")}<span className="text-red-500	">*</span></span>
                  <div className="input-container-11">
                  <input
                     id="commercial_register"
                     name="commercial_register"
                     onChange={businessSectorForm.handleChange}
                     value={businessSectorForm.values.commercial_register}
                     type="text"
                  className="w-full h-full text-end p-1 rounded-[5%]" />

                  </div>
                </div>
              </div>

            </div>
            <span onClick={()=>handelBusinesSector(1)} className="button mt-5">
              <span className="button-text">{t("confirm")}</span>
            </span>
          </div>
          :
          businesSector==1?
          <div className="">
          <div className="frame1-d">
            <div className="frame1-12 md:flex-col mt-3">
              <div className="input-field-13">
                <span className="label-14">{t("fatherName")}<span className="text-red-500	">*</span></span>
                <div className="input-container-15">
                <input
                                     id="father_name"
                                     name="father_name"
                                     onChange={businessSectorForm.handleChange}
                                     value={businessSectorForm.values.father_name}
                type="text" className="w-full h-full text-end p-1 rounded-[5%]" />
                </div>
              </div>
              <div className="input-field-13">
                <span className="label-14">{t("firstName")}<span className="text-red-500	">*</span></span>
                <div className="input-container-15">
                <input 
                id="first_name"
                name="first_name"
                onChange={businessSectorForm.handleChange}
                value={businessSectorForm.values.first_name}
                 
                 type="text" className="w-full h-full text-end p-1 rounded-[5%]" />
                </div>
              </div>

            </div>
            <div className="frame1-12 md:flex-col mt-3">
              <div className="input-field-13">
                <span className="label-14">{t("lastName")}<span className="text-red-500	">*</span></span>
                <div className="input-container-15">
                <input
                                id="family_name"
                                name="family_name"
                                onChange={businessSectorForm.handleChange}
                                value={businessSectorForm.values.family_name}
                type="text" className="w-full h-full text-end p-1 rounded-[5%]" />
                </div>
              </div>
              <div className="input-field-13">
                <span className="label-14">{t("grandfatherName")}<span className="text-red-500	">*</span></span>
                <div className="input-container-15">
                <input
                 id="grandfather_name"
                 name="grandfather_name"
                 onChange={businessSectorForm.handleChange}
                 value={businessSectorForm.values.grandfather_name}
                type="text" className="w-full h-full text-end p-1 rounded-[5%]" />
                </div>
              </div>

            </div>
            <div className="frame1-12 md:flex-col mt-3">
              <div className="input-field-13">
                <span className="label-14">{t("mobileNumber")} <span className="text-red-500	">*</span></span>
                <div className="input-container-15">
                <input
                      id="mobile_number"
                      name="mobile_number"
                      onChange={businessSectorForm.handleChange}
                      value={businessSectorForm.values.mobile_number}
                type="tel" className="w-full h-full text-end p-1 rounded-[5%]" />
                </div>
              </div>


            </div>
          </div>
          <span onClick={()=>handelBusinesSector(2)} className="button mt-5">
            <span className="button-text">{t("confirm")}</span>
          </span>
        </div>
        :  
        <div className="">
        <div className="frame1-d">
          <div className="input-field">
            <span className="label">{t("username")}<span className="text-red-500	">*</span></span>
            <div className="input-container">
                <input type="text" className="w-full h-full text-end p-1 rounded-[5%]" />
            </div>
          </div>
          <div className="frame1-e">
            <div className="input-field-f">
              <span className="label-10">{t("email")}<span className="text-red-500	">*</span></span>
              <div className="input-container-11">
              <input 
              type="email"
                                                   id="email"
                                                   name="email"
                                                   onChange={businessSectorForm.handleChange}
                                                   value={businessSectorForm.values.email}
              className="w-full h-full text-end p-1 rounded-[5%]" />

              </div>
            </div>
          </div>
          <div className="frame1-12 md:flex-col">
            <div className="input-field-13">
              <span className="label-14">{t("confirmPassword")}<span className="text-red-500	">*</span></span>
              <div className="input-container-15">
              <input type="password" 
              id="password_confirmation"
              name="password_confirmation"
              onChange={businessSectorForm.handleChange}
              value={businessSectorForm.values.password_confirmation}
              className="w-full h-full text-end p-1 rounded-[5%]" />
              </div>
              {/* <span className="hint-19"
                >يجب ان تحتوى {t("password")} على حرف كبير وحرف صغير وحرف خاص
                مثل @#$
              </span> */}
            </div>
            <div className="input-field-16">
              <span className="label-17">{t("password")}<span className="text-red-500	">*</span></span>
              <div className="input-container-18">
              <input type="password"
                                id="password"
                                name="password"
                                onChange={businessSectorForm.handleChange}
                                value={businessSectorForm.values.password}
              className="w-full h-full text-end p-1 rounded-[5%]" />
              </div>
              {/* <span className="hint-19"
                >يجب ان تحتوى {t("password")} على حرف كبير وحرف صغير وحرف خاص
                مثل @#$
              </span> */}
            </div>
          </div>
        </div>
        <button type="submit" className="button mt-5">
          <span className="button-text">تسجيل</span>
        </button>
      </div>
          
          :""}
</form>


<form onSubmit={governmentSectorForm.handleSubmit}>

          {typeInput==3?
          government==0?
          <div className="">
          <div className="frame1-d">
            <div className="input-field">
              <span className="label">{t("organizationName")}<span className="text-red-500	">*</span></span>
              <div className="input-container">
                  <input 
                   id="name_distination"
                   name="name_distination"
                   onChange={governmentSectorForm.handleChange}
                   value={governmentSectorForm.values.name_distination}
                   type="text"
                   className="w-full h-full text-end p-1 rounded-[5%]" />
              </div>
            </div>

          </div>
          <span onClick={()=>handelGovernment(1)} className="button mt-5">
            <span className="button-text">{t("confirm")}</span>
          </span>
        </div>
          :
          government==1?
          <div className="">
          <div className="frame1-d">
            <div className="frame1-12 md:flex-col mt-3">
              <div className="input-field-13">
                <span className="label-14">{t("fatherName")}<span className="text-red-500	">*</span></span>
                <div className="input-container-15">
                <input
                                     id="father_name"
                                     name="father_name"
                                     onChange={governmentSectorForm.handleChange}
                                     value={governmentSectorForm.values.father_name}
                type="text" className="w-full h-full text-end p-1 rounded-[5%]" />
                </div>
              </div>
              <div className="input-field-13">
                <span className="label-14">{t("firstName")}<span className="text-red-500	">*</span></span>
                <div className="input-container-15">
                <input 
                id="first_name"
                name="first_name"
                onChange={governmentSectorForm.handleChange}
                value={governmentSectorForm.values.first_name}
                 
                 type="text" className="w-full h-full text-end p-1 rounded-[5%]" />
                </div>
              </div>

            </div>
            <div className="frame1-12 md:flex-col mt-3">
              <div className="input-field-13">
                <span className="label-14">{t("lastName")}<span className="text-red-500	">*</span></span>
                <div className="input-container-15">
                <input
                                id="family_name"
                                name="family_name"
                                onChange={governmentSectorForm.handleChange}
                                value={governmentSectorForm.values.family_name}
                type="text" className="w-full h-full text-end p-1 rounded-[5%]" />
                </div>
              </div>
              <div className="input-field-13">
                <span className="label-14">{t("grandfatherName")}<span className="text-red-500	">*</span></span>
                <div className="input-container-15">
                <input
                 id="grandfather_name"
                 name="grandfather_name"
                 onChange={governmentSectorForm.handleChange}
                 value={governmentSectorForm.values.grandfather_name}
                type="text" className="w-full h-full text-end p-1 rounded-[5%]" />
                </div>
              </div>

            </div>
            <div className="frame1-12 md:flex-col mt-3">
              <div className="input-field-13">
                <span className="label-14">{t("mobileNumber")} <span className="text-red-500	">*</span></span>
                <div className="input-container-15">
                <input
                      id="mobile_number"
                      name="mobile_number"
                      onChange={governmentSectorForm.handleChange}
                      value={governmentSectorForm.values.mobile_number}
                type="tel" className="w-full h-full text-end p-1 rounded-[5%]" />
                </div>
              </div>


            </div>
          </div>
          <span onClick={()=>handelGovernment(2)} className="button mt-5">
            <span className="button-text">{t("confirm")}</span>
          </span>
        </div>
        :  
        <div className="">
        <div className="frame1-d">
          <div className="input-field">
            <span className="label">{t("username")}<span className="text-red-500	">*</span></span>
            <div className="input-container">
                <input type="text" className="w-full h-full text-end p-1 rounded-[5%]" />
            </div>
          </div>
          <div className="frame1-e">
            <div className="input-field-f">
              <span className="label-10">{t("email")}<span className="text-red-500	">*</span></span>
              <div className="input-container-11">
              <input 
              type="email"
                                                   id="email"
                                                   name="email"
                                                   onChange={governmentSectorForm.handleChange}
                                                   value={governmentSectorForm.values.email}
              className="w-full h-full text-end p-1 rounded-[5%]" />

              </div>
            </div>
          </div>
          <div className="frame1-12 md:flex-col">
            <div className="input-field-13">
              <span className="label-14">{t("confirmPassword")}<span className="text-red-500	">*</span></span>
              <div className="input-container-15">
              <input type="password" 
              id="password_confirmation"
              name="password_confirmation"
              onChange={governmentSectorForm.handleChange}
              value={governmentSectorForm.values.password_confirmation}
              className="w-full h-full text-end p-1 rounded-[5%]" />
              </div>
              {/* <span className="hint-19"
                >يجب ان تحتوى {t("password")} على حرف كبير وحرف صغير وحرف خاص
                مثل @#$
              </span> */}
            </div>
            <div className="input-field-16">
              <span className="label-17">{t("password")}<span className="text-red-500	">*</span></span>
              <div className="input-container-18">
              <input type="password"
                                id="password"
                                name="password"
                                onChange={governmentSectorForm.handleChange}
                                value={governmentSectorForm.values.password}
              className="w-full h-full text-end p-1 rounded-[5%]" />
              </div>
              {/* <span className="hint-19"
                >يجب ان تحتوى {t("password")} على حرف كبير وحرف صغير وحرف خاص
                مثل @#$
              </span> */}
            </div>
          </div>
        </div>
        <button type="submit" className="button mt-5">
          <span className="button-text">تسجيل</span>
        </button>
      </div>
          :""}
</form>
        </div>

        {typeInput==1?
        <div className="progress-bar flex-col md:flex-row md:w-full">
          <div className="frame1-1b w-full md:w-[50%]">
            <span className="personal-info">{t("personalInformation")}</span>
            <div className="check"></div>
          </div>
          <div className="frame1-1c w-full md:w-[50%]">
            <span className="account-info">{t("accountInformation")}</span>
            <div className="minus"></div>
          </div>
        </div>
        :""}
        {typeInput==2?
        <div className="progress-bar flex-col md:flex-row md:w-full">
          <div className="frame1-1b w-full md:w-[33%]">
          <span className="personal-info">{t("companyInformation")}</span>
          <div className="check"></div>
          </div>
          <div className="frame1-1c w-full md:w-[33%]">
          <span className="account-info">{t("commissionerInformation")}</span>
          <div className="minus"></div>
          </div>
          <div className="frame1-1c w-full md:w-[33%]">
            <span className="account-info">{t("accountInformation")}</span>
            <div className="minus"></div>
          </div>
        </div>
        :""}
        {typeInput==3?
        <div className="progress-bar flex-col md:flex-row md:w-full">
          <div className="frame1-1b w-full md:w-[50%]">
            <span className="personal-info">{t("companyInformation")}</span>
            <div className="check"></div>
          </div>
          <div className="frame1-1c w-full md:w-[50%]">
            <span className="account-info">{t("commissionerInformation")}</span>
            <div className="minus"></div>
          </div>
          <div className="frame1-1c w-full md:w-[50%]">
            <span className="account-info">{t("accountInformation")}</span>
            <div className="minus"></div>
          </div>
        </div>
        :""}
      </div>
    </div>

</div>
      <Toaster containerStyle={{zIndex:"10000000000000000000000000000"}} />

 </> );
};
