/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatStrokeWeightFill7 = ({ className }) => {
  return (
    <svg
      className={`format-stroke-weight-fill-7 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M20 16C20 16.7911 19.7654 17.5645 19.3259 18.2223C18.8864 18.8801 18.2616 19.3928 17.5307 19.6955C16.7998 19.9983 15.9956 20.0775 15.2196 19.9231C14.4437 19.7688 13.731 19.3878 13.1716 18.8284C12.6122 18.269 12.2312 17.5563 12.0769 16.7804C11.9225 16.0044 12.0017 15.2002 12.3045 14.4693C12.6072 13.7384 13.1199 13.1136 13.7777 12.6741C14.4355 12.2346 15.2089 12 16 12C17.0609 12 18.0783 12.4214 18.8284 13.1716C19.5786 13.9217 20 14.9391 20 16ZM29 10V22C28.998 23.8559 28.2599 25.6352 26.9476 26.9476C25.6352 28.2599 23.8559 28.998 22 29H10C8.14409 28.998 6.36477 28.2599 5.05245 26.9476C3.74012 25.6352 3.00199 23.8559 3 22V10C3.00199 8.14409 3.74012 6.36477 5.05245 5.05245C6.36477 3.74012 8.14409 3.00199 10 3H22C23.8559 3.00199 25.6352 3.74012 26.9476 5.05245C28.2599 6.36477 28.998 8.14409 29 10ZM22 16C22 14.8133 21.6481 13.6533 20.9888 12.6666C20.3295 11.6799 19.3925 10.9108 18.2961 10.4567C17.1997 10.0026 15.9933 9.88378 14.8295 10.1153C13.6656 10.3468 12.5965 10.9182 11.7574 11.7574C10.9182 12.5965 10.3468 13.6656 10.1153 14.8295C9.88378 15.9933 10.0026 17.1997 10.4567 18.2961C10.9108 19.3925 11.6799 20.3295 12.6666 20.9888C13.6533 21.6481 14.8133 22 16 22C17.5908 21.9983 19.116 21.3657 20.2408 20.2408C21.3657 19.116 21.9983 17.5908 22 16ZM25 8.5C25 8.20333 24.912 7.91332 24.7472 7.66664C24.5824 7.41997 24.3481 7.22771 24.074 7.11418C23.7999 7.00065 23.4983 6.97094 23.2074 7.02882C22.9164 7.0867 22.6491 7.22956 22.4393 7.43934C22.2296 7.64912 22.0867 7.91639 22.0288 8.20736C21.9709 8.49834 22.0007 8.79994 22.1142 9.07403C22.2277 9.34811 22.42 9.58238 22.6666 9.7472C22.9133 9.91203 23.2033 10 23.5 10C23.8978 10 24.2794 9.84196 24.5607 9.56066C24.842 9.27936 25 8.89782 25 8.5Z"
        fill="#343330"
      />
    </svg>
  );
};
