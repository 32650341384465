/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatOutlineWeightFill1 = ({ className }) => {
  return (
    <svg
      className={`format-outline-weight-fill-1 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M28.8662 27.5C28.7784 27.6521 28.6521 27.7783 28.5001 27.8661C28.348 27.9539 28.1755 28 28 28H3.99995C3.8245 27.9998 3.65219 27.9535 3.50031 27.8656C3.34843 27.7778 3.22234 27.6515 3.13469 27.4996C3.04705 27.3476 3.00093 27.1752 3.00098 26.9997C3.00102 26.8243 3.04723 26.6519 3.13495 26.5C5.0387 23.2088 7.97245 20.8487 11.3962 19.73C9.70266 18.7218 8.38688 17.1856 7.65094 15.3572C6.91499 13.5289 6.79956 11.5095 7.32238 9.6092C7.8452 7.70889 8.97735 6.03274 10.545 4.83816C12.1126 3.64357 14.029 2.9966 16 2.9966C17.9709 2.9966 19.8873 3.64357 21.4549 4.83816C23.0226 6.03274 24.1547 7.70889 24.6775 9.6092C25.2003 11.5095 25.0849 13.5289 24.349 15.3572C23.613 17.1856 22.2972 18.7218 20.6037 19.73C24.0275 20.8487 26.9612 23.2088 28.865 26.5C28.9529 26.6519 28.9993 26.8243 28.9996 26.9998C28.9998 27.1754 28.9538 27.3479 28.8662 27.5Z"
        fill="#343330"
      />
    </svg>
  );
};
