/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatStrokeWeightFill = ({ className }) => {
  return (
    <svg
      className={`format-stroke-weight-fill ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M26.9237 11.6175C26.8481 11.4348 26.72 11.2786 26.5555 11.1686C26.3911 11.0587 26.1978 11 26 11H5.99999C5.8021 10.9998 5.60861 11.0584 5.44401 11.1683C5.27942 11.2782 5.15112 11.4344 5.07537 11.6172C4.99962 11.8 4.97981 12.0012 5.01846 12.1953C5.05712 12.3894 5.15248 12.5676 5.29249 12.7075L15.2925 22.7075C15.3854 22.8005 15.4957 22.8742 15.6171 22.9246C15.7385 22.9749 15.8686 23.0008 16 23.0008C16.1314 23.0008 16.2615 22.9749 16.3829 22.9246C16.5043 22.8742 16.6146 22.8005 16.7075 22.7075L26.7075 12.7075C26.8473 12.5676 26.9425 12.3893 26.981 12.1953C27.0195 12.0013 26.9995 11.8002 26.9237 11.6175Z"
        fill="#343330"
      />
    </svg>
  );
};
