/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatOutlineWeightLight8 = ({ className }) => {
  return (
    <svg
      className={`format-outline-weight-light-8 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M26.6325 26.5974L18.705 14.1399L26.555 5.4999C26.6313 5.42906 26.6921 5.3431 26.7334 5.2475C26.7746 5.1519 26.7955 5.04875 26.7948 4.94462C26.794 4.8405 26.7716 4.73767 26.7289 4.64269C26.6862 4.54772 26.6242 4.46267 26.5468 4.39297C26.4694 4.32328 26.3784 4.27045 26.2795 4.23787C26.1806 4.20528 26.076 4.19364 25.9724 4.2037C25.8687 4.21376 25.7683 4.24529 25.6775 4.29629C25.5867 4.34728 25.5075 4.41662 25.445 4.4999L17.875 12.8274L12.6375 4.5974C12.5694 4.49024 12.4751 4.40214 12.3636 4.34136C12.2521 4.28058 12.127 4.24911 12 4.2499H6.00002C5.86567 4.24996 5.7338 4.28611 5.6182 4.35457C5.5026 4.42303 5.40751 4.52128 5.34287 4.63906C5.27824 4.75684 5.24642 4.88982 5.25076 5.0241C5.25509 5.15838 5.29542 5.28903 5.36752 5.4024L13.295 17.8599L5.44502 26.4999C5.33255 26.6497 5.28021 26.8362 5.2983 27.0226C5.3164 27.2091 5.40362 27.382 5.5428 27.5074C5.68198 27.6328 5.86304 27.7016 6.05037 27.7002C6.23769 27.6988 6.41772 27.6273 6.55502 27.4999L14.125 19.1724L19.3625 27.4024C19.4307 27.5096 19.5249 27.5977 19.6364 27.6584C19.7479 27.7192 19.873 27.7507 20 27.7499H26C26.1344 27.7498 26.2662 27.7137 26.3818 27.6452C26.4974 27.5768 26.5925 27.4785 26.6572 27.3607C26.7218 27.243 26.7536 27.11 26.7493 26.9757C26.7449 26.8414 26.7046 26.7108 26.6325 26.5974ZM20.4113 26.2499L7.36627 5.7499H11.5888L24.6338 26.2499H20.4113Z"
        fill="#343330"
      />
    </svg>
  );
};
