/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FormatOutlineWeightDuotone8 = ({ className }) => {
  return (
    <svg
      className={`format-outline-weight-duotone-8 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M26 27H20L6 5H12L26 27Z" fill="#343330" opacity="0.2" />
      <path
        className="path"
        d="M26.8438 26.4638L19.0188 14.1663L26.74 5.6725C26.9146 5.47565 27.0046 5.21791 26.9905 4.95515C26.9764 4.69239 26.8592 4.44579 26.6645 4.26882C26.4698 4.09185 26.2131 3.99876 25.9502 4.00974C25.6873 4.02073 25.4393 4.1349 25.26 4.3275L17.905 12.4175L12.8438 4.46375C12.7535 4.32169 12.6289 4.20471 12.4814 4.12365C12.3339 4.04258 12.1683 4.00005 12 4H6.00001C5.82071 3.99991 5.64468 4.04803 5.49037 4.13932C5.33605 4.23062 5.20911 4.36172 5.12285 4.5189C5.03659 4.67609 4.99418 4.85357 5.00006 5.03278C5.00593 5.21198 5.05988 5.3863 5.15626 5.5375L12.9813 17.8337L5.26001 26.3337C5.16984 26.4306 5.09979 26.5444 5.05393 26.6685C5.00806 26.7927 4.98729 26.9247 4.99282 27.0569C4.99834 27.1891 5.03005 27.3189 5.08611 27.4388C5.14217 27.5586 5.22146 27.6662 5.3194 27.7552C5.41733 27.8442 5.53195 27.9129 5.65662 27.9572C5.78129 28.0016 5.91352 28.0208 6.04566 28.0137C6.1778 28.0066 6.30722 27.9733 6.42641 27.9158C6.5456 27.8583 6.65219 27.7777 6.74001 27.6787L14.095 19.5888L19.1563 27.5425C19.2472 27.6834 19.3722 27.7991 19.5197 27.8791C19.6671 27.959 19.8323 28.0006 20 28H26C26.1791 27.9999 26.3549 27.9518 26.509 27.8606C26.6632 27.7693 26.79 27.6384 26.8762 27.4814C26.9624 27.3244 27.0049 27.1472 26.9992 26.9681C26.9935 26.7891 26.9398 26.6149 26.8438 26.4638ZM20.5488 26L7.82126 6H11.4463L24.1788 26H20.5488Z"
        fill="#343330"
      />
    </svg>
  );
};
